import React, { useEffect,useRef,useState,useCallback } from 'react';
import SlideBtn from './slide-btn';
import './index.scss';
import { updateOrderLine } from '@/redux/action/order-line.js';
import { updateCurrentBallInfo } from '@/redux/action/current-ball-info.js';
import { connect } from 'react-redux';
import SocketEvent from '@/serve/socket-event';
import postMessageToApp from "@/views/mobile/connectApp/postMessageToApp.js";

//普通controlLink控制或实时控制(liveControl)的控制球组件
const LdrBalls = (props) => {
    let {
        linkId,
        toysData,
        joinerToy,
        creatorToy,
        socketServiceInstanct,
        orderLine,
        liveOrSync,
        isEnd,
        controlState,
        updateOrderLine,
        updateCurrentBallInfo
    } = props;
    const [htmlArr, setHtmlArr] = useState([]);
    const [syncToysData, setSyncToysData] = useState([]); // {name: "Max,tenera", toyFun: "p,v,s"}
    useEffect(()=>{
        let allArr = [];
        let toyNameArr = [];
        let toys = [...joinerToy, ...creatorToy]
        toys.forEach(toy => {
            let toyFun = toy.toyFun
            //syncControl根据功能生成滚动球，v1、v2与v同属震动，故将v1、v2转为v
            if (toyFun.includes('v1')) {
                toyFun = toyFun.replace('v1', 'v')
            }
            if (toyFun.includes('v2')) {
                toyFun = toyFun.replace('v2', 'v')
            }
            if (toyFun.includes('v3')) {
                toyFun = toyFun.replace('v3', 'v')
            }
            let toyFunArr = (toyFun + "").split(",")
            allArr = allArr.concat(toyFunArr)
            toyNameArr.push(toy.type)
        })
        let newToyFun = Array.from(new Set(allArr)).join(",");
        let newToyName = Array.from(new Set(toyNameArr)).join(",");
        setSyncToysData({ toyFun:newToyFun,name:newToyName });
    }, [joinerToy,creatorToy]);

    useEffect(()=>{
        let tmpArr = [];
        const touchEndAndSendOrder = ()=>{
            if(orderLine && linkId){
                setTimeout(()=>{
                    sendOrderToToy(orderLine,linkId,socketServiceInstanct);
                },200);
            }
        }
        let toy = syncToysData;
        if(!toy.toyFun) toy.toyFun = "v"
        let funcArr = (toy.toyFun + "").split(",")
        let item = <div key="sync-balls" className="ball-item" onTouchMove={  (e)=>{ e.stopPropagation() } }>
            <div className="ball-list">
                {
                    funcArr.map(order => {
                        return <SlideBtn key={order} data={{ linkId, orderType:order, isEnd, socketServiceInstanct }} touchEndAndSendOrder={touchEndAndSendOrder}></SlideBtn>
                    })
                }
            </div>
        </div>;
        tmpArr.push(item);
        setHtmlArr(tmpArr);
    }, [isEnd,syncToysData,linkId,orderLine,socketServiceInstanct]);

    //默认选中第一个玩具的震动按钮
    useEffect(()=>{
        const firstOrder = (syncToysData && syncToysData.toyFun && syncToysData.toyFun.slice(0,1)) || 'v'
        updateCurrentBallInfo({currentSelBallId: `toy1-drag-${firstOrder}-btn-id`});
    },[syncToysData, updateCurrentBallInfo])

    //初始化曲线数据结构
    useEffect(()=>{
        let toyFunArr = []
        let toys = [...(joinerToy || []), ...(creatorToy || [])]
        toys.forEach(toy => {
            let funStr = toy.toyFun
            if (funStr) {
                if (funStr.includes('v1')) {
                    funStr = funStr.replace('v1', 'v')
                }
                if (funStr.includes('v2')) {
                    funStr = funStr.replace('v2', 'v')
                }
                if (funStr.includes('v3')) {
                    funStr = funStr.replace('v3', 'v')
                }
                const arr = funStr.split(",")
                toyFunArr = toyFunArr.concat(arr)
            }
        })
        let newArr = Array.from(new Set(toyFunArr));
        let str = "";
        if(newArr && newArr.length > 0){
            str = newArr.join(",");
        }
        if(toysData && toysData.length > 0){
            let orderLineObj = [];
            for(let x=0; x<toysData.length; x++){
                let curToy = toysData[x];
                let obj = {
                    toyId: curToy.id,
                    name: curToy.name,
                    type: curToy.type,
                    version: curToy.version,
                    status: curToy.status,
                    toyFun: str ? str : curToy.toyFun,
                    line: {}
                }
                let orders = (str || curToy.toyFun).split(',')
                orders.forEach((o, i) => {
                    obj.line[o] = [0]  // 根据指令添加画线条数
                })
                orderLineObj.push(obj);
            }
            updateOrderLine(orderLineObj);
        }
    },[joinerToy,creatorToy,toysData,updateOrderLine]);

    const [lastOrder, setLastOrder] = useState("");
    const [lastSyncOrder, setLastSyncOrder] = useState("");
    //有记忆的hooks，记住上一次渲染计算的结果
    let savedSendTimerCallback = useRef();
    const [time, setTime] = useState(0)
    const callback = useCallback(() => {
        setTime(time + 1)
        if(liveOrSync === "sync"){
            //如果是sync control，则需要把指令提交给connect app
            //sync message = "{\"cate\":\"all\",\"all\":{\"v\":4,\"v1\":-1,\"v2\":-1,\"s\":-1,\"p\":11,\"r\":-1}}"
            let order = sendOrderToToy(orderLine,linkId);
            let orderStr = order.toyCommandJson;
            if(lastSyncOrder !== orderStr || time % 10 === 0){
                let body = { "linkId":linkId, "event":"toyCommand", "message":orderStr };
                postMessageToApp.post(JSON.stringify(body));
                setLastSyncOrder(orderStr);
            }
        }else{
            let order = sendOrderToToy(orderLine,linkId);
            let orderStr = JSON.stringify(order);
            if(lastOrder !== orderStr){
                setLastOrder(orderStr);
                socketServiceInstanct.socketEmitMsg(SocketEvent.ANON_COMMAND_LINK_TS, order);
            }
        }
    },[time, liveOrSync, orderLine, linkId, lastSyncOrder, lastOrder, socketServiceInstanct]);

    //发给服务器的玩具指令
    const sendOrderToToy = (orderLine,linkId)=>{
        let toyData = {...orderLine};
        let orderBody = {}
        Object.keys(toyData).forEach(function(key){
            const curToy = toyData[key]
            orderBody = { v: -1, v1: -1, v2: -1, v3: -1, s: -1, p: -1, r: -1, f: -1, t: -1, d: -1, o: -1, pos: -1  }
            let toyFunArr = (curToy.toyFun + "").split(',');
            toyFunArr.forEach(order => {
                let level = -1
                let lineArr = curToy.line[order]
                if (lineArr.length > 1) {
                    level = lineArr[lineArr.length - 1]
                }
                if (order === 'p') {
                    if(level >=1 && level <= 6){
                        level = 1
                    } else if(level > 6 && level < 14){
                        level = 2
                    } else if(level >= 14){
                        level = 3
                    }
                }
                if (order === 'pos' && level > 0) {
                  level *= 5;
                }
                if (order === 'v') {
                    orderBody = {
                        ...orderBody,
                        "v": level,
                        "v1": level,
                        "v2": level,
                        "v3": level,
                    }
                } else {
                    orderBody = { ...orderBody, [order]: level }
                }
            })
        })
        let cate = { "version": 5, "cate": "all", "all": orderBody };
        let order = {
            "toyCommandJson": JSON.stringify(cate),
            "linkId": linkId,
            "userTouch": true
        }
        return order;
    }
    //每次渲染，更新ref为最新的回调
    useEffect(()=>{
        savedSendTimerCallback.current = ()=>{
            callback();
        }
    }, [callback])
    useEffect(()=>{
        let timer = setInterval(()=>{
            if (isEnd && isEnd.end) {
                clearInterval(timer)
            }
            if(controlState.state === 1){
                savedSendTimerCallback.current();
            }
        }, 100);
        return function(){
            clearInterval(timer);
        }
    },[controlState, isEnd]);

    return (
        <>
            <div id="mobile-balls-area" className="sync-balls-area">
                {
                    htmlArr
                }
            </div>
        </>
    );
}

export default connect(state => ({
    orderLine: state.orderLine,
    controlState: state.controlState
}), {
    updateOrderLine,
    updateCurrentBallInfo
})(LdrBalls);
