// 表情列表
const expressin = [
    {
        "key": "RedHeart",
        "emoji": "RedHeart.svg"
    },
    {
        "key": "HeartEyes1",
        "emoji": "HeartEyes1.svg"
    },
    {
        "key": "Smiling1",
        "emoji": "Smiling1.svg"
    },
    {
        "key": "BlowKiss",
        "emoji": "BlowKiss.svg"
    },
    {
        "key": "Grinning1",
        "emoji": "Grinning1.svg"
    },
    {
        "key": "Grinning2",
        "emoji": "Grinning2.svg"
    },
    {
        "key": "Grinning3",
        "emoji": "Grinning3.svg"
    },
    {
        "key": "Beaming",
        "emoji": "Beaming.svg"
    },
    {
        "key": "Grinning4",
        "emoji": "Grinning4.svg"
    },
    {
        "key": "Grinning5",
        "emoji": "Grinning5.svg"
    },
    {
        "key": "Laughing",
        "emoji": "Laughing.svg"
    },
    {
        "key": "TearsJoy1",
        "emoji": "TearsJoy1.svg"
    },
    {
        "key": "Smiling2",
        "emoji": "Smiling2.svg"
    },
    {
        "key": "UpsideDown",
        "emoji": "UpsideDown.svg"
    },
    {
        "key": "Winking1",
        "emoji": "Winking1.svg"
    },
    {
        "key": "Smiling3",
        "emoji": "Smiling3.svg"
    },
    {
        "key": "Smiling4",
        "emoji": "Smiling4.svg"
    },
    {
        "key": "StarStruck",
        "emoji": "StarStruck.svg"
    },
    {
        "key": "Kissing1",
        "emoji": "Kissing1.svg"
    },
    {
        "key": "Smiling5",
        "emoji": "Smiling5.svg"
    },
    {
        "key": "Kissing2",
        "emoji": "Kissing2.svg"
    },
    {
        "key": "Kissing3",
        "emoji": "Kissing3.svg"
    },
    {
        "key": "Smiling6",
        "emoji": "Smiling6.svg"
    },
    {
        "key": "Savoring",
        "emoji": "Savoring.svg"
    },
    {
        "key": "Tongue",
        "emoji": "Tongue.svg"
    },
    {
        "key": "Winking2",
        "emoji": "Winking2.svg"
    },
    {
        "key": "Zany",
        "emoji": "Zany.svg"
    },
    {
        "key": "Squinting",
        "emoji": "Squinting.svg"
    },
    {
        "key": "MoneyMouth",
        "emoji": "MoneyMouth.svg"
    },
    {
        "key": "Hugging",
        "emoji": "Hugging.svg"
    },
    {
        "key": "HandOver",
        "emoji": "HandOver.svg"
    },
    {
        "key": "Shushing",
        "emoji": "Shushing.svg"
    },
    {
        "key": "Thinking",
        "emoji": "Thinking.svg"
    },
    {
        "key": "Zipper",
        "emoji": "Zipper.svg"
    },
    {
        "key": "Eyebrow",
        "emoji": "Eyebrow.svg"
    },
    {
        "key": "Neutral",
        "emoji": "Neutral.svg"
    },
    {
        "key": "Expressionless",
        "emoji": "Expressionless.svg"
    },
    {
        "key": "WithoutMouth",
        "emoji": "WithoutMouth.svg"
    },
    {
        "key": "Smirking",
        "emoji": "Smirking.svg"
    },
    {
        "key": "Unamused",
        "emoji": "Unamused.svg"
    },
    {
        "key": "RollingEyes",
        "emoji": "RollingEyes.svg"
    },
    {
        "key": "Grimacing",
        "emoji": "Grimacing.svg"
    },
    {
        "key": "Lying",
        "emoji": "Lying.svg"
    },
    {
        "key": "Relieved",
        "emoji": "Relieved.svg"
    },
    {
        "key": "Pensive",
        "emoji": "Pensive.svg"
    },
    {
        "key": "Sleepy",
        "emoji": "Sleepy.svg"
    },
    {
        "key": "Drooling",
        "emoji": "Drooling.svg"
    },
    {
        "key": "Sleeping",
        "emoji": "Sleeping.svg"
    },
    {
        "key": "Mask",
        "emoji": "Mask.svg"
    },
    {
        "key": "Thermometer",
        "emoji": "Thermometer.svg"
    },
    {
        "key": "Bandage",
        "emoji": "Bandage.svg"
    },
    {
        "key": "Nauseated",
        "emoji": "Nauseated.svg"
    },
    {
        "key": "Vomiting",
        "emoji": "Vomiting.svg"
    },
    {
        "key": "Sneezing",
        "emoji": "Sneezing.svg"
    },
    {
        "key": "Hot",
        "emoji": "Hot.svg"
    },
    {
        "key": "Cold",
        "emoji": "Cold.svg"
    },
    {
        "key": "Woozy",
        "emoji": "Woozy.svg"
    },
    {
        "key": "Dizzy",
        "emoji": "Dizzy.svg"
    },
    {
        "key": "Exploding",
        "emoji": "Exploding.svg"
    },
    {
        "key": "Partying",
        "emoji": "Partying.svg"
    },
    {
        "key": "Sunglasses",
        "emoji": "Sunglasses.svg"
    },
    {
        "key": "Nerd",
        "emoji": "Nerd.svg"
    },
    {
        "key": "Confused",
        "emoji": "Confused.svg"
    },
    {
        "key": "Worried",
        "emoji": "Worried.svg"
    },
    {
        "key": "Frowning1",
        "emoji": "Frowning1.svg"
    },
    {
        "key": "OpenMouth",
        "emoji": "OpenMouth.svg"
    },
    {
        "key": "Hushed",
        "emoji": "Hushed.svg"
    },
    {
        "key": "Astonished",
        "emoji": "Astonished.svg"
    },
    {
        "key": "Flushed",
        "emoji": "Flushed.svg"
    },
    {
        "key": "Pleading",
        "emoji": "Pleading.svg"
    },
    {
        "key": "Frowning2",
        "emoji": "Frowning2.svg"
    },
    {
        "key": "Anguished",
        "emoji": "Anguished.svg"
    },
    {
        "key": "Fearful",
        "emoji": "Fearful.svg"
    },
    {
        "key": "Anxious",
        "emoji": "Anxious.svg"
    },
    {
        "key": "Sad",
        "emoji": "Sad.svg"
    },
    {
        "key": "Crying1",
        "emoji": "Crying1.svg"
    },
    {
        "key": "Crying2",
        "emoji": "Crying2.svg"
    },
    {
        "key": "Screaming",
        "emoji": "Screaming.svg"
    },
    {
        "key": "Confounded",
        "emoji": "Confounded.svg"
    },
    {
        "key": "Persevering",
        "emoji": "Persevering.svg"
    },
    {
        "key": "Disappointed",
        "emoji": "Disappointed.svg"
    },
    {
        "key": "Downcast",
        "emoji": "Downcast.svg"
    },
    {
        "key": "Weary",
        "emoji": "Weary.svg"
    },
    {
        "key": "Tired",
        "emoji": "Tired.svg"
    },
    {
        "key": "Yawning",
        "emoji": "Yawning.svg"
    },
    {
        "key": "SteamNose",
        "emoji": "SteamNose.svg"
    },
    {
        "key": "Pouting1",
        "emoji": "Pouting1.svg"
    },
    {
        "key": "Symbols",
        "emoji": "Symbols.svg"
    },
    {
        "key": "Horns1",
        "emoji": "Horns1.svg"
    },
    {
        "key": "Horns2",
        "emoji": "Horns2.svg"
    },
    {
        "key": "Skull",
        "emoji": "Skull.svg"
    },
    {
        "key": "Poo",
        "emoji": "Poo.svg"
    },
    {
        "key": "Alien1",
        "emoji": "Alien1.svg"
    },
    {
        "key": "Alien2",
        "emoji": "Alien2.svg"
    },
    {
        "key": "Grinning6",
        "emoji": "Grinning6.svg"
    },
    {
        "key": "Grinning7",
        "emoji": "Grinning7.svg"
    },
    {
        "key": "TearsJoy2",
        "emoji": "TearsJoy2.svg"
    },
    {
        "key": "HeartEyes2",
        "emoji": "HeartEyes2.svg"
    },
    {
        "key": "WrySmile",
        "emoji": "WrySmile.svg"
    },
    {
        "key": "Kissing",
        "emoji": "Kissing.svg"
    },
    {
        "key": "Scared",
        "emoji": "Scared.svg"
    },
    {
        "key": "Crying3",
        "emoji": "Crying3.svg"
    },
    {
        "key": "Pouting2",
        "emoji": "Pouting2.svg"
    },
    {
        "key": "KissMark",
        "emoji": "KissMark.svg"
    },
    {
        "key": "Letter",
        "emoji": "Letter.svg"
    },
    {
        "key": "Arrow",
        "emoji": "Arrow.svg"
    },
    {
        "key": "Ribbon",
        "emoji": "Ribbon.svg"
    },
    {
        "key": "Sparkling",
        "emoji": "Sparkling.svg"
    },
    {
        "key": "Growing",
        "emoji": "Growing.svg"
    },
    {
        "key": "Beating",
        "emoji": "Beating.svg"
    },
    {
        "key": "TwoHearts",
        "emoji": "TwoHearts.svg"
    },
    {
        "key": "Exclamation",
        "emoji": "Exclamation.svg"
    },
    {
        "key": "Broken",
        "emoji": "Broken.svg"
    },
    {
        "key": "HeartFire",
        "emoji": "HeartFire.svg"
    },
    {
        "key": "Mending",
        "emoji": "Mending.svg"
    },
    {
        "key": "Anger",
        "emoji": "Anger.svg"
    },
    {
        "key": "Collision",
        "emoji": "Collision.svg"
    },
    {
        "key": "Sweat",
        "emoji": "Sweat.svg"
    },
    {
        "key": "Dashing",
        "emoji": "Dashing.svg"
    },
    {
        "key": "Bomb",
        "emoji": "Bomb.svg"
    },
    {
        "key": "Zzz",
        "emoji": "Zzz.svg"
    },
    {
        "key": "Waving",
        "emoji": "Waving.svg"
    },
    {
        "key": "Back",
        "emoji": "Back.svg"
    },
    {
        "key": "Splayed",
        "emoji": "Splayed.svg"
    },
    {
        "key": "OK",
        "emoji": "OK.svg"
    },
    {
        "key": "Victory",
        "emoji": "Victory.svg"
    },
    {
        "key": "Crossed",
        "emoji": "Crossed.svg"
    },
    {
        "key": "LoveYou",
        "emoji": "LoveYou.svg"
    },
    {
        "key": "Honour",
        "emoji": "Honour.svg"
    },
    {
        "key": "CallMe",
        "emoji": "CallMe.svg"
    },
    {
        "key": "Left",
        "emoji": "Left.svg"
    },
    {
        "key": "Right",
        "emoji": "Right.svg"
    },
    {
        "key": "Up1",
        "emoji": "Up1.svg"
    },
    {
        "key": "Down1",
        "emoji": "Down1.svg"
    },
    {
        "key": "Up2",
        "emoji": "Up2.svg"
    },
    {
        "key": "Down2",
        "emoji": "Down2.svg"
    },
    {
        "key": "Fist1",
        "emoji": "Fist1.svg"
    },
    {
        "key": "Fist2",
        "emoji": "Fist2.svg"
    },
    {
        "key": "Fist3",
        "emoji": "Fist3.svg"
    },
    {
        "key": "Fist4",
        "emoji": "Fist4.svg"
    },
    {
        "key": "Clapping",
        "emoji": "Clapping.svg"
    },
    {
        "key": "OpenHands",
        "emoji": "OpenHands.svg"
    },
    {
        "key": "PalmsUp",
        "emoji": "PalmsUp.svg"
    },
    {
        "key": "Handshake",
        "emoji": "Handshake.svg"
    },
    {
        "key": "Folded",
        "emoji": "Folded.svg"
    },
    {
        "key": "Ear",
        "emoji": "Ear.svg"
    },
    {
        "key": "Nose",
        "emoji": "Nose.svg"
    },
    {
        "key": "Sakura",
        "emoji": "Sakura.svg"
    },
    {
        "key": "Rose",
        "emoji": "Rose.svg"
    },
    {
        "key": "Hibiscus",
        "emoji": "Hibiscus.svg"
    },
    {
        "key": "Sunflower",
        "emoji": "Sunflower.svg"
    },
    {
        "key": "Blossom",
        "emoji": "Blossom.svg"
    },
    {
        "key": "Tulip",
        "emoji": "Tulip.svg"
    },
    {
        "key": "Grapes",
        "emoji": "Grapes.svg"
    },
    {
        "key": "Melon",
        "emoji": "Melon.svg"
    },
    {
        "key": "Watermelon",
        "emoji": "Watermelon.svg"
    },
    {
        "key": "Tangerine",
        "emoji": "Tangerine.svg"
    },
    {
        "key": "Lemon",
        "emoji": "Lemon.svg"
    },
    {
        "key": "Banana",
        "emoji": "Banana.svg"
    },
    {
        "key": "Pineapple",
        "emoji": "Pineapple.svg"
    },
    {
        "key": "Apple1",
        "emoji": "Apple1.svg"
    },
    {
        "key": "Apple2",
        "emoji": "Apple2.svg"
    },
    {
        "key": "Pear",
        "emoji": "Pear.svg"
    },
    {
        "key": "Peach",
        "emoji": "Peach.svg"
    },
    {
        "key": "Cherries",
        "emoji": "Cherries.svg"
    },
    {
        "key": "Strawberry",
        "emoji": "Strawberry.svg"
    },
    {
        "key": "Tomato",
        "emoji": "Tomato.svg"
    },
    {
        "key": "Eggplant",
        "emoji": "Eggplant.svg"
    },
    {
        "key": "Hamburger",
        "emoji": "Hamburger.svg"
    },
    {
        "key": "Pizza",
        "emoji": "Pizza.svg"
    },
    {
        "key": "WineGlass",
        "emoji": "WineGlass.svg"
    },
    {
        "key": "Cocktail",
        "emoji": "Cocktail.svg"
    },
    {
        "key": "Tropical",
        "emoji": "Tropical.svg"
    },
    {
        "key": "BeerMug",
        "emoji": "BeerMug.svg"
    },
    {
        "key": "Crescent",
        "emoji": "Crescent.svg"
    },
    {
        "key": "Sun",
        "emoji": "Sun.svg"
    },
    {
        "key": "Star",
        "emoji": "Star.svg"
    },
    {
        "key": "Cloud1",
        "emoji": "Cloud1.svg"
    },
    {
        "key": "Cloud2",
        "emoji": "Cloud2.svg"
    },
    {
        "key": "Cloud3",
        "emoji": "Cloud3.svg"
    },
    {
        "key": "Droplet",
        "emoji": "Droplet.svg"
    },
    {
        "key": "Halloween",
        "emoji": "Halloween.svg"
    },
    {
        "key": "Christmas",
        "emoji": "Christmas.svg"
    },
    {
        "key": "Santa",
        "emoji": "Santa.svg"
    },
    {
        "key": "Balloon",
        "emoji": "Balloon.svg"
    },
    {
        "key": "Popper",
        "emoji": "Popper.svg"
    }
]

export default expressin;
