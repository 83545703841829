import { CONTROL_PERMISSION, LIVE_CONTROL_PERMISSION } from "../constant"
const controlPermission = (preState = {}, action) => {
    const {type, data} = action
    switch (type) {
        case CONTROL_PERMISSION:
            if(JSON.stringify(preState) !== JSON.stringify(data)){
                return  {...data}
            }else{
                return preState
            }
        case LIVE_CONTROL_PERMISSION:
            return  {...preState, ...data}
        default:
            return preState
    }
}

export default controlPermission
