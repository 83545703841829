import {connect} from 'react-redux';
import Header from "./components/header";
import ChatList from "@/components/chat-list";
import ChatOperate from "./components/chat-operate";
import EvakeRemote from './components/evake-remote';
import InitPage from "@/components/init-page";
import EndChat from '@/components/end-chat';
import ErrorPage from "@/components/error";
import Dialog from "@/components/dialog";
import './index.scss';
import React, { useEffect, useState, useRef, useCallback } from "react";
import $api from '@/serve/api';
import { SocketService } from "@/serve/socket-service";
import SocketEvent from '@/serve/socket-event';
import aesUtils from '@/utils/aes/aes-utils';
import { updateControlState } from "@/redux/action/control-state.js";
import { updateMovingBallState } from "@/redux/action/moving-ball.js";
import { updateChatList } from "@/redux/action/chat-list.js";
import { updateJoinerInfo } from "@/redux/action/joiner-info.js";
import { updateControlPermission, updateLiveControlPermission } from "@/redux/action/control-permission.js";
import { isPc, formatSeconds, getMsgBody, generateMsgId, getUrlParam } from "@/utils/common.js";
import { v4 as uuidv4 } from 'uuid';
import postMessageToApp from "./connectApp/postMessageToApp";
import toysArr from "@/utils/toy.js";
import { orderLevel } from '@/utils/order-utils.js';
import { preloadAd } from '@/utils/preloadAd.js';

const MobilePage = (props) => {
    let { updateControlState, updateJoinerInfo, updateControlPermission, updateChatList, urlInfo, joinerInfo, updateLiveControlPermission } = props;
    const [tmpSocketService] = useState(new SocketService());
    const [creatorId, setCreatorId] = useState(null);
    const [joinerId, setJoinerId] = useState(null);
    const [linkId, setLinkId] = useState(null);
    const [chatInfo, setChatInfo] = useState(null);
    const [toysData, setToysData] = useState({toys: []});
    const [joinerToy, setJoinerToy] = useState([]);
    const [creatorToy, setCreatorToy] = useState([]);

    //因为避免画线(orderLine)和玩具(toysData)耦合，故分开两个状态管理
    const [toyStatus, setToyStatus] = useState({tStatus: null});
    const [email, setEmail] = useState(null);
    const [linkInfo, setLinkInfo] = useState(null);
    const [userOnline, setUserOnline] = useState(true);
    const [userOnApp, setUserOnApp] = useState("unknown"); //"unknown":未知  online：在线   offline：不在线
    const timeOut = useRef(null);

    // 判断是否T2（多次使用的link
    const isT2 = window.location.href.includes('/t2/')
    const [longKey, setLongKey] = useState(''); // 是否获取长链接的key

    let pc = isPc();
    let device = urlInfo.device;
    if(device && (device === "android" || device === "ios" || device === "pad")){
        pc = false;
    }
    let isPad = /iPad|iPod/i.test(navigator.userAgent);
    let isSafari = /Safari/i.test(navigator.userAgent);
    let hasTouchFunc = 'ontouchend' in document;
    if(isSafari && hasTouchFunc){
        isPad = true;
    }
    if(isPad){
        pc = false;
    }
    const [loading, setLoading] = useState(true); //加载状态
    const [qrCode, setQrCode] = useState(null);   //二维码
    const [isEnd, setIsEnd] = useState({end:false, endType:''});
    const [isCheck, setIsCheck] = useState(false); //判断是否可用
    const [errPageStatus, setErrPageStatus] = useState(''); // 显示错误页面
    const [tips, setTips] = useState('');
    const [time, setTime] = useState('');
    const [stop, setStop] = useState(false);
    const [fromCam, setFromCam] = useState(true);// 是否来自插件
    const [reachMax, setReachMax] = useState(false);
    // 判断是否第一次加入
    const [joinerFirstTime, setJoinerFirstTime] = useState(false);
    const [isLeaveRoom, setIsLeaveRoom] = useState(false);

    // 关闭弹框
    const closeTips = () => {
        localStorage.setItem('taptip', Date.now() + 30 * 3600 * 24 * 1000) // 设置一个月内不会再次弹出
        setTips('')
    }

    // 对后端返回的玩具指令进行处理
    const dealToys = (toys = []) => {
        console.warn(`--dealToys toys: `, toys);
        const dealtToys = []
        toys.forEach(toy => {
            let toyFuns = (toy.toyFun || 'v').split(',')
            if (toyFuns.includes('v') && toyFuns.includes('v1')) { // v和v1同时出现时，不需要v
                const vIdx = toyFuns.indexOf('v')
                toyFuns.splice(vIdx, 1)
            }
            if (['tenera', 'q01'].includes(toy.type.toLowerCase())) { // Tenera玩具只有s模式，不需要v模式
                toyFuns = ['s']
                toy.type = 'tenera'
            }
            if (toy.type.toLowerCase() === 'flexer') { // flexer玩具后端可能返回'v,f,v1,v2', 只需要'v,f'
                toyFuns = ['v', 'f']
            }
            if (toy.type.toLowerCase() === 'gravity') { // gravity玩具后端可能只返回'v', 需要'v,t'
                toyFuns = ['v', 't']
            }
            if (toy.type.toLowerCase() === 'xmachine' || toy.type.toLowerCase() === 'mini xmachine') { // xmachine玩具后端返回'v,t'，只需要't'
                toyFuns = ['t']
            }
            if (toy.type.toLowerCase() === 'solace pro') { // solace pro 具体使用的哪个模式 // t,pos
                // if(!toy.workMode) return // 无 workMode 则忽略这个玩具信息
                toyFuns = toy.workMode === 'speed' ? ['t'] : ['pos']
            }
            let tempArr = []
            orderLevel.forEach(o => { // 对指令进行重排序，保持控制球的顺序
                if (toyFuns.includes(o)) tempArr.push(o)
            })
            toy.toyFun = tempArr.join(',')
            dealtToys.push(toy)
        })
        console.log(`--dealtToys: `, dealtToys);
        return dealtToys
    }

    const [joinedFlag, setJoinedFlag] = useState(false); // 是否已经提示join
    // const [leftFlag, setLeftFlag] = useState(false); // 是否已经提示left
    const joinerInfoChangeCallback = useCallback(()=>{
        // console.log(`--joinerInfo joinerInfoChangeCallback: `, joinerInfo);
        if(joinerInfo.appPage === 'control_link') { // app在control_link房间
            if(isLeaveRoom) { // 不在房间
                setIsLeaveRoom(false)
                if(!joinedFlag){ // 未发送过joined
                    setJoinedFlag(true)
                    updateChatList([{...getMsgBody(linkId, { tips: `The other user joined the room.` }, 'roomtips', `joinroom-${linkId}`), createTime: Date.now(), msgId: generateMsgId()}])
                }
            }
        }else{ // other
            if(!isLeaveRoom) { // 在房间
                setIsLeaveRoom(true)
            //     if(!leftFlag){ // 未发送过left
            //         setLeftFlag(true)
            //         updateChatList([{...getMsgBody(linkId, { tips: `The other user is left this room. You can still control their toy(s).` }, 'roomtips', `outroom-${linkId}`), createTime: Date.now(), msgId: generateMsgId()}])
            //     }
            }
        }
    },[joinerInfo, linkId, updateChatList, isLeaveRoom, joinedFlag])

    useEffect(()=>{
        // console.log(`--joinerInfo watch: `, joinerInfo);
        if(joinerInfo?.appPage) joinerInfoChangeCallback()
    },[joinerInfo, joinerInfoChangeCallback])

    useEffect(() => {
        if (!pc && !tips && !toysData.toys.length) {
            const statusFun = (res) => {
                setLoading(false);
                const idAlias = sessionStorage.getItem('idAlias')
                if(idAlias && idAlias.includes(`${urlInfo.code}-`)){
                    const arr = idAlias.split(`${urlInfo.code}-`)
                    setEmail(arr[1]);
                }
                if(`${res.code}` === '50091'){
                    setErrPageStatus('isShowLimit')
                    return '50091'
                }
                if(`${res.code}` === '50092'){
                    setErrPageStatus('isBanPage')
                    return '50092'
                }
                if(`${res.code}` === '5009101'){
                    setIsEnd({end: true, endType: 'valid'})
                    setIsCheck(true)
                    return '5009101'
                }
                if(`${res.code}` === '5009102'){
                    setIsEnd({end: true, endType: 'banned'})
                    setIsCheck(true)
                    return '5009102'
                }
                if(`${res.code}` === '5009103'){
                    setIsEnd({end: true, endType: 'expired'})
                    setIsCheck(true)
                    return '5009103'
                }
                if(`${res.code}` === '5009104'){
                    setIsEnd({end: true, endType: 'taken'})
                    setIsCheck(true)
                    return '5009104'
                }
                if(`${res.code}` === '50093'){
                    const { data } = res
                    if(`${data.controllerBanned}` === 'true') {
                        setIsEnd({end: true, endType:`ban-${data.timeUnit}-${data.banTime}`})
                        setIsCheck(true)
                        return '50093'
                    }
                    setIsEnd({end: true})
                    setIsCheck(true)
                    return '50093'
                }
                if (res?.data?.anotherPlaying) {
                    setErrPageStatus('isAnotherPlaying');
                    return 'error'
                }
                if(`${res.result}` !== 'true') {
                    setErrPageStatus('default')
                    return 'error'
                }
            }
            if(!isEnd.end && isT2 && !longKey && !isCheck){
                const anonKey = localStorage.getItem('anonKey')
                let bodyFormData = new FormData();
                bodyFormData.append('shortCode', urlInfo.code);
                if(anonKey) bodyFormData.append('anonKey', anonKey);
                $api.longtimecontrollink(bodyFormData).then(res => {
                    if(statusFun(res)) return
                    const { data } = res
                    setLongKey(data.id)
                    if(data.anonKey) localStorage.setItem('anonKey' ,data.anonKey)
                    if(data.shareTophy) sessionStorage.setItem('shareTophy' ,data.shareTophy)
                }).catch(err => {
                    setErrPageStatus('default')
                    setLoading(false);
                })
                return
            }
            if(!isEnd.end && !isCheck && !urlInfo.isCreator && !tips){
                let bodyFormData = new FormData();
                bodyFormData.append('id', isT2? longKey : urlInfo.code);
                bodyFormData.append('historyUrl', document.referrer);
                $api.anonControllinkStatus(bodyFormData).then(res => {
                    if(statusFun(res)) return
                    if(localStorage.getItem('taptip') < Date.now()){
                        setTips(()=>{
                            return (
                                <span>You are going to control the Lovense toys from this link. By clicking OK below you agree to our <a style={{color: '#FF2D89'}} href='https://hyttoapps.bandnana.com/remote/terms-and-conditions' target='_blank' rel="noreferrer">Terms & Conditions.</a> Contact us <a style={{color: '#FF2D89'}} href='https://www.lovense.com/contact' target='_blank' rel="noreferrer">here.</a> </span>
                            )
                        })
                    }
                    setIsCheck(true)
                }).catch(err => {
                    setErrPageStatus('default')
                    setLoading(false);
                })
            }
            if(urlInfo.isCreator) setIsCheck(true)
            if(isEnd && !isEnd.end && isCheck){
                let timer5 = null;
                let otherSideUserId = null; //对方的userId
                localStorage.setItem("joinerToy", "");
                localStorage.setItem("creatorToy", "");
                localStorage.setItem("toysData", "");
                //记录remoteApp是否加入并发起了控制
                //取值范围：""表示未发起控制, accept表示已发起控制, end表示退出控制
                localStorage.setItem("remoteAppSyncControlStatus","");
                localStorage.setItem("remoteAppLiveControlStatus","");
                if(urlInfo.isCreator){      //被控方(创建者)进入聊天控制页面
                    let bodyFormData = new FormData();
                    bodyFormData.append('linkId', urlInfo.linkId);
                    bodyFormData.append('code', urlInfo.code);
                    $api.anonCreatorOpenRoom(bodyFormData).then(res => {
                        if (res.result) {
                            const {controlLinkData: { linkStatus, linkId }} = res.data;
                            window.lvsStatus = () => {
                                return !Boolean(document.querySelector('.end-chat'))
                            }
                            window.lvsClose = () => {
                                if(document.querySelector('.end-chat')) return false
                                tmpSocketService.socketEmitMsg(SocketEvent.ANON_END_CONTROL_LINK_TS, { ackId:uuidv4(), linkId:linkId });
                                // 三秒后没收到服务器响应则跳转默认结束页面
                                timeOut.current = setTimeout(() => {
                                    tmpSocketService.disconnect()
                                    setIsEnd({end: true})
                                }, 3000);
                                return true
                            }
                            if(!window.sessionStorage.getItem(linkId+'linkId-log-flag')){
                                let element_type = '2'
                                if(/Tophy|tophy/i.test(navigator.userAgent)) element_type = '1'
                                let text = {
                                    "logNo":"S0009",
                                    "content": JSON.stringify({
                                        "page_name":"Control Link Open",
                                        "event_id":"control_link_open",
                                        "event_type":"open",
                                        "element_id":"open_" + linkId,  //元素id，xxx为 lastActiveSessionId
                                        "element_type": element_type ,
                                        "element_content": window.sessionStorage.getItem('shareTophy')? "1" : "2",
                                        "element_name":"",
                                        "duration":"",
                                        "toys":""
                                    }),
                                    "timeStamp": new Date().getTime()
                                }
                                $api.logsNewV2(text)
                                window.sessionStorage.setItem(linkId+'linkId-log-flag', '1')
                            }
                            if(linkStatus !== 0){ //0:加入成功,可以连接socket; 1:id已经有人使用过了; 2:失效
                                setIsEnd({end:true});
                            }else{
                                const {
                                    controlLinkData: {link,x,y,joinerFirstTime,creator,joiner},
                                    socketIoPath,
                                    wsUrl: wsServerUrl,
                                    qrCode,
                                    idAlias
                                } = res.data;
                                // 存储x,y给到msgData信息使用aes做加解密处理
                                localStorage.setItem('aesEncryp', JSON.stringify({x, y}));
                                let toys = null;
                                if(joiner){ //控制方(加入者)
                                    toys = dealToys(joiner.toys);
                                    if(toys && toys.length > 0){ //若控制方有连接玩具，则优先显示控制方的的玩具
                                        let tmpToysData = {toys: toys};
                                        setToysData(tmpToysData);
                                        // setJoinerToy(toys);
                                        localStorage.setItem("toysData", JSON.stringify(toys));
                                        localStorage.setItem("joinerToy", JSON.stringify(toys));
                                        // setToyStatus({tStatus: toys});
                                        if (toys && toys.length > 1) {
                                            let toysName = [];
                                            for (const item of toys) {
                                                let tmpName = toysArr[(item.type+"").toLowerCase()];
                                                if(!tmpName){
                                                    tmpName = item.type;
                                                }
                                                if(item.version){
                                                    toysName.push(tmpName + " " + item.version);
                                                }else{
                                                    toysName.push(tmpName);
                                                }
                                            }
                                            res.data.controlLinkData.toysName = toysName.join(',');
                                        }
                                    }
                                    otherSideUserId = joiner.userId;
                                    setJoinerId(joiner.userId);
                                }
                                if(creator){ //被控方(创建者)
                                    if(toys && toys.length > 0){

                                    }else{
                                        //若控制方没有连接玩具，则显示自己的玩具
                                        toys = dealToys(creator.toys);
                                        let tmpToysData = {toys: toys};
                                        setToysData(tmpToysData);
                                        setCreatorToy(toys);
                                        localStorage.setItem("toysData", JSON.stringify(toys));
                                        localStorage.setItem("creatorToy", JSON.stringify(toys));
                                        setToyStatus({tStatus: toys});
                                        if (toys && toys.length > 1) {
                                            let toysName = [];
                                            for (const item of toys) {
                                                let tmpName = toysArr[(item.type+"").toLowerCase()];
                                                if(!tmpName){
                                                    tmpName = item.type;
                                                }
                                                if(item.version){
                                                    toysName.push(tmpName + " " + item.version);
                                                }else{
                                                    toysName.push(tmpName);
                                                }
                                            }
                                            res.data.controlLinkData.toysName = toysName.join(',');
                                        }
                                    }
                                    setCreatorId(creator.userId);
                                }
                                setEmail(idAlias);
                                sessionStorage.setItem('idAlias', `${urlInfo.code}-${idAlias}`)
                                setQrCode(qrCode);
                                if (link) {
                                    if (link.expires > 0) {
                                        link.leftControlTimeText = formatSeconds(link.leftControlTime);
                                    } else if (link.expires < 0 && !link.isStart) {
                                        link.leftControlTimeText = '00:00'; //永久
                                    }
                                    setLinkInfo(link);
                                    if(!timer5){
                                        // console.log("进入页面，轮询controlLink信息.....");
                                        refreshControlLinkEvent(JSON.stringify({"linkId":linkId}), linkStatus);
                                    }
                                    //玩具控制状态 0未开始，1控制中 2已失效
                                    let clState = link.isStart ? 1 : 0;
                                    if(linkStatus !== 0){
                                        clState = 2;
                                    }
                                    updateControlState({"state":clState});
                                }
                                let msgDataStr = aesUtils.aesEncryptXy(JSON.stringify(res.data.controlLinkData));
                                let joinerUserId = joiner ? joiner.userId : "";
                                const chatInfo = {
                                    ackId: uuidv4(),
                                    dateImType: 'control_link',
                                    dateImTypeData: linkId,
                                    fix: 'right',
                                    isFirst: joinerFirstTime,
                                    msgData: msgDataStr,
                                    msgType: "controllink",
                                    msgVer: 8,
                                    toId: joinerUserId
                                }
                                setChatInfo(chatInfo);
                                setLinkId(linkId);
                                tmpSocketService.initSocket(wsServerUrl, socketIoPath);
                                //connect app 关闭OK窗口进入创建者页面，重新拉去未读信息
                                setTimeout(()=>{
                                    //进入页面获取未读消息
                                    tmpSocketService.socketEmitMsg(SocketEvent.Q_GET_USER_NEW_MSG_LIST_TS, { dateImTypeData:linkId,msgId:'' });
                                    //创建者打开页面则发一条请求liveControl的消息给加入者
                                    // const msgData = { type: 'request', id: generateMsgId() };
                                    // const params = getMsgBody(linkId, msgData, 'live', joinerUserId);
                                    // tmpSocketService.socketEmitMsg(SocketEvent.Q_SEND_IM_MSG_TS, params);
                                }, 200);
                            }
                        } else {
                            setErrPageStatus('default');
                        }
                        setTimeout(() => {
                            setLoading(false);
                        }, 250);
                    }, err => {
                        setErrPageStatus('default');
                        setLoading(false);
                    });
                }else{  //主控方加入控制
                    let bodyFormData = new FormData();
                    bodyFormData.append('id', isT2? longKey : urlInfo.code);
                    bodyFormData.append('historyUrl', document.referrer);
                    const refererChannel = getUrlParam('referer_channel')
                    if(refererChannel) bodyFormData.append('refererChannel', refererChannel);
                    $api.anonControllinkJoin(bodyFormData).then(res => {
                        if (res.result) {
                            if (res.data.anotherPlaying) {
                                setErrPageStatus('isAnotherPlaying');
                            }
                            const {controlLinkData: { linkStatus, linkId, controlPermission }} = res.data;
                            const isOpenControlPermission = controlPermission && controlPermission.openControlPermission
                            if(!pc) {
                                window.lvsStatus = () => {
                                    return !Boolean(document.querySelector('.end-chat'))
                                }
                                window.lvsClose = () => {
                                    if(document.querySelector('.end-chat')) return false
                                    tmpSocketService.socketEmitMsg(SocketEvent.ANON_END_CONTROL_LINK_TS, { ackId:uuidv4(), linkId:linkId });
                                    // 三秒后没收到服务器响应则跳转默认结束页面
                                    timeOut.current = setTimeout(() => {
                                        tmpSocketService.disconnect()
                                        setIsEnd({end: true})
                                    }, 3000);
                                    return true
                                }
                                if(!window.sessionStorage.getItem(linkId+'linkId-log-flag')){
                                    let element_type = '2'
                                    if(/Tophy|tophy/i.test(navigator.userAgent)) element_type = '1'
                                    let text = {
                                        "logNo":"S0009",
                                        "content": JSON.stringify({
                                            "page_name":"Control Link Open",
                                            "event_id":"control_link_open",
                                            "event_type":"open",
                                            "element_id":"open_" + linkId,  //元素id，xxx为 lastActiveSessionId
                                            "element_type": element_type ,
                                            "element_content": window.sessionStorage.getItem('shareTophy')? "1" : "2",
                                            "element_name": isOpenControlPermission ? "1" : "2", // 元素名称，是否开启控制请求，1--开启control permission，2--关闭control permission
                                            "duration":"",
                                            "toys":""
                                        }),
                                        "timeStamp": new Date().getTime()
                                    }
                                    $api.logsNewV2(text)
                                    window.sessionStorage.setItem(linkId+'linkId-log-flag', '1')
                                }
                            }
                            if(linkStatus !== 0){
                                setIsEnd({end:true})
                            }else{
                                const {
                                    controlLinkData: {link, x, y, joinerFirstTime, creator, controlPermission},
                                    socketIoPath,
                                    wsUrl: wsServerUrl,
                                    qrCode,
                                    fromCam,
                                    idAlias
                                } = res.data;
                                if(!controlPermission){
                                    updateLiveControlPermission({openControlPermission: false})
                                }else{
                                    updateControlPermission(controlPermission)
                                }
                                // 存储x,y给到msgData信息使用aes做加解密处理
                                localStorage.setItem('aesEncryp', JSON.stringify({x, y}));
                                let toys = null;
                                if(creator){
                                    toys = dealToys(creator.toys);
                                    setToysData({toys: toys});
                                    setCreatorToy(toys);
                                    localStorage.setItem("toysData", JSON.stringify(toys));
                                    localStorage.setItem("creatorToy", JSON.stringify(toys));
                                    setToyStatus({tStatus: toys});
                                    if (toys && toys.length > 1) {
                                        let toysName = [];
                                        for (const item of toys) {
                                            let tmpName = toysArr[(item.type+"").toLowerCase()];
                                            if(!tmpName){
                                                tmpName = item.type;
                                            }
                                            if(item.version){
                                                toysName.push(tmpName + " " + item.version);
                                            }else{
                                                toysName.push(tmpName);
                                            }
                                        }
                                        res.data.controlLinkData.toysName = toysName.join(',');
                                    }
                                    otherSideUserId = creator.userId;
                                    setCreatorId(creator.userId);
                                }
                                setFromCam(fromCam || false);
                                setEmail(idAlias);
                                sessionStorage.setItem('idAlias', `${urlInfo.code}-${idAlias}`)
                                setQrCode(qrCode);
                                if (link) {
                                    if (link.expires > 0) {
                                        link.leftControlTimeText = formatSeconds(link.leftControlTime);
                                    } else if (link.expires < 0 && !link.isStart) {
                                        link.leftControlTimeText = '00:00'; //永久
                                    }
                                    setLinkInfo(link);
                                    if(!timer5){
                                        // console.log("进入页面，轮询controlLink信息.....");
                                        refreshControlLinkEvent(JSON.stringify({"linkId":linkId}), linkStatus);
                                    }
                                    //玩具控制状态 0未开始，1控制中 2已失效
                                    let clState = link.isStart ? 1 : 0;
                                    if(linkStatus !== 0){
                                        clState = 2;
                                    }
                                    updateControlState({"state":clState});
                                }
                                let msgDataStr = aesUtils.aesEncryptXy(JSON.stringify(res.data.controlLinkData));
                                const chatInfo = {
                                    ackId: uuidv4(),
                                    toId: creator.userId,
                                    fix: 'right',
                                    isFirst: joinerFirstTime,
                                    msgVer: 8,
                                    msgType: "controllink",
                                    msgData: msgDataStr,
                                    dateImType: 'control_link',
                                    dateImTypeData: linkId
                                }
                                setChatInfo(chatInfo);
                                setLinkId(linkId);
                                tmpSocketService.initSocket(wsServerUrl, socketIoPath);
                                // 打开控制面板
                                tmpSocketService.socketEmitMsg(SocketEvent.ANON_OPEN_CONTROL_PANEL_TS, {linkId: linkId});

                                // 判断是否第一次加入
                                if (joinerFirstTime) {
                                    // 清除聊天记录缓存
                                    setTimeout(() => localStorage.setItem('chatList', JSON.stringify([])));
                                    setJoinerFirstTime(joinerFirstTime);
                                    // 发送第一条默认消息
                                    try{
                                        const sendData = {
                                            controlLinkData: JSON.stringify(res.data.controlLinkData)
                                        }
                                        const params = getMsgBody(linkId, sendData, 'controllink', creator.userId);
                                        setTimeout(() => {
                                            let type = SocketEvent.Q_SEND_IM_MSG_TS;
                                            tmpSocketService.socketEmitMsg(type, params);
                                        }, 500);
                                    }catch(e){
                                        console.error("error 280: ", e.message, res);
                                    }
                                } else {
                                    // 关闭窗口重新拉去未读信息
                                    tmpSocketService.socketEmitMsg(SocketEvent.Q_GET_USER_NEW_MSG_LIST_TS, { dateImTypeData:linkId,msgId: '' });
                                }
                                preloadAd(linkId)
                            }
                        } else {
                            setErrPageStatus('default');
                        }
                        setTimeout(() => {
                            setLoading(false);
                        }, 250);
                    }, err => {
                        setErrPageStatus('default');
                        setLoading(false);
                    })
                }
                const queryControlLinkInfo = (linkId) => {
                    if(urlInfo.isCreator){
                        let json1 = { "ackId":uuidv4(), "linkId":linkId };
                        tmpSocketService.socketEmitMsg(SocketEvent.CON_QUERY_CONTROL_INFO_TS, json1);
                    }else{
                        let json1 = { "ackId":uuidv4(), "linkId": linkId };
                        tmpSocketService.socketEmitMsg(SocketEvent.ANON_QUERY_CONTROL_INFO_TS, json1);
                    }
                    //询问是否在线
                    if(isEnd && !isEnd.end){
                        let json2 = { ackId:uuidv4(), userId:otherSideUserId, linkId:linkId };
                        tmpSocketService.socketEmitMsg(SocketEvent.QUERY_USER_ON_LINE_INFO_TS, json2);
                    }
                }
                //remote开始控制玩具，controlLink状态变更
                const refreshControlLinkEvent = (res, linkStatus) => {
                    // console.log("remote开始控制玩具，controlLink状态变更-->", res);
                    if (tmpSocketService) {
                        let linkId = JSON.parse(res).linkId;
                        queryControlLinkInfo(linkId);
                        setTimeout(()=>{
                            queryControlLinkInfo(linkId);
                        },1000);
                        setTimeout(()=>{
                            queryControlLinkInfo(linkId);
                        },2000);

                        clearInterval(timer5);
                        timer5 = setInterval(() => {  //定时更新controlLink基本信息
                            queryControlLinkInfo(linkId);
                            if(isEnd && !isEnd.end){
                            } else if(linkStatus === 2){ //已失效
                                clearInterval(timer5);
                            }
                        }, 5000);
                    }
                }

                //检查最新的joinerToy、creatorToy、toysData，是否与本地缓存发生变化
                //如果变化了，则更新相关useState
                const checkUpdateToys = (key, updateToy) => {
                    let update = false;
                    if(updateToy){
                        let lsToysData = [];
                        let localStorageToysData = localStorage.getItem(key);
                        if(localStorageToysData){
                            lsToysData = JSON.parse(localStorageToysData);
                        }
                        if(lsToysData && lsToysData.length > 0){
                            //socket返回的最新的玩具数据
                            let dArr = [];
                            for(let d=0; d<updateToy.length; d++){
                                let dObj = updateToy[d];
                                let tmpObj = { type:dObj.type, id:dObj.id, status:dObj.status };
                                dArr.push(tmpObj);
                            }
                            //本地localStorage缓存的旧的玩具数据
                            let eArr = [];
                            for(let e=0; e<lsToysData.length; e++){
                                let eObj = lsToysData[e];
                                let tmpObj = { type:eObj.type, id:eObj.id, status:eObj.status };
                                eArr.push(tmpObj);
                            }
                            //匹配，如不相等则更新useState
                            if(dArr.length > 0 && eArr.length > 0 && JSON.stringify(dArr) !== JSON.stringify(eArr)){
                                update = true;
                            }
                        }else{
                            update = true; //第一次进入没有localStorage数据的时候，默认更新
                        }
                    }
                    return update;
                }
                //返回创建者定时查询controlLink的结果
                let tmpJoinerToy = null;
                let updateJoinerToyTime = 0;
                let updateCreatorToyTime = 0;
                const conQueryControlInfoTcEvent = (res) => {
                    let resObj = JSON.parse(res);
                    // console.log("查询controlLink结果 res=", resObj);
                    let obj = { controlLinkData: resObj };
                    const { controlLinkData: {link, linkStatus, creator, joiner} } = obj;
                    if (link) {
                        if (link.expires > 0) {
                            link.leftControlTimeText = formatSeconds(link.leftControlTime);
                        } else if (link.expires < 0 && !link.isStart) {
                            link.leftControlTimeText = '00:00'; //永久
                        }
                        setLinkInfo(link);
                        //玩具控制状态 0未开始，1控制中 2已失效
                        let clState = link.isStart ? 1 : 0;
                        if (linkStatus !== 0) {
                            clState = 2;
                        }
                        updateControlState({"state":clState});
                        if((link.isStart + "") === "true") {  //通知app加入者已开始控制玩具
                            let body = {
                                "version": 1,
                                "linkId": resObj.linkId,
                                "event": "controllinkDidStart",
                                "message": ""
                            };
                            postMessageToApp.post(JSON.stringify(body));
                        }
                    }
                    if(creator){
                        let toys = dealToys(creator.toys)
                        setToyStatus({tStatus:toys});
                        //只有玩具的type或者status发生了变化，才更新creatorToy
                        let updateCreatorToy = checkUpdateToys("creatorToy", toys);
                        if(updateCreatorToy){
                            setCreatorToy(toys);
                        	localStorage.setItem("creatorToy", JSON.stringify(toys));
                        }
                    }
                    let remoteAppSyncControlStatus = localStorage.getItem("remoteAppSyncControlStatus");
                    let remoteAppLiveControlStatus = localStorage.getItem("remoteAppLiveControlStatus");
                    //如果加入者有连接玩具，则创建者页面需显示加入者的玩具信息
                    if(urlInfo.isCreator && (remoteAppSyncControlStatus === "accept" || remoteAppLiveControlStatus === "accept")){
                        let oldValue = localStorage.getItem("userOnApp");
                        if(joiner && joiner.toys && joiner.toys.length > 0 && oldValue === "online"){
                            tmpJoinerToy = dealToys(joiner.toys);
                            setToyStatus( {tStatus:tmpJoinerToy} );
                            //只有玩具的type或者status发生了变化，才更新toysData、joinerToy
                            let updateJoinerToy = checkUpdateToys("joinerToy", tmpJoinerToy);
                            if(updateJoinerToy || updateJoinerToyTime === 0){
                                setJoinerToy(tmpJoinerToy);
                                updateJoinerToyTime++;
                                localStorage.setItem("joinerToy", JSON.stringify(tmpJoinerToy));
                            }
                            let updateToysData = checkUpdateToys("toysData", tmpJoinerToy);
                            if(updateToysData || updateCreatorToyTime === 0){
                               let tmpToysData = {toys: tmpJoinerToy};
                               setToysData(tmpToysData);
                               updateCreatorToyTime++;
                               localStorage.setItem("toysData", JSON.stringify(tmpJoinerToy));
                            }
                        }
                    }
                    if (linkStatus !== 0) {
                        if(!urlInfo.isCreator) setIsEnd({end:true});
                    }
                }
                //返回对方用户是否在线的查询结果
                const ackQueryUserOnlineInfoTcEvent = (res) => {
                    updateJoinerInfo(res)
                    if(res.onLine){
                        setUserOnline(true); //上线
                        let oldValue = localStorage.getItem("userOnApp");
                        if(res.onAndroid || res.onIos){
                            if(oldValue !== "online"){
                                setUserOnApp("online");
                                localStorage.setItem("userOnApp", "online");
                            }
                        }else{
                            if(oldValue !== "offline"){
                                setUserOnApp("offline");
                                localStorage.setItem("userOnApp", "offline");
                            }
                        }
                    }else{
                        setUserOnline(false); //掉线(通常是杀死app)
                    }
                }
                //controlLink信息需要刷新
                const qYouMustRefreshControlLinkTcEvent = (res) => {
                    try{
                        let linkId = JSON.parse(res).linkId;
                        if(urlInfo.isCreator){
                            let json1 = { "ackId":uuidv4(), "linkId":linkId };
                            tmpSocketService.socketEmitMsg(SocketEvent.CON_QUERY_CONTROL_INFO_TS, json1);
                        }else{
                            let json1 = { "ackId":uuidv4(), "linkId": linkId };
                            tmpSocketService.socketEmitMsg(SocketEvent.ANON_QUERY_CONTROL_INFO_TS, json1);
                        }
                    }catch(e){
                        console.error("388 error-->", e.message, res);
                    }
                }
                //刷新加入者id
                const anonTidRefreshTcEvent = (res) => {
                    try{
                        let obj = JSON.parse(res);
                        setJoinerId(obj.tid);
                    }catch(e){
                        console.error("error 426-- ", e.message, res);
                    }
                }

                if (tmpSocketService) {
                    const e = tmpSocketService._events;
                    if (!e.ANON_YOU_MUST_REFRESH_CONTROL_LINK_TC) {
                        tmpSocketService.on(SocketEvent.ANON_YOU_MUST_REFRESH_CONTROL_LINK_TC, refreshControlLinkEvent);
                    }
                    if (!e.CON_QUERY_CONTROL_INFO_TC) {
                        tmpSocketService.on(SocketEvent.CON_QUERY_CONTROL_INFO_TC, conQueryControlInfoTcEvent);
                    }
                    if (!e.ACK_QUERY_USER_ON_LINE_INFO_TC) {
                        tmpSocketService.on(SocketEvent.ACK_QUERY_USER_ON_LINE_INFO_TC, ackQueryUserOnlineInfoTcEvent);
                    }
                    if (!e.Q_YOU_MUST_REFRESH_CONTROL_LINK_TC) {
                        tmpSocketService.on(SocketEvent.Q_YOU_MUST_REFRESH_CONTROL_LINK_TC, qYouMustRefreshControlLinkTcEvent);
                    }
                    if (!e.ANON_TID_REFRESH_TC){
                        tmpSocketService.on(SocketEvent.ANON_TID_REFRESH_TC, anonTidRefreshTcEvent);
                    }
                }
                return function () {
                    if(timer5){
                        clearInterval(timer5);
                    }
                    // if (tmpSocketService) {
                    //     tmpSocketService.off(SocketEvent.ANON_YOU_MUST_REFRESH_CONTROL_LINK_TC, refreshControlLinkEvent);
                    //     tmpSocketService.off(SocketEvent.ANON_QUERY_CONTROL_INFO_TC, anonQueryControlInfoTcEvent);
                    //     tmpSocketService.off(SocketEvent.CON_QUERY_CONTROL_INFO_TC, conQueryControlInfoTcEvent);
                    //     tmpSocketService.off(SocketEvent.ACK_QUERY_USER_ON_LINE_INFO_TC, ackQueryUserOnlineInfoTcEvent);
                    //     tmpSocketService.off(SocketEvent.Q_YOU_MUST_REFRESH_CONTROL_LINK_TC, qYouMustRefreshControlLinkTcEvent);
                    //     tmpSocketService.off(SocketEvent.ANON_TID_REFRESH_TC, anonTidRefreshTcEvent);
                    // }
                }
            }
        }
    }, [tmpSocketService, updateControlState, pc, urlInfo, isEnd, tips, isCheck, isT2, longKey, updateJoinerInfo, toysData.toys.length, updateControlPermission, updateLiveControlPermission]);

    useEffect(()=>{
        //返回加入者查询controlLink的结果
        const anonQueryControlInfoTcEvent = (res) => {
            console.log("查询controlLink结果 res=", res);
            let obj = { controlLinkData: JSON.parse(res) };
            const { controlLinkData:{link,linkStatus,creator, controlPermission} } = obj;
            console.log(`--controlPermission: `, controlPermission);
            if(!controlPermission){
                updateLiveControlPermission({openControlPermission: false})
            }else{
                updateControlPermission(controlPermission)
            }
            if (link) {
                if (link.expires > 0) {
                    link.leftControlTimeText = formatSeconds(link.leftControlTime);
                } else if (link.expires < 0 && !link.isStart) {
                    link.leftControlTimeText = '00:00'; //永久
                }
                setLinkInfo(link);
                //玩具控制状态 0未开始，1控制中 2已失效
                let clState = link.isStart ? 1 : 0;
                if (linkStatus !== 0) {
                    clState = 2;
                }
                updateControlState({"state":clState});
            }
            if(creator){
                let toys = dealToys(creator.toys)
                setToyStatus( {tStatus: toys} );
                console.log(`--toys: `, toys);
                console.log(`--toysData: `, toysData.toys);
                // if(!toysData.toys?.length && toys.length){
                if(toysData.toys?.length !== toys.length || toys.some((toy,index) => (toy.workMode !== toysData.toys?.[index].workMode))){
                    setToysData({toys})
                }
            }
            if (linkStatus === 2) {
                const { controlLinkData: { punishment } } = obj
                if(`${punishment.controllerBanned}` === 'true') {
                    setIsEnd({end: true, endType:`ban-${punishment.timeUnit}-${punishment.banTime}`})
                    return
                }
                setIsEnd({end:true});
            }
        }

        tmpSocketService?.on(SocketEvent.ANON_QUERY_CONTROL_INFO_TC, anonQueryControlInfoTcEvent);
        return function () {
            tmpSocketService?.off(SocketEvent.ANON_QUERY_CONTROL_INFO_TC, anonQueryControlInfoTcEvent);
        }
    },[tmpSocketService, toysData.toys, updateLiveControlPermission, updateControlPermission, updateControlState])

    const [expiredTimestamp, setExpiredTimestamp] = useState(Date.now())
    useEffect(()=>{
        // 客户端接收控制权限的响应数据
        const controlPermissionResponseTcEvent = (res) => {
            console.log(`--controlPermissionResponseTcEvent: `, res);
            const { operationType } = JSON.parse(res)
            if(operationType === 'accept'){
                updateLiveControlPermission({joinerHasLiveControlPermission: true})
            }else if(operationType === 'decline'){
                updateChatList([{...getMsgBody(linkId, { tips: `Control request declined` }, 'tips', `outroom-${linkId}`), createTime: Date.now(), msgId: generateMsgId()}])
            }else if(operationType === 'expired'){
                if(Date.now() - expiredTimestamp > 60 * 1000){
                    updateChatList([{...getMsgBody(linkId, { tips: `Control request has expired` }, 'tips', `outroom-${linkId}`), createTime: Date.now(), msgId: generateMsgId()}])
                }else{
                    setExpiredTimestamp(Date.now())
                }
            }
        }
        tmpSocketService.on(SocketEvent.CL_CONTROL_PERMISSION_RESPONSE_TC, controlPermissionResponseTcEvent);
        return ()=>{
            tmpSocketService.off(SocketEvent.CL_CONTROL_PERMISSION_RESPONSE_TC, controlPermissionResponseTcEvent);
        }
    }, [tmpSocketService, updateLiveControlPermission, updateChatList, linkId, expiredTimestamp]);


    useEffect(()=>{
        if(isEnd && isEnd.end){
            //通知app加入者已结束控制玩具
            clearTimeout(timeOut.current)
            let body = { "linkId":linkId, "event":"controllinkDidEnd", "message":"" };
            postMessageToApp.post(JSON.stringify(body));
            //结束socket
            tmpSocketService.disconnect();
            //清除缓存的聊天消息
            updateChatList([]);
            localStorage.setItem('chatList', JSON.stringify([]));
        }
    }, [linkId,isEnd,tmpSocketService,updateChatList]);

    // 更新第一次进入状态为false
    const joinerFirstTimeChange = () => {
        setJoinerFirstTime(false)
    }
    // 点击结束socket按钮
    const endSocket = () => {
        tmpSocketService.socketEmitMsg(SocketEvent.ANON_END_CONTROL_LINK_TS, { ackId:uuidv4(), linkId:linkId });
        // 三秒后没收到服务器响应则跳转默认结束页面
        timeOut.current = setTimeout(() => {
            tmpSocketService.disconnect()
            setIsEnd({end: true})
        }, 3000);
    }
    // 通知浏览器结束, 通知占用倒计时消息开启/停止
    const endEvent = (e, time) => {
        let endType = e || ''
        if(e === 'reachMax') return setReachMax(true)
        if(e === 'over') return setStop('over')
        if(e === 'start') {
            if(urlInfo.isCreator && `${time}` === '0') {
                setStop('over')
            }
            if(time) setTime(time)
            return
        }
        tmpSocketService.disconnect()
        if(!urlInfo.isCreator) setIsEnd({end:true, endType})
    }
    const updateUserId = (userId) => {
        if(urlInfo.isCreator){
            setJoinerId(userId);
        }else{
            setCreatorId(userId);
        }
    }

    // 发送停止倒计时指令
    const closeTime = () => {
        if(urlInfo.isCreator && tmpSocketService){
            tmpSocketService.socketEmitMsg(SocketEvent.Q_CANCEL_OCCUPY_COUNTDOWN_TS, {linkId: linkId});
            setStop('over')
        }
    }

    return (tips?
        (<Dialog content={tips} closeTips={closeTips} />):
        (<div className="main">
            <div className="wrap">
                {
                    urlInfo.isCreator ? "" :
                    <div className="hd">
                        <Header
                            linkId={linkId}
                            toysData={toysData.toys}
                            linkInfo={linkInfo}
                            isEnd={isEnd}
                            setIsEnd={setIsEnd}
                            socketServiceInstanct={tmpSocketService}
                            endSocket={endSocket}/>

                        {
                            (isEnd && isEnd.end) ? '' : (<EvakeRemote qrCode={qrCode} linkId={linkId}></EvakeRemote>)
                        }
                    </div>
                }
                <div className="bd">
                    <ChatList isCreator={urlInfo.isCreator} time={time} stop={stop} reachMax={reachMax} socketServiceInstanct={tmpSocketService} closeTime={closeTime}/>
                </div>
                <div className="ft">
                    <ChatOperate
                        socketServiceInstanct={tmpSocketService}
                        creatorId={creatorId}
                        joinerId={joinerId}
                        isCreator={urlInfo.isCreator}
                        userOnline={userOnline}
                        userOnApp={userOnApp}
                        linkId={linkId}
                        joinerFirstTime={joinerFirstTime}
                        chatInfo={chatInfo}
                        joinerFirstTimeChange={joinerFirstTimeChange}
                        qrCode={qrCode}
                        fromCam={fromCam}
                        isEnd={isEnd}
                        toysData={toysData.toys}
                        joinerToy={joinerToy}
                        creatorToy={creatorToy}
                        toyStatus={toyStatus.tStatus}
                        endEvent={endEvent}
                        updateUserId={updateUserId}
                        setJoinerToy={setJoinerToy}
                        setToysData={setToysData}
                        setToyStatus={setToyStatus}
                    />
                </div>
            </div>

            {/*初始加载页*/}
            {loading && (<InitPage/>)}

            {/*失效页面*/}
            {(isEnd && isEnd.end) && (<EndChat code={urlInfo.code} endType={isEnd.endType} email={email} linkId={linkId} />)}
            {/*异常页面*/}
            {(errPageStatus && <ErrorPage pageStatus={errPageStatus}/> )}
        </div>
        )
    )
}

// export default MobilePage;

export default connect(state => ({
    chatList: state.chatList,
    joinerInfo: state.joinerInfo,
}), {
    updateChatList,
    updateControlState,
    updateMovingBallState,
    updateJoinerInfo,
    updateControlPermission,
    updateLiveControlPermission,
})(MobilePage);