
import React, { useState, useEffect, useMemo } from 'react';
import CountDown from "./count-down";
import OrderLines from "./order-lines";
import SlideBalls from "./slide-balls";
import ControlRequest from "./control-request";
import "./index.scss";
import img_control_request from '@/assets/images/business/control_request.png';
import {connect} from "react-redux";

const ToyControl = (props) => {
  const {
    socketServiceInstanct,
    linkId,
    qrCode,
    fromCam,
    isEnd,
    toysData,
    linkInfo,
    toyStatus,
    endSocket,
    setIsEnd,
    userOnline,
    controlPermission,
  } = props;

  const isShowContent = useMemo(
    () => controlPermission.openControlPermission === false || controlPermission.openControlPermission === true,
    [controlPermission.openControlPermission]
  );
  const [hadAuthority, setHadAuthority] = useState(false); // 请求控制

  useEffect(()=>{
    if(controlPermission.joinerHasLiveControlPermission || controlPermission.openControlPermission === false) {
      setHadAuthority(true)
    }
  }, [controlPermission])

  return (
    <div id="pc-toy-control" className="pc-toy-control">
      {
        isShowContent ? (
          <>
            {
              hadAuthority ? (
                <>
                  <div className="pc-order-line">
                    <OrderLines toyStatus={toyStatus} userOnline={userOnline}></OrderLines>
                  </div>
                  <SlideBalls
                    linkId={linkId}
                    isEnd={isEnd}
                    socketServiceInstanct={socketServiceInstanct}
                    toysData={toysData}>
                  </SlideBalls>
                </>
              ) : (
                <>
                  <div className="pc-order-line-control-request">
                    <img src={img_control_request} alt="" />
                  </div>
                  <ControlRequest
                    linkId={linkId}
                    socketServiceInstanct={socketServiceInstanct}
                    toysData={toysData}
                  />
                </>
              )
            }
            <CountDown
              isEnd={isEnd}
              linkId={linkId}
              toysData={toysData}
              linkInfo={linkInfo}
              qrCode={qrCode}
              fromCam={fromCam}
              socketServiceInstanct={socketServiceInstanct}
              setIsEnd={setIsEnd}
              endSocket={endSocket}
              hadAuthority={hadAuthority}
            />
          </>
        ) : null
      }
    </div>
  )
}

export default connect(state => ({
  controlPermission: state.controlPermission,
}), {
})(ToyControl);
