import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import './index.scss'
import './pc.scss'
import icon_unlink from '@/assets/images/business/time-up/icon_unlink.png'
import icon_timeout from '@/assets/images/business/time-up/icon_timeout.png'
import questionnaire from '@/assets/images/business/light_home_banner_bg.png'
import EvakeRemote from '@/views/mobile/components/evake-remote';
import DownloadRemote from '@/views/pc/components/download-remote';
import $api from '@/serve/api'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid';
import { isPc, getUrlParam, urlToObj } from "@/utils/common.js";
const pc = isPc()
const learnMoreUrlDefault = 'https://lovense.com/bluetooth-wireless-remote-control-sex-toys?_utm_pro=210813654'

const EndChat = (props) => {
    const { t } = useTranslation();
    const { endType, linkId, endchatAd } = props
    const [survey, setSurvey] = useState(null)
    const [endData, setEndData] = useState({})

    // 埋点-结束页曝光
    useEffect(() => {
        console.log(`--endType: `, endType);
        const elementTypes = { // 上报结束页面显示的链接状态
            manual: 1, // 1--表示控制结束（正常控制结束页面）
            auto: 1, // 1--表示控制结束（正常控制结束页面）
            taken: 2, // 2--表示被别人打开
            expired: 3, // 3--表示链接失效
            valid: 4, // 4--表示链接错误或者已删除
        }
        const element_type = endType ? elementTypes[endType] : 1
        const elementContents = { // 正常控制结束时，上报结束原因
            manual: 1, // 手动结束
            auto: 2, // 到点自动结束
            // 3 意外结束
        }
        const element_content = element_type === 1 ? (endType ? elementContents[endType] : 3) : ''
        const params = {
            logNo: 'S0009',
            content: JSON.stringify({
                page_name: 'Control Link Js',//所在页面名称，默认
                event_id: 'control_link_closed_page_exposure',  //事件id，默认
                event_type: 'exposure',  //事件类型，默认
                element_id: linkId ? ("open_" + linkId) : '',  //元素id，xxx为lastActiveSessionId
                element_type, //元素名称，上报结束页面显示的链接状态，1--表示控制结束（正常控制结束页面），2--表示被别人打开，3--表示链接失效，4--表示链接错误或者已删除
                element_content, //元素内容，如果是正常控制结束（element_type=1），上报结束原因，1--表示手动结束，2--表示自动到点结束，3--表示意外结束（如断网之类）;非正常控制结束，传""
            }),
            timeStamp: new Date().getTime()
        }
        $api.logsNewV2(params)
    }, [endType, linkId])

    useEffect(() => {
        $api.getSurvey().then(res => {
            if (res.result) {
                const { openStatus, onlineUrl } = res.data
                const tapSurvey = localStorage.getItem('tapSurvey')
                if(!tapSurvey && openStatus) setSurvey(onlineUrl)
            }
        })
    }, [])

    useEffect(() => {
        // console.log(`--endchatAd: `, endchatAd);
        $api.getAdContent({
            lang: (navigator.language || navigator.browserLanguage).toLowerCase(),
            requestId: endchatAd?.requestId || uuidv4().replaceAll('-', '').toLowerCase(),
            refererChannel: getUrlParam('referer_channel'),
        }).then(res => {
            if (res.result && res.data) {
                if (res.data.item && res.data.item.adList?.length) {
                    let adList = res.data.item.adList.filter(ad => ad.pictureType === (pc ? 'pc' : 'mobile'))
                    adList.forEach((item, index) => {
                        // 解决链接中因包含特殊字符'&' 导致无法上报埋点的问题
                        item.adLinkUrlBi = item.adLinkUrl.includes('&') ? JSON.stringify(urlToObj(item.adLinkUrl)) : item.adLinkUrl
                    })

                    if(adList.length > 4) { // 随机4个广告
                        adList.forEach((item, index) => {
                            item.index = index
                        })
                        const adListRandom = []
                        while (adListRandom.length<4) {
                            const randomIndex = Math.ceil(Math.random() * adList.length) - 1
                            adListRandom.push(adList.splice(randomIndex, 1)[0])
                        }
                        adList = adListRandom.sort((a,b)=> a.index-b.index)
                    }
                    // 这里赋值去掉了一层.item
                    res.data.adList = adList
                    // 埋点-4个广告位广告曝光
                    res.data.adList.forEach(ad => {
                        const params = {
                            logNo: 'M0077',
                            content: JSON.stringify({
                                ad_owner: res.data.adOwner, // 广告主，对应后端接口的adowner
                                ad_id: res.data.adId, // 广告id，对应后端接口的adid
                                ad_name: res.data.adName, // 广告名，对应后端接口的adname
                                ad_picture: ad.adPicture, // 广告设置的图片链接，对应后端接口的adpicture
                                ad_linkurl: ad.adLinkUrlBi, // 广告设置的点击跳转的url，对应后端接口的adlinkurl
                            }),
                            timeStamp: new Date().getTime(),
                        }
                        $api.logsNewV2(params)
                    });
                }
                setEndData(res.data)
                // 埋点-广告曝光
                const params = {
                    logNo: 'M0058',
                    content: JSON.stringify({
                        reason: res.data.adOwner,
                        func_type: 'Controller',
                        content_id: res.data.adId,
                        ad_name: res.data.adName,
                    }),
                    timeStamp: new Date().getTime()
                }
                $api.logsNewV2(params)
            }
        })
    }, [endchatAd])

    const handleClickAd = (e) => {
        // 埋点-广告点击（大图）
        const params = {
            logNo: 'M0059',
            content: JSON.stringify({
                reason: endData.adOwner,
                func_type: 'Controller',
                content_id: endData.adId,
                ad_name: endData.adName,
            }),
            timeStamp: new Date().getTime()
        }
        $api.logsNewV2(params)
    }

    const handleClickAd4 = (ad) => {
        // 埋点-4个广告位广告点击
        const params = {
            logNo: 'M0078',
            content: JSON.stringify({
                ad_owner: endData.adOwner, // 广告主，对应后端接口的adowner
                ad_id: endData.adId , // 广告id，对应后端接口的adid
                ad_name: endData.adName, // 广告名，对应后端接口的adname
                ad_picture: ad.adPicture, // 广告设置的图片链接，对应后端接口的adpicture
                ad_linkurl: ad.adLinkUrlBi, // 广告设置的点击跳转的url，对应后端接口的adlinkurl
            }),
            timeStamp: new Date().getTime()
        }
        $api.logsNewV2(params)
    }

    const handleClickLearnMore = () => {
        // 埋点-底部广告learn more点击
        const params = {
            logNo: 'M0079',
            content: JSON.stringify({
                ad_owner: endData.adOwner, // 广告主，对应后端接口的adowner
                ad_id: endData.adId , // 广告id，对应后端接口的adid
                ad_name: endData.adName, // 广告名，对应后端接口的adname
                // ad_linkurl: endData.item.learnMoreUrl, // 广告设置的点击跳转的url，对应后端接口的adlinkurl
                ad_linkurl: endData.item.learnMoreUrl.includes('&') ? JSON.stringify(urlToObj(endData.item.learnMoreUrl)) : endData.item.learnMoreUrl, // 广告设置的点击跳转的url，对应后端接口的adlinkurl
            }),
            timeStamp: new Date().getTime()
        }
        $api.logsNewV2(params)
    }

    const imgList = {
        default: icon_unlink,
        valid: icon_unlink,
        banned: icon_unlink,
        expired: icon_unlink,
        taken: icon_unlink,
        auto: icon_timeout,
    }

    const tipList = {
        default: t('end_page_timesup'),
        over: t('link_auto_end_title'),
        ban: t('link_auto_end_title'),
        valid: t('fail_to_open_link1'), //
        banned: t('fail_to_open_link1'),
        expired: t('fail_to_open_link3'), //
        taken: t('fail_to_open_link2'), //
        auto: t('end_page_timesup'), // 到时间自动结束
    }

    const desList = {
        default: t('end_page_timesup_des'),
        over: t('link_auto_end_controllee_des'),
        ban: 'You are banned from opening control links for 30 minutes.',
        valid: t('fail_to_open_link_des3'), //
        banned: 'You are banned from opening control links',
        expired: t('fail_to_open_link_des2'), //
        taken: t('fail_to_open_link_des1'), //
        auto: t('end_page_timesup_des'), // 到时间自动结束
    }

    // 秒转换分秒显示
    const format = (s) => {
        let h = Math.floor(s / 60)
        s = s % 60
        h += ''
        s += ''
        if(h && s!=='0') h = `${h}:${s}`
        return `${h}`
    }

    // 前往问卷
    const toSurvey = (e) => {
        localStorage.setItem('tapSurvey', true)
        if(e) window.location.href = e
        setSurvey(null)
    }

    const getText = (e) => {
        let key = ''
        console.log(`--endType: `, endType);
        if(endType){
            key = endType
            if(endType.includes('ban-')){
                const arr = endType.split('-')
                key = arr[0]
                let str = `You are banned from opening control links for ${format(arr[2])} minutes.`
                if(t('link_auto_end_controller_des')) str = t('link_auto_end_controller_des').replace('<#1#>', `${arr[2]} ${t(arr[1])}`)
                desList.ban = str
            }
        }
        return e[key || 'default']
    }

    return (
        <div className={pc?"pc-end-chat" : "end-chat"}>

            {pc ? (
                <DownloadRemote ></DownloadRemote>
            ) : (
                <EvakeRemote ></EvakeRemote>
            )}

            <div className="container">
                <div className="time-up">
                    <img src={imgList[endType  || 'default'] || imgList['default']} alt=""/>
                    <h3>{getText(tipList) || tipList['default']}</h3>
                    <p>{getText(desList) || desList['default']}</p>
                </div>

                {survey && <div className="questionnaire">
                    <img className="question-banner" src={questionnaire} alt=""/>
                    <div className="question-text">
                        {t('control_link_survey_title')}
                    </div>
                    <div className="question-tips">
                        {t('control_link_survey_des')}
                    </div>
                    <div className="question-check" onClick={() => { toSurvey(survey) }}>{t('buttonn_survey')}</div>
                </div>}

                {endData.adPicture && (
                    <a className="event-img" href={ endData.adLinkUrl } target="_blank" rel="noreferrer" onClick={ handleClickAd }>
                        <img src={ endData.adPicture } alt="" />
                    </a>
                )}

                {endData.item && (
                    <>
                        <div className="interactive">
                            { endData.item.adText || t('default_text_control_link_h5') }
                        </div>

                        {endData.adList?.length ? (
                            <div className="product-list">
                                {endData.adList.map((ad) => (
                                    <a href={ ad.adLinkUrl } target="_blank" rel="noreferrer" onClick={ ()=>handleClickAd4(ad) }>
                                        <img className="img" src={ad.adPicture} alt=""/>
                                    </a>
                                ))}
                            </div>
                        ) : null}

                        <a className="learn-more" href={ endData.item.learnMoreUrl || learnMoreUrlDefault } target="_blank" rel="noreferrer" onClick={ handleClickLearnMore }>
                            { t('learn_more') }
                        </a>
                    </>
                )}

                {/* {pc &&  <div className="adv-item">{t('download_remote_des')}</div>} */}

            </div>
        </div>
    )
}


export default connect(state => ({
    endchatAd: state.endchatAd,
}), {
})(EndChat);
