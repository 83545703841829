import React from 'react';
import './index.scss'
import i18next from "@/utils/i18n.js"
import Loading from '@/components/loading'
import { isPc } from "@/utils/common.js";
const pc = isPc()

const Header = (props) => {
    const {requestText} = props;
    // 是否显示结束弹框
    // const [isShow, setIsShow] = React.useState(false)

    return (
        <div className={pc?"pc-init-page" : "init-page"}>
            <div className="container">
                <div className="content">
                    <Loading/>
                    <div className="text">
                        <p>{requestText || i18next.t('common_loading')}</p>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Header;
