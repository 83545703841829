
import { useEffect, useRef } from "react"
import "./index.scss"

//高阶组件
const LayerMask = ({ ContentComponent, cancelEvent, confirmEvent, data }) => {
  const mouseWheel = (e) => {
    e.stopPropagation()
  }
  const pc_layer = useRef()
  useEffect(() => {
    const targetEl = pc_layer.current
    targetEl.addEventListener('mousewheel', mouseWheel, false)
    targetEl.addEventListener('DOMMouseScroll', mouseWheel, false)
    return function () {
      if (targetEl) {
        targetEl.removeEventListener('mousewheel', mouseWheel, false)
        targetEl.removeEventListener('DOMMouseScroll', mouseWheel, false)
      }
    }
  }, [])

  return (
    <>
      <div ref={pc_layer} className="pc-layer">
        <div className="layer-mask"></div>
        <div className="layer-content">
          <ContentComponent
            cancelEvent={cancelEvent}
            confirmEvent={confirmEvent}
            data={data}
          />
        </div>
      </div>
    </>
  )
}

export default LayerMask