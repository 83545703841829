import $api from '@/serve/api'
import { v4 as uuidv4 } from 'uuid';
import { isPc, getUrlParam } from "@/utils/common.js";
import store from "@/redux/store";
import { updateEndchatAdRequestId } from "@/redux/action/endchat-ad.js";
const pc = isPc()

export const preloadAd = (linkId)=>{
  setTimeout(() => {
    const requestId = uuidv4().replaceAll('-', '').toLowerCase()
    // console.log(`--requestId: `, requestId);
    store.dispatch(updateEndchatAdRequestId(requestId))
    $api.getAdContent({
      lang: (navigator.language || navigator.browserLanguage).toLowerCase(),
      requestId,
      linkId,
      refererChannel: getUrlParam('referer_channel'),
    }).then(res => {
      if (res.result && res.data) {
        if (res.data.adPicture) {
          new Image().src = res.data.adPicture
        }
        if (res.data.item && res.data.item.adList?.length) {
          const adList = res.data.item.adList.filter(ad => ad.pictureType === (pc ? 'pc' : 'mobile'))

          adList.forEach(item => {
            new Image().src = item.adPicture
          });
        }
      }
    })
  }, 5000);
}
