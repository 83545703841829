/*
    该文件用于汇总所有的reducer为一个总的reducer
*/
//引入combineReducers，用于汇总多个reducer
import {combineReducers} from 'redux';
//引入为Count组件服务的reducer
import token from './token';
import orderLine from './order-line.js';
import chatList from './chat-list';
import toysData from './toys.js';
import controlState from './control-state.js';
import movingBall from './moving-ball.js';
import closeOperateStatus from "./close-operate.js";
import currentBallInfo from "./current-ball-info.js";
import fromAppToyCommand from "./from-app-toy-command.js";
import joinerInfo from "./joiner-info.js";
import controlPermission from "./control-permission.js";
import endchatAd from "./endchat-ad.js";

//汇总所有的reducer变为一个总的reducer
export default combineReducers({
    token, orderLine, chatList, toysData, controlState, movingBall, closeOperateStatus,
    currentBallInfo, fromAppToyCommand, joinerInfo, controlPermission, endchatAd
})
