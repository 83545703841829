import React, { useRef, useState, useEffect, useCallback } from 'react';
import keyboard from '@/assets/images/business/keyboard.png';
import emojis from '@/assets/images/business/emojis.png';
import control from '@/assets/images/business/chat_function_live.png';
import waringIcon from '@/assets/images/business/voice_record_waring.png';
import send from '@/assets/images/business/chat_function_send.png';
import microphone from '@/assets/images/business/chat_function_microphone.png';
import img_control_request from '@/assets/images/business/control_request_icon.svg';
import RecordIcon from '@/components/record-icon';
import $api from '@/serve/api';
import expressionList from '@/utils/expressin';
import ToyControl from "./toy-control";
import SocketEvent from '@/serve/socket-event';
import aesUtils from '@/utils/aes/aes-utils';
import {connect} from "react-redux";
import {updateChatList} from "@/redux/action/chat-list";
import $validate from '@/utils/validate';
import QRCode from "qrcode.react";
import { updateCloseOperate } from "@/redux/action/close-operate";
import { getMsgBody, generateMsgId, supportLdsToy } from "@/utils/common.js";
import postMessageToApp from "../../connectApp/postMessageToApp.js";
import config from "@/config/index"
import './index.scss';
import BenzAMRRecorder from 'benz-amr-recorder'

const ChatOperate = (props) => {
    const {
        socketServiceInstanct,creatorId,joinerId,isCreator,linkId,joinerFirstTime,chatInfo,
        qrCode,fromCam,chatList,toysData,joinerToy,creatorToy,isEnd,toyStatus,endEvent,userOnline,userOnApp,
        updateUserId,updateChatList,joinerFirstTimeChange,closeOperateStatus,updateCloseOperate,
        setJoinerToy,setToysData,setToyStatus,
        controlPermission,
    } = props;

    //加入者控制类型：liveControl 或 syncControl
    const [liveOrSync, setLiveOrSync] = useState("live");
    const [ldsOrLdr, setLdsOrLdr] = useState("ldr");
    //存储发送信息
    const [storeSendInfo, setStoreSendInfo] = useState("");
    //第一次进入页面初始数据需要做socket发送成功，等待服务器提示成功后，将该数据存储进聊天列表
    const [chatInfoG, setChatInfoG] = useState("");
    const [chatListData, setChatListData] = useState([]);
    const textareaValueRef = useRef(null);
    const errorListTime = useRef({});
    const [textareaValue, setTextareaValue] = useState(null); //输入框
    const [textareaOnFocus, setTextareaOnFocus] = useState(false);
    const [isExpression, setIsExpression] = useState(false);  //表情面板
    const [isMic, setIsMic] = useState(false);  // 语音模式
    const [isToyControl, setIsToyControl] = useState(true);   //玩具控制面板
    const [caretPos, setCaretPos] = useState({start: 0, end: 0});  //存储光标位置
    const [isPositionState, setIsPositionState] = useState(false); //是否获取光标状态（true/false）
    const [msgId, setMsgId] = useState(false);                //聊天消息对应id，拉去数据处理
    const [isShowQrcode, setIsShowQrcode] = useState(false);
    const [ldrControlType, setLdrControlType] = useState(2);  //ldr控制状态：1主控 2被控
    const [ldsControlType, setLdsControlType] = useState(0);  //lds控制状态：0互控 1主控  2被控
    const [isSupportTouchPanel, setIsSupportTouchPanel] = useState(false)  // 感应模式时是否支持触控面板（remote APP新版时为true）
    //禁用状态
    const [isDisable, setIsDisable] = useState(false);
    //是否是第一次进入
    const [isFirst, setIsFirst] = useState(false);
    //创建者是否首次进入controlLink页面
    const [creatorFirstCome, setCreatorFirstCome] = useState(true);
    // 记录按下录音的时候Y轴的位置
    const [touchData, setTouchData] = useState(0);
    // 记录按下录音的时候时间
    const [touchTime, setTouchTime] = useState(0);
    // 是否取消发送
    const [cancelTime, setCancelTime] = useState(false);
    // 显示文案
    const [tooShort, setTooShort] = useState(false);
    // 显示弹窗
    const [micToast, setMicToast] = useState("");
    // 预先设置一个变量来存MediaRecorder实例
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const timeOut = useRef(null);
    // 录音上传中
    const [audioUploading, setAudioUploading] = useState(false);
    // 是否录音中
    const [micStart, setMicStart] = useState(false);
    // 请求控制中
    const [isRequestingControl, setIsRequestingControl] = useState(false);
    const [hadAuthority, setHadAuthority] = useState(false);
    const [firstOpenControl, setFirstOpenControl] = useState(false);

    useEffect(()=>{
        if(userOnApp === "offline"){
            setLiveOrSync("live");
            setJoinerToy([]);
            setToysData({toys:creatorToy});
            setToyStatus({tStatus: creatorToy});
        }
    },[userOnApp,creatorToy,setToysData,setJoinerToy,setToyStatus]);

    useEffect(() => {
        let str = joinerFirstTime + "";
        if (str === "false" || str === "true") {
            setIsFirst(joinerFirstTime);
        }
    }, [joinerFirstTime]);
    useEffect(() => {
        if (chatInfo) {
            setChatInfoG(chatInfo);
        }
    }, [chatInfo]);

    const [tipsOnline, setTipsOnline] = useState(-1); // 提示在线/不在线 -1默认 0不在线 1在线
    const [disconnectedTimes, setDisconnectedTimes] = useState(0); // 断连次数
    const userOnlineChangeCallback = useCallback((userOnline)=>{
        console.log(`--userOnline: `, userOnline);
        let onlineText = userOnline ? 'reconnected' : 'disconnected'
        const tipsStr = `The other user is ${onlineText}.`
        // console.log(`userOnline tipsStr: `, tipsStr);
        const msgBody = [{...getMsgBody(linkId, { tips: tipsStr }, 'tips', `${onlineText}-${creatorId}`), createTime: Date.now(), msgId: generateMsgId()}]
        console.log(`--userOnline disconnectedTimes: `, disconnectedTimes);
        if (userOnline) { // 在线
            if(tipsOnline === 0) { // 上次提示了断线
                setTipsOnline(1)
                updateChatList(msgBody)
            }
            if(disconnectedTimes > 0) setDisconnectedTimes(0)
        } else { // 断线
            if(tipsOnline) { // 上次提示了在线
                if(disconnectedTimes >= 1){ // 推送了两次断连了
                    setTipsOnline(0)
                    updateChatList(msgBody)
                }
            }
            if(disconnectedTimes < 1) setDisconnectedTimes((pre) => pre+1)
        }
    },[creatorId, linkId, updateChatList, disconnectedTimes, tipsOnline])

    useEffect(() => {
        //返回对方用户是否在线的查询结果
        const ackQueryUserOnlineInfoTcEvent = (res) => {
            console.log(`--userOnline watch: `, res)
            if(!isCreator) userOnlineChangeCallback(res.onLine)
        }
        socketServiceInstanct.on(SocketEvent.ACK_QUERY_USER_ON_LINE_INFO_TC, ackQueryUserOnlineInfoTcEvent);
        return function () {
            socketServiceInstanct.off(SocketEvent.ACK_QUERY_USER_ON_LINE_INFO_TC, ackQueryUserOnlineInfoTcEvent);
        }
    }, [socketServiceInstanct, isCreator, userOnlineChangeCallback]);

    useEffect(() => {
        if (!$validate.isNull(chatList)) {
            setChatListData(chatList);
            localStorage.setItem('chatList', JSON.stringify(deWeight(chatListData)));
        }
    }, [chatList, chatListData]);

    //Lds页面显示的玩具
    //需要控制或被控的玩具，加入者和创建者均默认选中第一个支持LDS的玩具
    const [ldsJoinerToyList, setLdsJoinerToyList] = useState([]);
    const [ldsCreatorToyList, setLdsCreatorToyList] = useState([]);
    //加入者在Lds控制面板手动选择了的玩具
    const [joinerSelectedLdsToy, setJoinerSelectedLdsToy] = useState("");
    //创建者在Lds控制面板默认选择了的玩具
    const [creatorSelectedLdsToy, setCreatorSelectedLdsToy] = useState("");

    //根据加入者和创建者连接的玩具是否支持LDS，决定控制方向
    useEffect(()=>{
        //切换控制的时候优先使用加入者手动选择的玩具
        const selectedToyByToyId = (toyId, paramArr) => {
            let tArr = [];
            for(let i=0; i<paramArr.length; i++){
                let cObj = paramArr[i];
                cObj.selected = false;
                if(cObj.toyId === toyId){
                    cObj.selected = true;
                }
                tArr.push(cObj);
            }
            return tArr;
        }
        if(ldsOrLdr === "lds"){
            let joinerList = [];
            let creatorList = [];
            if(joinerToy && joinerToy.length > 0){
                let num = 0;
                for(let i=0; i<joinerToy.length; i++){
                    let item = joinerToy[i];
                    let type = (item.type + "").toLowerCase();
                    let version = item.version ? item.version : "";
                    let toyName = type;
                    if(version){
                        toyName = toyName + "_" + version;
                    }
                    let selected = false;
                    if(supportLdsToy(type) && num === 0){
                        selected = true;
                        num++;
                    }
                    //构造新数据结构
                    let toyObj = {};
                    toyObj.toyId = (item.id + "").toLowerCase();
                    toyObj.type = type;
                    toyObj.toyName = (toyName + "").toLowerCase();
                    toyObj.selected = selected;
                    joinerList.push(toyObj);
                }
            }
            if(creatorToy && creatorToy.length > 0){
                let num = 0;
                for(let i=0; i<creatorToy.length; i++){
                    let item = creatorToy[i];
                    let type = (item.type + "").toLowerCase();
                    let version = item.version ? item.version : "";
                    let toyName = type;
                    if(version){
                        toyName = toyName + "_" + version;
                    }
                    let selected = false;
                    if(supportLdsToy(type) && num === 0){
                        selected = true;
                        num++;
                    }
                    //构造新数据结构
                    let toyObj = {};
                    toyObj.toyId = (item.id + "").toLowerCase();
                    toyObj.type = type;
                    toyObj.toyName = (toyName + "").toLowerCase();
                    toyObj.selected = selected;
                    creatorList.push(toyObj);
                }
            }
            if(ldsControlType === 0){ //互控
                if(joinerSelectedLdsToy){
                    let tArr = selectedToyByToyId(joinerSelectedLdsToy, joinerList);
                    joinerList = tArr;
                }
                setLdsJoinerToyList(joinerList);
                setLdsCreatorToyList(creatorList);
                //如果某一方连接中的玩具均支持LDS功能，则默认为主控或被控
                let joinerSupportLds = joinerList.find(v=>v.selected === true) ? true : false;
                let creatorSupportLds = creatorList.find(v=>v.selected === true) ? true : false;
                if(joinerList.length > 0 && creatorList.length > 0){
                    if (!isSupportTouchPanel) { // 旧版才需要做下面的判断处理，新版进来后一定是互控模式
                        if(creatorSupportLds && !joinerSupportLds){
                            setLdsControlType(1);
                        }else if(!creatorSupportLds && joinerSupportLds){
                            setLdsControlType(2);
                        }
                    }
                }
            }else if(ldsControlType === 1){ //主控
                let jArr = [...joinerList];
                jArr.forEach(obj => obj.selected = false);
                setLdsJoinerToyList(jArr);
                setLdsCreatorToyList(creatorList);
            }else if(ldsControlType === 2){ //被控
                let cArr = [...creatorList];
                cArr.forEach(obj => obj.selected = false);
                setLdsCreatorToyList(cArr);
                if(joinerSelectedLdsToy){
                    let tArr = selectedToyByToyId(joinerSelectedLdsToy,joinerList);
                    joinerList = tArr;
                }
                setLdsJoinerToyList(joinerList);
            }
        }
    },[ldsOrLdr, ldsControlType, joinerSelectedLdsToy, joinerToy, creatorToy, isSupportTouchPanel]);

    useEffect(()=>{
        let tmpId = "";
        for(let i=0; i<ldsCreatorToyList.length; i++){
            let item = ldsCreatorToyList[i];
            let type = (item.type + "").toLowerCase();
            if(supportLdsToy(type) && tmpId === ""){
                tmpId = item.toyId;
            }
        }
        setCreatorSelectedLdsToy(tmpId);
    },[ldsCreatorToyList]);

    //标记进入LDS面板则调用一次changeControl方法
    const [firstComeLds, setFirstComeLds] = useState(true);
    useEffect(()=>{
        let tm = null;
        if(creatorSelectedLdsToy && firstComeLds){
            tm = setTimeout(()=>{
                let res = ldsOrLdr === "lds" ? true : false;
                let msgJson = { type:1,startLdr:res,toyId:creatorSelectedLdsToy,direction:ldsControlType };
                let body = {
                    "version": 1,
                    "linkId": linkId,
                    "event": "changeControl",
                    "message": JSON.stringify(msgJson)
                };
                postMessageToApp.post(JSON.stringify(body));
                setFirstComeLds(false);
            },1000);
        }
        return function(){
            clearTimeout(tm);
        }
    }, [linkId,ldsOrLdr,ldsControlType,creatorSelectedLdsToy,firstComeLds]);

    //打开手机键盘
    const openKeyboard = () => {
        setIsPositionState(true);       //是否获取光标状态（true/false）
        setIsShowQrcode(!isShowQrcode); //二维码
        updateCloseOperate(true);       //关闭操作面板
        textareaValueRef.current.focus();
        if(isExpression){
            setIsExpression(false);
        }
        getPositionForTextArea();
        setCursorAtLast();
        setIsMic(false)
    }
    //进入语音模式
    const openMic = async () => {
        setIsShowQrcode(!isShowQrcode);
        setIsToyControl(false);
        updateCloseOperate(true);
        if(isExpression){
            setIsExpression(false);
        }
        setIsMic(true)
        if(!mediaRecorder){
            try {
                let recorder = new BenzAMRRecorder();
                await recorder.initWithRecord()
                recorder.cancelRecord()
            } catch (error) {
                console.log(`--error: `, error);
                showToast("The browser you are using doesn't support recordIng audio. Open the control link with a different browser to use this feature next time. ", 5000)
                setIsMic(false)
            }
        }
    }
    // 显示toast
    const showToast = (e, time = 800) => {
        if(!e) return
        setTooShort(true)
        setMicToast(e)
        setTimeout(()=>{
            setMicToast('')
            if(mediaRecorder && mediaRecorder.isRecording()) mediaRecorder.finishWAVRecord()
        }, time)
    }
    //语言触摸开始
    const micTouchstart = (e) => {
        if(micStart) return
        setMicStart(true)
        const data = e.changedTouches[0]
        setTouchData(data.pageY)
        setTouchTime(Date.now())
        setCancelTime(false)
        setTooShort(false)
        setMicToast('Slide up to cancel.')
        // 首先打开麦克风
        let mediaRecorder = new BenzAMRRecorder();
        mediaRecorder.initWithRecord().then(() => {
            mediaRecorder.startRecord();
            setMicStart(false)
        }).catch((e) => {
            showToast('Permission to use your microphone is required. Please enable it in your browser.', 5000)
            setIsMic(false)
        })
        setMediaRecorder(mediaRecorder)
        clearTimeout(timeOut.current)
        timeOut.current = setTimeout(()=>{
            micTouchend('skip')
        }, 60000);
        // todo 发送失败的时候显示在页面上
    }
    //语言触摸移动
    const micTouchmove = (e) => {
        const data = e.changedTouches[0]
        if(touchData - data.pageY > 100) {
            if(cancelTime) return
            setCancelTime(true)
            return
        }
        if(!cancelTime) return
        setCancelTime(false)
    }
    //语言触摸结束
    const micTouchend = (e) => {
        if(e && e !== 'skip') e.preventDefault()
        clearTimeout(timeOut.current)
        if(e !== 'skip'){
            if(Date.now() - touchTime < 1000){
                setTouchTime(0)
                setMicToast('')
                if(mediaRecorder && mediaRecorder.isRecording()) mediaRecorder.finishWAVRecord()
                return
            }
            if(cancelTime){
                showToast('Cancel the recording')
                return
            }
            if(Date.now() - touchTime < 3000){
                setTouchTime(0)
                showToast('Message is too short')
                return
            }
            if(touchTime === 0) return
        }
        if(audioUploading) return
        setAudioUploading(true)
        setMicToast('')
        setMediaRecorder(mediaRecorder => {
            if(mediaRecorder){
                if(!sessionStorage.getItem('joiner_cancel_occupy_countdown_'+linkId)) { // 加入者取消占用倒计时
                    sessionStorage.setItem('joiner_cancel_occupy_countdown_'+linkId, 1)
                    socketServiceInstanct.socketEmitMsg(SocketEvent.Q_JOINER_CANCEL_OCCUPY_COUNTDOWN_TS, {linkId: linkId});
                }
                mediaRecorder.finishWAVRecord().then(async () => {
                    // 获取到录音的blob file
                    let blob = mediaRecorder.getWAVBlob()
                    let file = new window.File([blob],"record.am")
                    // 将blob转换为地址，一般用于页面上面的回显，这个url可以直接被 audio 标签使用
                    let time = Math.round((Date.now() - touchTime)/1000)
                    if(time > 60) time = 60
                    let fileData = new FormData()
                    fileData.append('file', file)
                    // 上传聊天音频文件
                    let fileUrl = ''
                    let upLoad = false
                    try {
                        const { data } = await $api.uploadAudio(fileData)
                        fileUrl = data
                        upLoad = true
                    } catch (error) {
                        const url = (window.URL).createObjectURL(blob)
                        fileUrl = url
                    }
                    const msgData = {
                        time, url: fileUrl, msgId: generateMsgId(),
                    }
                    const toId = isCreator ? joinerId : creatorId
                    const params = getMsgBody(linkId, msgData, 'audio', toId)
                    let pObj = {...params}
                    pObj.fix = 'right'
                    pObj.msgDataText = `<span>${time}"<span/>`
                    pObj.msgDataInfo = msgData
                    pObj.msgId = msgData.msgId
                    pObj.url = fileUrl
                    pObj.audioUrl = config.audioFileUrl + fileUrl
                    pObj.time = time
                    pObj.audioTime = time
                    if(!upLoad) {
                        pObj.blob = blob
                        pObj.audioUrl = fileUrl
                    } else {
                        setTimeout(()=>{
                            socketServiceInstanct.socketEmitMsg(SocketEvent.Q_SEND_IM_MSG_TS, params)
                        }, 50)
                    }
                    setStoreSendInfo(pObj)
                    pObj.msgWaitState = 'loading'
                    if(!upLoad) {
                        pObj.msgWaitState = 'error'
                    }else {
                        errorListTime.current[pObj.ackId] = setTimeout(()=>{
                            if(pObj.msgWaitState === 'loading') {
                                let arr = [...chatList]
                                pObj.msgWaitState = 'error'
                                updateChatList([...arr, { ...pObj }])
                            }
                        }, 60000)
                    }
                    let createTime = Date.now()
                    updateChatList([{...pObj, createTime}])
                    setAudioUploading(false)
                }).catch(() => {
                    showToast('The recording failure')
                    setIsMic(false)
                    setAudioUploading(false)
                })
            }
            return mediaRecorder
        })
        setTouchTime(0)
    }

    // 去重
    const deWeight = (list = []) => {
        let arr = JSON.parse(JSON.stringify(list))
        if (arr.length === 0) return arr
        for (let i = 0; i < arr.length - 1; i++) {
            for (let j = i + 1; j < arr.length; j++) {
                if (arr[i].msgId === arr[j].msgId) {
                    if(arr[i].msgWaitState === '' || arr[j].msgWaitState === ''){
                        arr[i].msgWaitState = ''
                    } else {
                        if(arr[i].msgWaitState === 'reload' || arr[j].msgWaitState === 'reload'){
                            arr[i].msgWaitState = 'reload'
                        } else if(arr[i].msgWaitState === 'error' || arr[j].msgWaitState === 'error'){
                            arr[i].msgWaitState = 'error'
                        }
                    }
                    arr.splice(j, 1);
                    //因为数组长度减小1，所以直接 j++ 会漏掉一个元素，所以要 j--
                    j--;
                }
            }
        }
        return arr;
    }
    //获取输入框焦点
    const handleValueFocus = (e) => {
        setTextareaOnFocus(true);
    }
    const handleValueBlur = (e) => {
        setTextareaOnFocus(false);
    }
    //输入框获得焦点后滚动到可视区
    useEffect(()=>{
        if(textareaOnFocus){
            setTimeout(()=>{
                let htmlEl = document.getElementsByTagName('html')[0];
                htmlEl.scrollTop = document.body.scrollHeight;
                let chatListEl = document.getElementById("lvs-chat-list");
                chatListEl.scrollTop = chatListEl.scrollHeight;
            },300); //预留0.3秒让输入框重新聚集及弹出键盘
        }
    },[textareaOnFocus]);

    //设置光标位置位于输入框的文本末尾处
    const setCursorAtLast = () => {
        let obj = document.getElementById("editable-el-id");
        if (window.getSelection) { //ie11 10 9 ff safari
            obj.focus(); //解决ff不获取焦点无法定位问题
            let range2 = window.getSelection(); //创建range
            range2.selectAllChildren(obj); //range 选择obj下所有子内容
            range2.collapseToEnd(); //光标移至最后
        } else if (document.selection) { //ie10 9 8 7 6 5
            let range2 = document.selection.createRange(); //创建选择对象
            //let range2 = document.body.createTextRange();
            range2.moveToElementText(obj); //range定位到obj
            range2.collapse(false); //光标移至最后
            range2.select();
        }
    }
    //获取光标位置
    const getPositionForTextArea = (e) => {
        let CaretPos = { start:0,end:0 };
        try{
            const getSelect = window.getSelection();
            const ctrl = getSelect.getRangeAt(0);
            if (ctrl.startOffset || ctrl.endOffset) {
                if (ctrl.startOffset) {  //Firefox support
                    CaretPos.start = ctrl.startOffset;
                }
                if (ctrl.endOffset) {
                    CaretPos.end = ctrl.endOffset;
                }
            }
            if(textareaValue && e) {
                const eq = [].indexOf.call(e.target.childNodes, getSelect.anchorNode)
                let arr1 = textareaValue.split('[').filter(e => e!=='').map(e => `[${e}`)
                let arr = arr1.map(row => row.split(']').filter(e => e!=='').map((e) => {
                return e.includes('[')?`${e}]`:e
                }))
                let newArr = []
                arr.forEach(row => newArr.push(...row))
                newArr = newArr.slice(0, eq);
                const num = newArr.join().replace(/,/g, '').length;
                if (ctrl.startOffset) {  //Firefox support
                CaretPos.start = ctrl.startOffset + num;
                }
                if (ctrl.endOffset) {
                CaretPos.end = ctrl.endOffset + num;
                if(ctrl.commonAncestorContainer.nodeValue){
                    const trimStr = ctrl.commonAncestorContainer.nodeValue.substring(0, ctrl.endOffset);
                    let a = trimStr.split('').map(row => row.trim()? row : '123456');
                    const trimNum = a.join().replace(/,/g, '').length;
                    if(trimNum) CaretPos.end = trimNum + num;
                }
                }
            }
            return (CaretPos);
        }catch(e){
           console.error("290 error--->", e.message);
           return (CaretPos);
        }
    };
    //打开表情面板
    const chooseExpressin = () => {
        setIsPositionState(false);
        setIsExpression(!isExpression);
        setIsToyControl(false);
        updateCloseOperate(true);
        setIsMic(false)
    }
    //获取输入框处理
    const handleValueChange = (e) => {
        setIsPositionState(true);
        setTextareaValue(imgToEmoji(e.target.innerHTML));
        let position = getPositionForTextArea(e); // 光标的位置
        setCaretPos(position);
        updateCloseOperate(true);
        textareaValueRef.current.onselectstart = function () {
            return false;
        }
    };
    // 把输入框的表情包文本换成图片显示
    const innerTextareaHtml = (str) => {
      const reg = /\[(.+?)\]/g;
      let list = [];
      let result = null;
      let innerHTML = str;
      do {
          result = reg.exec(str);
          result && list.push(result[1]);
      } while (result);
      list.forEach(row=>{
        try {
            const data = expressionList[expressionList.map(item => item.key).indexOf(row)];
            const emoji = `<img src=${require('@/assets/images/business/emoji/' + data.emoji).default} alt=${data.key} />`;
            innerHTML = innerHTML.replace(`[${row}]`, emoji);
        } catch (error) {
            console.log(error)
        }
      });
      textareaValueRef.current.innerHTML = innerHTML;
    };
    // 把输入框的表情包图片换成文本保存
    const imgToEmoji = (str) => {
      const reg = /alt="(.+?)"/g;
      let list = [];
      let result = null;
      let innerHTML = str;
      do {
          result = reg.exec(str);
          result && list.push(result[1]);
      } while (result);
      list.forEach(row=>{
        const emoji = new RegExp("\\<img(.+?)"+ row +"(.+?)>")
        innerHTML = innerHTML.replace(emoji, `[${row}]`);
      });
      return innerHTML
    }
    //添加表情
    const expressionHandler = (data) => {
        setIsPositionState(false);
        const keyText = `[${data.key}]`;
        if (textareaValue) {
            // const str = `${textareaValue.slice(0, caretPos.end)}${keyText}${textareaValue.slice(caretPos.end)}`;
            const text = textareaValue.replace(/ /g, '&nbsp;')
            let leftTxt = text.slice(0, caretPos.end)
            let rightTxt = text.slice(caretPos.end)
            if(leftTxt.includes('[')){
                let arr = leftTxt.split('[')
                let str = arr.pop()
                if(!str.includes(']')){
                    let right = rightTxt.split(']')
                    leftTxt = leftTxt + right[0]+']'
                    rightTxt = rightTxt.substring(right[0].length + 1, rightTxt.length)
                }
            }
            const str = `${leftTxt}${keyText}${rightTxt}`;
            innerTextareaHtml(str);
            setTextareaValue(str);
        } else {
            innerTextareaHtml(keyText)
            setTextareaValue(keyText);
        }
        let caretPosObj = caretPos;
        caretPosObj.start = caretPosObj.start + keyText.length;
        caretPosObj.end = caretPosObj.end + keyText.length;
        //原来光标位置加上表情长度，重新更新存储光标位置
        setCaretPos(caretPosObj);
        updateCloseOperate(true);
    }
    //删除聊天
    const delChatText = () => {
        if (!textareaValue) {
            return;
        }
        let str = textareaValue.trim().slice(0, textareaValue.length - 1);
        innerTextareaHtml(str);
        setTextareaValue(str);
    }
    //格式化输入信息
    const formatString = (str) => {
        if (!str) return ''
        let val = str;
        val = val
            .replace(/<div>/g, '')
            .replace(/&nbsp;/g, ' ')
            .replace(/<\/div>/g, '\n')
            .replace(/<br>/g, '\n')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&amp;/g, '&')
            .replace(/<\/?.+?>/g, "")
            .replace(/ /g, " ");
        return val;
    }
    //提交聊天信息
    const submitChatInfo = () => {
        if (isDisable) {
            return;
        }
        setIsDisable(true);
        setTimeout(()=>{
            setIsDisable(false);
        },1000);
        if(!sessionStorage.getItem('joiner_cancel_occupy_countdown_'+linkId)) { // 加入者取消占用倒计时
            sessionStorage.setItem('joiner_cancel_occupy_countdown_'+linkId, 1)
            socketServiceInstanct.socketEmitMsg(SocketEvent.Q_JOINER_CANCEL_OCCUPY_COUNTDOWN_TS, {linkId: linkId});
        }
        const msgData = {
            msgId: generateMsgId(),
            text: formatString(textareaValue)
        }
        const toId = isCreator ? joinerId : creatorId;
        const params = getMsgBody(linkId, msgData, 'chat', toId);
        let pObj = {...params};
        pObj.fix = 'right';
        pObj.msgDataText = textareaValue;
        pObj.msgDataInfo = msgData;
        pObj.msgId = msgData.msgId;
        setStoreSendInfo(pObj);
        updateCloseOperate(true);
        setTimeout(()=>{
            setTextareaValue('');
            socketServiceInstanct.socketEmitMsg(SocketEvent.Q_SEND_IM_MSG_TS, params);
        },50);
        //光标重新聚集输入框
        if(textareaValueRef && textareaValueRef.current && !isExpression){
            setTimeout(()=>{
                textareaValueRef.current.focus();
            },200); //须延迟focus，否则无法生效
        }
        let createTime = Date.now()
        pObj.msgWaitState = 'loading'
        updateChatList([{...pObj, createTime}]);
        setTextareaValue(null);
        innerTextareaHtml('');
        errorListTime.current[pObj.ackId] = setTimeout(()=>{
            if(pObj.msgWaitState === 'loading') {
                pObj.msgWaitState = 'error'
                updateChatList([{ ...pObj }]);
            }
        }, 60000);
        if(textareaValueRef && textareaValueRef.current){
            textareaValueRef.current.blur();
        }
        if(!isExpression) setIsToyControl(!isToyControl)
    }

    const toyControlHandler = useCallback(() => {
        console.log(`--controlPermission: `, controlPermission);
        if(controlPermission.openControlPermission && !controlPermission.joinerHasLiveControlPermission){ // 控制请求
            if(!sessionStorage.getItem('joiner_cancel_occupy_countdown_'+linkId)) { // 加入者取消占用倒计时
                sessionStorage.setItem('joiner_cancel_occupy_countdown_'+linkId, 1)
                socketServiceInstanct.socketEmitMsg(SocketEvent.Q_JOINER_CANCEL_OCCUPY_COUNTDOWN_TS, {linkId: linkId});
            }
            if(!isRequestingControl){
                setIsRequestingControl(true)
                socketServiceInstanct.socketEmitMsg(SocketEvent.CL_CONTROL_PERMISSION_REQUEST_TS, {
                    linkId,
                    linkPermissionType: 'live_control',
                    operationType: 'request',
                });
                // 埋点
                $api.logsNewV2({
                    "logNo": "S0009",
                    "content": JSON.stringify({
                        "page_name": "Control Link Open",//所在页面名称，默认
                        "event_id": "controllinkjs_permission_popup_exposure",  //事件id，默认
                        "event_type": "exposure",  //事件类型，默认
                        "element_id": "open_" + linkId,  //元素id，xxx为lastActiveSessionId
                        "element_content": "1", //元素内容，1--表示等待live control授权
                    }),
                    "timeStamp":new Date().getTime()
                })
            }
        }else{
            setIsPositionState(false)
            setIsExpression(false)
            setIsToyControl(!isToyControl)
            updateCloseOperate(true)
            setIsMic(false)
        }
    },[controlPermission, socketServiceInstanct, isRequestingControl, linkId, setIsPositionState, setIsExpression, setIsToyControl, isToyControl, updateCloseOperate, setIsMic, setIsRequestingControl])

    useEffect(()=>{
        console.log(`--firstOpenControl: `, firstOpenControl);
        if(!firstOpenControl && (controlPermission.joinerHasLiveControlPermission || controlPermission.openControlPermission === false)) {
            setHadAuthority(true)
            setFirstOpenControl(true)
            // if(!micToast && !isPositionState){
            if(!micToast){
                setIsPositionState(false)
                setIsExpression(false)
                setIsToyControl(true)
                updateCloseOperate(true)
                setIsMic(false)
                textareaValueRef.current.blur();
            }
        }
    }, [controlPermission, micToast, updateCloseOperate, firstOpenControl])

    useEffect(()=>{
        if(controlPermission.creatorExistUntreatedLiveControlRequest && Date.now() - controlPermission.creatorLastApplyLiveControlTime < 60*1000) {
            setIsRequestingControl(true)
        }
    }, [controlPermission.creatorExistUntreatedLiveControlRequest, controlPermission.creatorLastApplyLiveControlTime])

    useEffect(()=>{
        // 客户端接收控制权限的响应数据
        const controlPermissionResponseTcEvent = (res) => {
            console.log(`--controlPermissionResponseTcEvent 2: `, res);
            setIsRequestingControl(false)

            const { operationType } = JSON.parse(res)
            // 埋点
            $api.logsNewV2({
                "logNo": "S0009",
                "content": JSON.stringify({
                    "page_name": "Control Link Open",//所在页面名称，默认
                    "event_id": "controllinkjs_permission_popup_disappear",  //事件id，默认
                    "event_type": "click",  //事件类型，默认
                    "element_id": "open_" + linkId,  //元素id，xxx为lastActiveSessionId
                    "element_content": {
                        'accept': '4',
                        'decline': '3',
                        'expired': '2',
                        // 'cancel': '1',
                    }[operationType], //元素内容，1--表示点击取消按钮，2--表示等待超过60秒自动取消，3--对方拒绝，4--对方接受
                }),
                "timeStamp":new Date().getTime()
            })
        }

        if (socketServiceInstanct) {
            socketServiceInstanct.on(SocketEvent.CL_CONTROL_PERMISSION_RESPONSE_TC, controlPermissionResponseTcEvent);
        }
        return function () {
            if (socketServiceInstanct) {
                socketServiceInstanct.off(SocketEvent.CL_CONTROL_PERMISSION_RESPONSE_TC, controlPermissionResponseTcEvent);
            }
        }
    }, [socketServiceInstanct, linkId]);



    const handleRequestingControlCancel = () => {
        setIsRequestingControl(false)
        socketServiceInstanct.socketEmitMsg(SocketEvent.CL_CONTROL_PERMISSION_REQUEST_TS, {
            linkId,
            linkPermissionType: 'live_control',
            operationType: 'cancel',
        });
        updateChatList([{...getMsgBody(linkId, { tips: `Control request cancelled` }, 'tips', `outroom-${linkId}`), createTime: Date.now(), msgId: generateMsgId()}])
    }

    useEffect(() => {
        if (!closeOperateStatus) {
            setIsPositionState(false)
            setIsExpression(false)
            setIsToyControl(false)
        }
    }, [closeOperateStatus])

    //拉取新消息的队列，防止巨量重复消息的重复拉取
    const [getNewMsgCount, setGetNewMsgCount] = useState(0);

    useEffect(()=>{
        let str = localStorage.getItem("getNewMsgList");
        if(str){
            let getNewMsgList = Array.from(JSON.parse(str));
            if(getNewMsgList.length > 0){
                if(getNewMsgList[0].status === 0){
                    getNewMsgList[0].status = 1;
                    localStorage.setItem("getNewMsgList", JSON.stringify(getNewMsgList));
                    // 向服务器拉取新消息
                    let type = SocketEvent.Q_GET_USER_NEW_MSG_LIST_TS;
                    const params = {
                        dateImTypeData: linkId,
                        msgId: msgId || ""
                    }
                    socketServiceInstanct.socketEmitMsg(type, params);
                }
            }
        }
    },[getNewMsgCount,linkId,msgId,socketServiceInstanct])

    // 判断玩具列表中是否有具备感应模式的玩具
    const hasInteractToy = (toys) => {
        return toys.some(item => supportLdsToy(item.type))
    }
    useEffect(() => {
        //服务器回应是否发送成功（ackCode值为12为正常运行）
        const sendImMsgEvent = (res) => {
            console.log(`--sendImMsgEvent: `, JSON.parse(res));
            console.log(`--chatList: `, chatList);
            setIsDisable(false);
            const {createTime, ackCode, ackId, tips = ''} = JSON.parse(res);
            let arr = [...chatList]
            // 聊天系统前端未做消息投递确认Task #33951
            let row = arr[arr.map(item => item.ackId).indexOf(ackId)]
            if(row){
                row.msgWaitState = ''
                if(ackCode!== 12) {
                    row.msgWaitState = 'error'
                } else {
                    clearTimeout(errorListTime.current[ackId])
                    delete errorListTime.current[ackId]
                }
            }
            if(ackCode!== 12 && tips) {
                setTooShort(true)
                setMicToast(tips)
                setTimeout(()=>{
                    setMicToast('')
                }, 2000)
            }
            if (isFirst) {
                let info = JSON.parse(JSON.stringify(chatInfoG));
                if(info){
                    info.isFirst = true;
                    info.createTime = createTime;
                    updateChatList([info]);
                    joinerFirstTimeChange();
                }
                return
            }
            if (row) updateChatList([row]);
        }
        const postChangeControlMsg = (msgJson) =>{
            let body = {
                "version": 1,
                "linkId": linkId,
                "event": "changeControl",
                "message": JSON.stringify(msgJson)
            };
            postMessageToApp.post(JSON.stringify(body));
        }
        //服务器回应B拉取新的消息 (checked)
        const userNewMsgListEvent = (res) => {
            let { list } = res;
            const chatList = list.filter(item => {
                return item.msgType === "chat" || item.msgType === "audio" || item.msgType === 'couponcardv1' ||
                    item.msgType === "controllink" || item.msgType === "sync" || item.msgType === "live"
            });
            console.log(`--chatList: `, chatList);
            let joinerSwitchMsgArr = [];
            if (chatList && chatList.length > 0) {
                for (const item of chatList) {
                    item.fix = "left";
                    try{
                        let data = null;
                        if(typeof item.msgData === "string"){
                            try{
                                let msgDataStr = aesUtils.aesDecryptXy(item.msgData);
                                if(msgDataStr){
                                    data = JSON.parse(msgDataStr);
                                }else{
                                    let {x: dynamicKey, y: dynamicValue} = JSON.parse(localStorage.getItem('aesEncryp')) || {};
                                    console.log("无法aesDecryptXy解密的数据 item=", item, "x=" + dynamicKey, "y=" + dynamicValue);
                                    item.msgType = "error"; //无法解密的数据，把msgType标记为error，不做任何dom渲染处理
                                }
                            }catch(e){
                                console.error("273 e=", e.message, item);
                                let {x: dynamicKey, y: dynamicValue} = JSON.parse(localStorage.getItem('aesEncryp')) || {};
                                console.log("无法aesDecryptXy解密的数据 item=", item, "x=" + dynamicKey, "y=" + dynamicValue);
                                item.msgType = "error"; //无法解密的数据，把msgType标记为error，不做任何dom渲染处理
                            }
                        }else{
                            data = item.msgData;
                        }
                        if(item.msgType === "chat") {
                            //普通文本消息
                            item.msgDataText = data.text;
                        } else if(item.msgType === "audio") {
                            //处理语音
                            item.audioUrl = config.audioFileUrl + data.url
                            item.audioTime = data.time
                            item.msgDataText = `<span>${data.time}"<span/>`
                        } else if (item.msgType === 'couponcardv1') {
                            item.countryCodeToAmounts = data.countryCodeToAmounts
                            item.couponUrl = data.couponUrl
                        } else if(item.msgType === "controllink"){
                            //接收到加入者发来默认的controlLink消息
                            item.msgData = JSON.parse(data.controlLinkData);
                        } else if(item.msgType === "sync"){ // 接收到来自加入者(remote)关于sync的请求消息
                            const extJsonStr = (item.extJsonStr && JSON.parse(item.extJsonStr)) || {}
                            const isSupport = (extJsonStr.feature && extJsonStr.feature.includes('isSupportLdrTouchPanel')) || false
                            setIsSupportTouchPanel(isSupport)
                            let dataType = (data.type + "").toLowerCase();
                            if(dataType === "request" && isCreator){  //请求发起sync控制，创建者默认接受控制
                                setLiveOrSync("sync");
                                //默认接受控制,并把当前创作者设置为被控方
                                const sendData = { ...data, type:"accept" };
                                localStorage.setItem("remoteAppSyncControlStatus", "accept");
                                const params = getMsgBody(linkId, sendData, 'sync', item.fromId);
                                socketServiceInstanct.socketEmitMsg(SocketEvent.Q_SEND_IM_MSG_TS, params);
                                setLdrControlType(2);

                                //重新更新加入者玩具信息
                                let localStorageJoinerToy = localStorage.getItem("joinerToy");
                                if(joinerToy.length === 0 && localStorageJoinerToy){
                                    let jToy = Array.from(JSON.parse(localStorageJoinerToy));
                                    setJoinerToy(jToy);
                                }
                            }else if(dataType === "swap"){ //切换控制方向
                                if(ldsOrLdr === "ldr"){        //镜面模式
                                    if(ldrControlType === 1){
                                        setLdrControlType(2);
                                    }else{
                                        setLdrControlType(1);
                                    }
                                }else if(ldsOrLdr === "lds"){  //感应模式
                                    if (isSupportTouchPanel && (!hasInteractToy(ldsCreatorToyList) || !hasInteractToy(ldsJoinerToyList))) { // 新版 && 有且仅有一方没有感应模式的玩具
                                        // 切换方向： creator有感应:0<->1   joiner有感应:0<->2
                                        if (ldsControlType === 0) {
                                            const _direction = hasInteractToy(ldsCreatorToyList) ? 1 : 2
                                            setLdsControlType(_direction)
                                            postChangeControlMsg({ type:2,direction:_direction,toyId:creatorSelectedLdsToy })
                                        } else {
                                            setLdsControlType(0)
                                            postChangeControlMsg({ type:2,direction:0,toyId:creatorSelectedLdsToy })
                                        }
                                    } else { //旧版(双方都有感应模式的玩具) 切换方向：双向控制-->主控-->被控-->双向控制
                                        if(ldsControlType === 0){
                                            setLdsControlType(2);
                                            postChangeControlMsg({ type:2,direction:2,toyId:creatorSelectedLdsToy });
                                        }else if(ldsControlType === 1){
                                            setLdsControlType(0);
                                            postChangeControlMsg({ type:2,direction:0,toyId:creatorSelectedLdsToy });
                                        }else if(ldsControlType === 2){
                                            setLdsControlType(1);
                                            postChangeControlMsg({ type:2,direction:1,toyId:creatorSelectedLdsToy });
                                        }
                                    }
                                }
                                if(isCreator && creatorFirstCome){
                                    joinerSwitchMsgArr.push("swap");
                                }
                            }else if(dataType === "swapldr"){
                                //remoteapp把lds和ldr命名搞反了,这里swapldr代表感应模式(lds)
                                setLdsOrLdr("lds");
                                setLdsControlType(0);
                                setFirstComeLds(true);
                                let u = navigator.userAgent
                                let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
                                if (isiOS) { // Android不发也没问题，所以这里单独给iOS发，避免影响Android
                                    postChangeControlMsg({ type:1,startLdr:true,toyId:creatorSelectedLdsToy,direction:ldsControlType })
                                }
                                if(isCreator && creatorFirstCome){
                                    joinerSwitchMsgArr.push("swaplds");
                                }
                            }else if(dataType === "swaplds"){
                                //remoteapp把lds和ldr命名搞反了,这里swaplds代表镜面模式(ldr)
                                setLdsOrLdr("ldr");
                                setLdrControlType(2);
                                setLdsControlType(0);
                                postChangeControlMsg({ type:1,startLdr:false,toyId:creatorSelectedLdsToy,direction:ldsControlType });
                                if(isCreator && creatorFirstCome){
                                    joinerSwitchMsgArr.push("swapldr");
                                }
                            }else if(dataType === "swapldractivetoy"){ //切换lds控制的玩具
                                //{ data: "c51af30c87e0", type: "swapLDRActiveToy" }
                                let tmpId = data.data;
                                setJoinerSelectedLdsToy(tmpId);
                                postChangeControlMsg({ type:3,toyId:tmpId });
                                if(isCreator && creatorFirstCome){
                                    joinerSwitchMsgArr.push(tmpId);
                                }
                            }else if(dataType === "end"){ //结束控制
                                localStorage.setItem("remoteAppSyncControlStatus", "end");
                                setToysData({toys:creatorToy});
                                setJoinerToy([]);
                                setToyStatus({tStatus: creatorToy});
                                if(ldsOrLdr === "lds"){
                                    postChangeControlMsg({ type:1,startLdr:false,toyId:creatorSelectedLdsToy,direction:ldsControlType });
                                }
                                setLiveOrSync("live");
                                setLdsOrLdr("ldr");
                                setLdrControlType(2);
                            }
                        } else if(item.msgType === "live"){ //接收到来自加入者(remote)关于live的请求消息
                            let dataType = (data.type + "").toLowerCase();
                            if(dataType === "request" && isCreator){ //请求发起live控制，创建者默认接受控制
                                setLiveOrSync("live");
                                //默认接受控制,并把当前创作者设置为被控方
                                const sendData = { ...data, type:"accept" };
                                localStorage.setItem("remoteAppLiveControlStatus", "accept");
                                const params = getMsgBody(linkId, sendData, 'live', item.fromId);
                                socketServiceInstanct.socketEmitMsg(SocketEvent.Q_SEND_IM_MSG_TS, params);
                                setLdrControlType(2);
                                //重新更新加入者玩具信息
                                let localStorageJoinerToy = localStorage.getItem("joinerToy");
                                if(joinerToy.length === 0 && localStorageJoinerToy){
                                    let jToy = Array.from(JSON.parse(localStorageJoinerToy));
                                    setToysData({toys:jToy});
                                    setJoinerToy(jToy);
                                }
                            }else if(dataType === "end"){
                                localStorage.setItem("remoteAppLiveControlStatus", "end");
                                setToysData({toys:creatorToy});
                                setJoinerToy([]);
                                setToyStatus({tStatus: creatorToy});
                            }
                        }
                    }catch(e){
                        console.error("error 620--", e.message, res);
                    }
                }
            }
            if (!$validate.isNull(list)) {
                //上报已读
                let type = SocketEvent.Q_DELIVER_IM_MSG_TS;
                const lastMsgId = list[list.length-1].msgId;
                socketServiceInstanct.socketEmitMsg(type, { msgId:lastMsgId });
                setMsgId(lastMsgId);
                let str = localStorage.getItem("getNewMsgList");
                if(str){
                    let getNewMsgList = Array.from(JSON.parse(str));
                    if(getNewMsgList.length>1){
                        getNewMsgList = getNewMsgList.splice(1);
                    }else{
                        getNewMsgList = [];
                    }
                    localStorage.setItem("getNewMsgList", JSON.stringify(getNewMsgList));
                }
            }else{
                localStorage.setItem("getNewMsgList", JSON.stringify([]));
            }
            setGetNewMsgCount(n=>n-1);
            // 永久缓存聊天记录
            const chatInfoList = JSON.parse(localStorage.getItem('chatList'));
            if ($validate.isNull(chatListData) && !$validate.isNull(chatInfoList)) {
                let ctArr = [];
                for(let i=0; i<chatInfoList.length; i++){
                    if(chatInfoList[i].dateImTypeData === linkId){
                        ctArr.push(chatInfoList[i]);
                    }
                }
                let arr = [...chatList, ...chatListData, ...ctArr];
                arr = deWeight(arr)
                updateChatList(arr);
                localStorage.setItem('chatList', JSON.stringify(arr));
            } else {
                updateChatList([...chatList]);
                localStorage.setItem('chatList', JSON.stringify(deWeight(chatListData)));
            }
            //创建者根据第一次拉取的消息进行分析，判断加入者当前处于哪个页面，然后自己也需要显示对于页面
            if(isCreator && creatorFirstCome){
                //['swapldr', 'swaplds', 'swapldr', 'swap', 'swap', 'swap', 'swap', 'ced09cfd1', 'swap', 'swap']
                joinerSwitchMsgArr = joinerSwitchMsgArr.reverse();
                //选中的玩具
                let sToyId = "";
                let swapArr = [];
                let swapName = "";
                for(let i=0; i<joinerSwitchMsgArr.length; i++){
                    let str = joinerSwitchMsgArr[i];
                    if(swapName === ""){
                        if(str === "swapldr" || str === "swaplds"){
                            swapName = str;
                        }else if(str === "swap"){
                            swapArr.push(str);
                        }else if(sToyId === ""){
                            sToyId = str;
                        }
                    }
                }
                if(swapName){
                    setLiveOrSync('sync');
                    let swapArrLength = swapArr.length;
                    if(swapName === "swapldr"){
                        setLdsOrLdr("ldr");
                        let tmpSwap = swapArrLength % 2 === 0 ? 1 : 2;
                        if(tmpSwap === 1){
                            setLdrControlType(2);
                        }else if(tmpSwap === 2){
                            setLdrControlType(1);
                        }
                    }else if(swapName === "swaplds"){
                        setLdsOrLdr("lds");
                        let tmpSwap = 0
                        if (isSupportTouchPanel && (!hasInteractToy(ldsCreatorToyList) || !hasInteractToy(ldsJoinerToyList))) {
                            tmpSwap = swapArrLength % 2
                            if(tmpSwap === 0){
                                setLdsControlType(0)
                            }else if(tmpSwap === 1){
                                const tmpType = hasInteractToy(ldsCreatorToyList) ? 1 : 2
                                setLdsControlType(tmpType)
                            }
                        } else {
                            tmpSwap = swapArrLength % 3
                            if(tmpSwap === 0){
                                setLdsControlType(0)
                            }else if(tmpSwap === 1){
                                setLdsControlType(2)
                            }else if(tmpSwap === 2){
                                setLdsControlType(1)
                            }
                        }

                    }
                    if(sToyId){
                        setJoinerSelectedLdsToy(sToyId);
                    }
                }
                setCreatorFirstCome(false);
            }
        }
        //收到这个事件,说明有人给B发送消息了,B自己去拉一下消息吧。
        //1.如果是一个全新的聊天,也就是APP本地的聊天列表没有B;那就去 拉一下B的信息,把列表构造出来
        const haveNewImMsgEvent = (res) => {
            let obj = { status:0 }; //0排队中 1拉取中 2拉取完毕上报已读
            let str = localStorage.getItem("getNewMsgList");
            let newArr = [obj];
            if(str){
                let oldArr = Array.from(JSON.parse(str));
                newArr = oldArr.concat([obj]);
            }
            localStorage.setItem("getNewMsgList", JSON.stringify(newArr));
            setGetNewMsgCount(n=>n+1);
        }
        // 服务器通知客户端刷新自动失效倒计时提醒
        const autoDisable = (res) => {
            // console.warn(`--服务器通知客户端刷新自动失效倒计时提醒  autoDisable: `, res);
            try {
                const data = JSON.parse(res)
                if(`${data.reachMaxAbnormalCount}` === 'true') endEvent('reachMax');
                if(`${data.remainTime}` === '-1') return endEvent('over');
                endEvent('start', data.remainTime);
            } catch (error) {
                endEvent('over');
            }
        }
        // 通知浏览器结束
        const linkIsEndEvent = (res) => {
            // console.warn(`--通知浏览器结束  linkIsEndEvent: `, res);
            try {
                socketServiceInstanct.disconnect()
                const data = JSON.parse(res)
                if(`${data.controllerBanned}` === 'true') {
                    endEvent(`ban-${data.timeUnit}-${data.banTime}`);
                    return
                }
                endEvent(data.endType);
            } catch (error) {
                endEvent();
            }
        }
        if (socketServiceInstanct) {
            const e = socketServiceInstanct._events;
            if (!e.Q_ACK_SEND_IM_MSG_TC) {
                socketServiceInstanct.on(SocketEvent.Q_ACK_SEND_IM_MSG_TC, sendImMsgEvent);
            }
            if (!e.Q_ACK_USER_NEW_MSG_LIST_TC) {
                socketServiceInstanct.on(SocketEvent.Q_ACK_USER_NEW_MSG_LIST_TC, userNewMsgListEvent);
            }
            if (!e.Q_YOU_HAVE_SOME_NEW_IM_MSG_TC) {
                socketServiceInstanct.on(SocketEvent.Q_YOU_HAVE_SOME_NEW_IM_MSG_TC, haveNewImMsgEvent);
            }
            if (!e.ANON_LINK_IS_END_TC) {
                socketServiceInstanct.on(SocketEvent.ANON_LINK_IS_END_TC, linkIsEndEvent);
            }
            if (!e.Q_REFRESH_OCCUPY_COUNTDOWN_TC) {
                socketServiceInstanct.on(SocketEvent.Q_REFRESH_OCCUPY_COUNTDOWN_TC, autoDisable);
            }
        }
        return function () {
            if (socketServiceInstanct) {
                socketServiceInstanct.off(SocketEvent.Q_ACK_SEND_IM_MSG_TC, sendImMsgEvent);
                socketServiceInstanct.off(SocketEvent.Q_ACK_USER_NEW_MSG_LIST_TC, userNewMsgListEvent);
                socketServiceInstanct.off(SocketEvent.Q_YOU_HAVE_SOME_NEW_IM_MSG_TC, haveNewImMsgEvent);
                socketServiceInstanct.off(SocketEvent.ANON_LINK_IS_END_TC, linkIsEndEvent);
                socketServiceInstanct.off(SocketEvent.Q_REFRESH_OCCUPY_COUNTDOWN_TC, autoDisable);
            }
        }
    }, [isCreator, chatList, endEvent, msgId, updateChatList, textareaValueRef, storeSendInfo, creatorSelectedLdsToy, chatInfoG, chatListData, isFirst, joinerFirstTimeChange, socketServiceInstanct, updateUserId, creatorFirstCome, ldsJoinerToyList, ldsCreatorToyList, linkId, ldsOrLdr, ldrControlType, ldsControlType, getNewMsgCount, setToysData, creatorToy, setJoinerToy, joinerToy, setToyStatus, isSupportTouchPanel]);

    return (
        <div id="chat-operate" className="chat-operate">
            <div className="control-panel">
                {/*录音icon*/}
                {(!isMic) && (
                    <div className='microphone' onClick={openMic}>
                        <img src={microphone} alt=""/>
                    </div>
                )}
                {/*键盘icon*/}
                {(isMic) && (
                    <div className="keyboard" onClick={openKeyboard}>
                        <img src={keyboard} alt=""/>
                    </div>
                )}
                <div className="input input-group">
                    <div className="inp talk-box"
                        style={{display:isMic?'block':'none'}}
                        onTouchStart={micTouchstart}
                        onTouchMove={micTouchmove}
                        onTouchEnd={micTouchend}
                        ref={textareaValueRef}>
                    </div>
                    <div className="talk-box-text" style={{display:isMic?'block':'none'}} >Hold to talk</div>
                    <div
                        id="editable-el-id"
                        style={{display:isMic?'none':'block'}}
                        onClick={openKeyboard}
                        className="inp"
                        ref={textareaValueRef}
                        contentEditable="true"
                        onInputCapture={handleValueChange}
                        onFocus={handleValueFocus}
                        onBlur={handleValueBlur}
                    />
                </div>
                {/*表情icon*/}
                {(!isExpression) && (
                    <div className="expression" onClick={chooseExpressin}>
                        <img src={emojis} alt=""/>
                    </div>
                )}
                {/*键盘icon*/}
                {(isExpression) && (
                    <div className="keyboard" onClick={openKeyboard}>
                        <img src={keyboard} alt=""/>
                    </div>
                )}
                {
                    // 玩具控制按钮
                    (!textareaValue || isMic) && (
                        <div className="control" onClick={toyControlHandler}>
                            <img src={control} alt=""/>
                        </div>
                    )
                }
                {
                    // 发送按鈕
                    (!isMic && textareaValue) && (
                        <div className="send" onClick={submitChatInfo}>
                            <img src={send} alt=""/>
                        </div>
                    )
                }
            </div>
            {
                <div className={`request-control-popup flex column ${isRequestingControl && 'show'}`} >
                    <div className='request-control-content flex acenter'>
                        <img className='request-control-icon' src={img_control_request} alt=''/>
                        <div className='request-control-text'>Waiting for the other user to respond <span></span></div>
                    </div>
                    <div className='request-control-bottom flex'>
                        <div className='request-control-btn' onClick={handleRequestingControlCancel}>Cancel</div>
                    </div>
                </div>
            }
            {
                micToast && <div className='mic-toast' style={{
                    width: tooShort?'6.6667rem':''
                }}>
                    <div className='mic-toast-icon'>
                        {!tooShort? <RecordIcon /> : <img className='waring-icon' src={waringIcon} alt=''/>}
                    </div>
                    <div>{micToast}</div>
                </div>
            }
            {
                // 表情面板

                <div className="expression-list" style={{ display:(isExpression && !isPositionState)?"block":"none" }}>
                    <ul>
                        {
                            expressionList.map(item => {
                                return (
                                    <li onClick={e => expressionHandler(item)} key={item.key}>
                                        <img src={require('@/assets/images/business/emoji/' + item.emoji).default} alt={item.key} />
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <i className="del-chat-text" onClick={delChatText}/>
                </div>

            }
            { isShowQrcode && qrCode && $validate.isPc() && <QRCode
                id="qrCode"
                value={qrCode} //地址
                size={200}     //二维码的大小
                fgColor="#000000" //二维码的颜色
                style={{margin: 'auto'}}
            />}
            {
                hadAuthority && (
                    <div style={{ display:(isToyControl && !isPositionState)?"block":"none" }}>
                        <ToyControl
                            data={{socketServiceInstanct,linkId,isCreator,qrCode,fromCam,toysData,
                                isEnd,joinerToy,creatorToy,ldsJoinerToyList,ldsCreatorToyList,
                                toyStatus,userOnline,liveOrSync,ldsOrLdr,ldrControlType,ldsControlType,isSupportTouchPanel}}
                        />
                    </div>
                )
            }
        </div>
    );
}
export default connect(state => ({
    chatList: state.chatList,
    closeOperateStatus: state.closeOperateStatus,
    controlPermission: state.controlPermission,
}), {updateChatList, updateCloseOperate})(ChatOperate);
