/*
	该模块是用于定义action对象中type类型的常量值，目的只有一个：便于管理的同时防止程序员单词写错
*/
export const STORGE_TOKEN = 'storage_token';  //存储token
export const ORDER_LINE = 'order_line'; 			//画线数据
export const CHAT_LIST = 'chat_list'; 				//聊天列表
export const TOYS_DATA = 'toys_data'; 				//玩具数据
export const CONTROL_STATE = 'control_state'; //控制状态
export const MOVING_BALL_STATE = 'moving_ball_state'; //滑动状态
export const COUNT_DOWN_STATE = 'count_down_state';   //倒计时状态 0未开始 1倒计时已开始
export const CLOSE_OPERATE = 'close_operate'; 				//关闭操作栏左右状态
export const CURRENT_SEL_BALL_ID = 'current_sel_ball_id'; 	//当前PC选中的滑动球的id
export const FROM_APP_TOY_COMMAND = 'from_app_toy_command'; //connect app嵌套的controlLink页面传过来的指令
export const SLIDE_BALL_POSITION = 'slide_ball_position';   //保存控制面板滑动球的位置
export const JOINER_INFO = 'joiner_info';   //加入者用户在线信息
export const CONTROL_PERMISSION = 'control_permission';   // 控制请求
export const LIVE_CONTROL_PERMISSION = 'live_control_permission';   // 控制请求 - 更新liveControl的权限
export const ENDCHAT_AD_REQUESTID = 'endchat_ad_requestid';   // 结束页-预加载广告requestId


