import {connect} from 'react-redux';
import InitPage from "@/components/init-page";
import EndChat from '@/components/end-chat';
import ErrorPage from "@/components/error";
import HeaderLogo from "./components/header-logo";
import ChatArea from "./components/chat-area";
import ToyControl from "./components/toy-control";
import Dialog from "@/components/dialog";
import toysArr from "@/utils/toy.js";
import './index.scss';
import React, {useEffect, useState, useRef, useCallback} from "react";
import $api from '@/serve/api';
import {SocketService} from "@/serve/socket-service";
import SocketEvent from '@/serve/socket-event';
import {updateChatList} from "@/redux/action/chat-list";
import {updateControlState} from "@/redux/action/control-state.js";
import {updateMovingBallState} from "@/redux/action/moving-ball.js";
import { updateJoinerInfo } from "@/redux/action/joiner-info.js";
import { updateControlPermission, updateLiveControlPermission } from "@/redux/action/control-permission.js";
import {formatSeconds} from "@/utils/common.js";
import {v4 as uuidv4} from 'uuid';
import { getMsgBody, generateMsgId, getUrlParam } from '../../utils/common.js';
import { orderLevel } from '@/utils/order-utils.js';
import { preloadAd } from '@/utils/preloadAd.js';

const PcPage = (props) => {
    let { updateControlState, updateJoinerInfo, updateControlPermission, updateChatList, urlInfo, joinerInfo, updateLiveControlPermission } = props;
    const [tmpSocketService] = useState(new SocketService());
    const [userId, setUserId] = useState(null);
    const [linkId, setLinkId] = useState(null);
    const [chatInfo, setChatInfo] = useState(null);
    const [toysData, setToysData] = useState({toys: []});
    //因为避免画线(orderLine)和玩具(toysData)耦合，故分开两个状态管理
    const [toyStatus, setToyStatus] = useState({tStatus: null});
    const [email, setEmail] = useState(null);
    const [linkInfo, setLinkInfo] = useState(null);
    const [userOnline, setUserOnline] = useState(true);
    const [loading, setLoading] = useState(true); //加载状态
    const [qrCode, setQrCode] = useState(null);   //二维码
    const [fromCam, setFromCam] = useState(true);// 是否来自插件
    const [isEnd, setIsEnd] = useState({end:false, endType:''});
    const [isCheck, setIsCheck] = useState(false); //判断是否可用
    const [errPageStatus, setErrPageStatus] = useState(''); // 显示错误页面
    const [tips, setTips] = useState('');
    const [time, setTime] = useState('');
    const [stop, setStop] = useState(false);
    const [reachMax, setReachMax] = useState(false);
    const timeOut = useRef(null);

    // 判断是否第一次加入
    const [joinerFirstTime, setJoinerFirstTime] = React.useState(false)
    const [isAtRoom, setIsAtRoom] = useState(false); // 是否在房间

    // 判断是否T2（多次使用的link
    const isT2 = window.location.href.includes('/t2/')
    const [longKey, setLongKey] = useState(''); // 是否获取长链接的key

    // 关闭弹框
    const closeTips = () => {
        localStorage.setItem('taptip', Date.now() + 30 * 3600 * 24 * 1000) // 设置一个月内不会再次弹出
        setTips('')
    }

    const dealToys = (toys = []) => {
        // console.warn(`--dealToys toys: `, toys);
        const dealtToys = []
        toys.forEach(toy => {
            let toyFuns = (toy.toyFun || 'v').split(',')
            if (toyFuns.includes('v') && toyFuns.includes('v1')) { // v和v1同时出现时，不需要v
                const vIdx = toyFuns.indexOf('v')
                toyFuns.splice(vIdx, 1)
            }
            if (['tenera', 'q01'].includes(toy.type.toLowerCase())) { // Tenera玩具只有s模式，不需要v模式
                toyFuns = ['s']
                toy.type = 'tenera'
            }
            if (toy.type.toLowerCase() === 'flexer') { // flexer玩具后端返回'v,f,v1,v2', 只需要'v,f'
                toyFuns = ['v', 'f']
            }
            if (toy.type.toLowerCase() === 'gravity') { // gravity玩具后端可能只返回'v', 需要'v,t'
                toyFuns = ['v', 't']
            }
            if (toy.type.toLowerCase() === 'xmachine' || toy.type.toLowerCase() === 'mini xmachine') { // xmachine玩具后端返回'v,t'，只需要't'
                toyFuns = ['t']
            }
            if (toy.type.toLowerCase() === 'solace pro') { // solace pro 具体使用的哪个模式 // t,pos
                // if(!toy.workMode) return // 无 workMode 则忽略这个玩具信息
                toyFuns = toy.workMode === 'speed' ? ['t'] : ['pos']
            }
            let tempArr = []
            orderLevel.forEach(o => { // 对指令进行重排序，保持控制球的顺序
                if (toyFuns.includes(o)) tempArr.push(o)
            })
            toy.toyFun = tempArr.join(',')
            dealtToys.push(toy)
        })
        console.log(`--dealtToys: `, dealtToys);
        return dealtToys
    }

    const [joinedFlag, setJoinedFlag] = useState(false); // 是否已经提示join
    // const [leftFlag, setLeftFlag] = useState(false); // 是否已经提示left
    const joinerInfoChangeCallback = useCallback(()=>{
        // console.log(`--joinerInfo joinerInfoChangeCallback: `, joinerInfo);
        if(joinerInfo.appPage === 'control_link') { // app在control_link房间
            if(!isAtRoom) { // 不在房间
                setIsAtRoom(true)
                if(!joinedFlag){ // 未发送过joined
                    setJoinedFlag(true)
                    updateChatList([{...getMsgBody(linkId, { tips: `The other user joined the room.` }, 'roomtips', `joinroom-${linkId}`), createTime: Date.now(), msgId: generateMsgId()}])
                }
            }
        }else{ // other
            if(isAtRoom) { // 在房间
                setIsAtRoom(false)
            //     if(!leftFlag){ // 未发送过left
            //         setLeftFlag(true)
            //         updateChatList([{...getMsgBody(linkId, { tips: `The other user is left this room. You can still control their toy(s).` }, 'roomtips', `outroom-${linkId}`), createTime: Date.now(), msgId: generateMsgId()}])
            //     }
            }
        }
    },[joinerInfo, linkId, updateChatList, isAtRoom, joinedFlag])

    useEffect(()=>{
        // console.log(`--joinerInfo watch: `, joinerInfo);
        if(joinerInfo?.appPage) joinerInfoChangeCallback()
    },[joinerInfo, joinerInfoChangeCallback])

    useEffect(() => {
        if(toysData.toys.length) return
        const statusFun = (res) => {
            setLoading(false);
            const idAlias = sessionStorage.getItem('idAlias')
            if(idAlias && idAlias.includes(`${urlInfo.code}-`)){
                const arr = idAlias.split(`${urlInfo.code}-`)
                setEmail(arr[1]);
            }
            if(`${res.code}` === '50091'){
                setErrPageStatus('isShowLimit')
                return '50091'
            }
            if(`${res.code}` === '50092'){
                setErrPageStatus('isBanPage')
                return '50092'
            }
            if(`${res.code}` === '5009101'){
                setIsEnd({end: true, endType: 'valid'})
                setIsCheck(true)
                return '5009101'
            }
            if(`${res.code}` === '5009102'){
                setIsEnd({end: true, endType: 'banned'})
                setIsCheck(true)
                return '5009102'
            }
            if(`${res.code}` === '5009103'){
                setIsEnd({end: true, endType: 'expired'})
                setIsCheck(true)
                return '5009103'
            }
            if(`${res.code}` === '5009104'){
                setIsEnd({end: true, endType: 'taken'})
                setIsCheck(true)
                return '5009104'
            }
            if(`${res.code}` === '50093'){
                const { data } = res
                if(`${data.controllerBanned}` === 'true') {
                    setIsEnd({end: true, endType:`ban-${data.timeUnit}-${data.banTime}`})
                    setIsCheck(true)
                    return '50093'
                }
                setIsEnd({end: true})
                setIsCheck(true)
                return '50093'
            }
            if (res?.data?.anotherPlaying) {
                setErrPageStatus('isAnotherPlaying');
                return 'error'
            }
            if(`${res.result}` !== 'true') {
                setErrPageStatus('default')
                return 'error'
            }
        }
        if(!isEnd.end && isT2 && !longKey && !isCheck){
            const anonKey = localStorage.getItem('anonKey')
            let bodyFormData = new FormData();
            bodyFormData.append('shortCode', urlInfo.code);
            if(anonKey) bodyFormData.append('anonKey', anonKey);
            $api.longtimecontrollink(bodyFormData).then(res => {
                if(statusFun(res)) return
                const { data } = res
                setLongKey(data.id)
                if(data.anonKey) localStorage.setItem('anonKey' ,data.anonKey)
                if(data.shareTophy) sessionStorage.setItem('shareTophy' ,data.shareTophy)
            }).catch(err => {
                setErrPageStatus('default')
                setLoading(false);
            })
            return
        }
        if(!isEnd.end && !isCheck && !urlInfo.isCreator && !tips){
            let bodyFormData = new FormData();
            bodyFormData.append('id', isT2? longKey : urlInfo.code);
            bodyFormData.append('historyUrl', document.referrer);
            $api.anonControllinkStatus(bodyFormData).then(res => {
                if(statusFun(res)) return
                if(localStorage.getItem('taptip') < Date.now()){
                    setTips(()=>{
                        return (
                            <span>You are going to control the Lovense toys from this link. By clicking OK below you agree to our <a style={{color: '#FF2D89'}} href='https://hyttoapps.bandnana.com/remote/terms-and-conditions' target='_blank' rel="noreferrer">Terms & Conditions.</a> Contact us <a style={{color: '#FF2D89'}} href='https://www.lovense.com/contact' target='_blank' rel="noreferrer">here.</a> </span>
                        )
                    })
                }
                setIsCheck(true)
            }).catch(err => {
                setErrPageStatus('default');
                setLoading(false);
            })
        }
        if(urlInfo.isCreator) {
            setIsCheck(true)
        }

        if(isEnd && !isEnd.end && !tips && isCheck){
            localStorage.setItem("last-send-timestamp", 1);
            let timer5 = null; //controlLink信息轮询定时器
            const urlInfo = window.location.href.split('/');
            const id = urlInfo[urlInfo.length - 1];
            let bodyFormData = new FormData();
            bodyFormData.append('id', isT2? longKey : id);
            bodyFormData.append('historyUrl', document.referrer);
            const refererChannel = getUrlParam('referer_channel')
            if(refererChannel) bodyFormData.append('refererChannel', refererChannel);
            let userIdTmp = "";
            $api.anonControllinkJoin(bodyFormData).then(res => {
                if (res.result) {
                    if (res.data.anotherPlaying) {
                        setErrPageStatus('isAnotherPlaying');
                    }
                    const {controlLinkData: {linkStatus}} = res.data;
                    if (linkStatus !== 0) {
                        setIsEnd({end:true})
                    } else {
                        const {
                            controlLinkData: {link, x, y, linkId, joinerFirstTime, creator, controlPermission},
                            socketIoPath,
                            wsUrl: wsServerUrl,
                            qrCode,
                            fromCam,
                            idAlias,
                        } = res.data;
                        if(!controlPermission){
                            updateLiveControlPermission({openControlPermission: false})
                        }else{
                            updateControlPermission(controlPermission)
                        }
                        let userId = null;
                        let toys = null;
                        if (creator) {
                            userId = creator.userId;
                            toys = dealToys(creator.toys)
                            setToysData({toys: toys});
                            setToyStatus({tStatus: toys});
                            if (toys && toys.length > 1) {
                                let toysName = [];
                                for (const item of toys) {
                                    let tmpName = toysArr[(item.type+"").toLowerCase()];
                                    if(!tmpName){
                                        tmpName = item.type;
                                    }
                                    if(item.version){
                                        toysName.push(tmpName + " " + item.version);
                                    }else{
                                        toysName.push(tmpName);
                                    }
                                }
                                res.data.controlLinkData.toysName = toysName.join(',')
                            }
                            userIdTmp = userId;
                            setUserId(userId);
                        }
                        // 存储x,y给到msgData信息使用aes做加解密处理
                        localStorage.setItem('aesEncryp', JSON.stringify({x, y}));
                        setFromCam(fromCam || false);
                        setEmail(idAlias);
                        sessionStorage.setItem('idAlias', `${id}-${idAlias}`)
                        setQrCode(qrCode);
                        if (link) {
                            if (link.expires > 0) {
                                link.leftControlTimeText = formatSeconds(link.leftControlTime);
                            } else if (link.expires < 0 && !link.isStart) {
                                link.leftControlTimeText = '00:00'; //永久
                            }
                            setLinkInfo(link);
                            if (!timer5) {
                                // console.log("进入页面，轮询controlLink信息.....");
                                refreshControlLinkEvent(JSON.stringify({"linkId": linkId}));
                            }
                        }
                        let chatInfo = getMsgBody(linkId,res.data.controlLinkData,"controllink",userId);
                        chatInfo.fix = "right";
                        chatInfo.isFirst = joinerFirstTime;
                        setChatInfo(chatInfo);
                        setLinkId(linkId);
                        tmpSocketService.initSocket(wsServerUrl, socketIoPath);
                        // 打开控制面板
                        tmpSocketService.socketEmitMsg(SocketEvent.ANON_OPEN_CONTROL_PANEL_TS, {linkId: linkId});
                        const msgData = {
                            controlLinkData: JSON.stringify(res.data.controlLinkData)
                        }
                        // 判断是否第一次加入
                        if (joinerFirstTime) {
                            // 清除聊天记录缓存
                            setTimeout(() => localStorage.setItem('chatList', JSON.stringify([])));
                            setJoinerFirstTime(joinerFirstTime);
                            // 发送第一条默认消息
                            const controlLinkData = getMsgBody(linkId,msgData,"controllink",userId);
                            setTimeout(() => {
                                let type = SocketEvent.Q_SEND_IM_MSG_TS;
                                tmpSocketService.socketEmitMsg(type, controlLinkData);
                            }, 500);
                        } else {
                            // 关闭窗口重新拉去未读信息
                            tmpSocketService.socketEmitMsg(SocketEvent.Q_GET_USER_NEW_MSG_LIST_TS, { dateImTypeData:linkId,msgId:''});
                        }
                        if(!window.sessionStorage.getItem(linkId+'linkId-log-flag')){
                            const isOpenControlPermission = controlPermission && controlPermission.openControlPermission
                            let text = {
                                "logNo":"S0009",
                                "content": JSON.stringify({
                                    "page_name":"Control Link Open",
                                    "event_id":"control_link_open",
                                    "event_type":"open",
                                    "element_id":"open_" + linkId,  //元素id，xxx为 lastActiveSessionId
                                    "element_type": '2' ,
                                    "element_content": window.sessionStorage.getItem('shareTophy')? "1" : "2",
                                    "element_name": isOpenControlPermission ? "1" : "2", // 元素名称，是否开启控制请求，1--开启control permission，2--关闭control permission
                                    "duration":"",
                                    "toys":""
                                }),
                                "timeStamp": new Date().getTime()
                            }
                            $api.logsNewV2(text)
                            window.sessionStorage.setItem(linkId+'linkId-log-flag', '1')
                        }
                        preloadAd(linkId)
                    }

                } else {
                    setErrPageStatus('default');;
                }
                setTimeout(() => {
                    setLoading(false);
                }, 250);
            }, err => {
                setErrPageStatus('default');;
                setLoading(false);
            });

            //remote开始控制玩具，controlLink状态变更
            const refreshControlLinkEvent = (res) => {
                // console.log("remote开始控制玩具，controlLink状态变更-->", res);
                if (tmpSocketService) {
                    let linkId = JSON.parse(res).linkId;
                    let json1 = {"linkId": linkId};
                    tmpSocketService.socketEmitMsg(SocketEvent.ANON_QUERY_CONTROL_INFO_TS, json1);
                    clearInterval(timer5);
                    timer5 = setInterval(() => {
                        tmpSocketService.socketEmitMsg(SocketEvent.ANON_QUERY_CONTROL_INFO_TS, json1);
                        let uuid = uuidv4();
                        if (userIdTmp) {
                            //询问是否在线
                            if(isEnd && !isEnd.end){
                                let json2 = { ackId:uuid, userId:userIdTmp, linkId:linkId };
                                tmpSocketService.socketEmitMsg(SocketEvent.QUERY_USER_ON_LINE_INFO_TS, json2);
                            }else{
                                clearInterval(timer5);
                            }
                        }
                    }, 5000);
                }
            }
            //返回对方用户是否在线的查询结果
            const ackQueryUserOnlineInfoTcEvent = (res) => {
                console.log("joinerInfo 在线状态变更：ackQueryUserOnlineInfoTcEvent -- res=", res);
                updateJoinerInfo(res)
                if (res.onLine) {  //上线
                    setUserOnline(true);
                } else {  //掉线(通常是杀死app)
                    setUserOnline(false);
                }
            }

            if (tmpSocketService) {
                const e = tmpSocketService._events;
                if (!e.ANON_YOU_MUST_REFRESH_CONTROL_LINK_TC) {
                    tmpSocketService.on(SocketEvent.ANON_YOU_MUST_REFRESH_CONTROL_LINK_TC, refreshControlLinkEvent);
                }
                if (!e.ACK_QUERY_USER_ON_LINE_INFO_TC) {
                    tmpSocketService.on(SocketEvent.ACK_QUERY_USER_ON_LINE_INFO_TC, ackQueryUserOnlineInfoTcEvent);
                }

            }
            return function () {
                if (timer5) {
                    clearInterval(timer5);
                }
                // if (tmpSocketService) {
                //     tmpSocketService.off(SocketEvent.ANON_YOU_MUST_REFRESH_CONTROL_LINK_TC, refreshControlLinkEvent);
                //     tmpSocketService.off(SocketEvent.ACK_QUERY_USER_ON_LINE_INFO_TC, ackQueryUserOnlineInfoTcEvent);
                // }
            }
        }
    }, [tmpSocketService, isEnd, tips, isCheck, urlInfo, isT2, longKey, updateJoinerInfo, toysData.toys.length, updateControlPermission, updateLiveControlPermission])

    useEffect(() => {
        if(isEnd && !isEnd.end && !tips && isCheck){
            //返回controlLink的查询结果
            const anonQueryControlInfoTcEvent = (res) => {
                // console.log("查询controlLink结果 res=", res);
                let obj = {  controlLinkData: JSON.parse(res) };
                console.log(`--obj: `, obj);
                const { controlLinkData: {link, linkStatus, creator, controlPermission} } = obj;
                console.log(`--controlPermission: `, controlPermission);
                if(!controlPermission){
                    updateLiveControlPermission({openControlPermission: false})
                }else{
                    updateControlPermission(controlPermission)
                }
                if (link) {
                    if (link.expires > 0) {
                        link.leftControlTimeText = formatSeconds(link.leftControlTime);
                    } else if (link.expires < 0 && !link.isStart) {
                        link.leftControlTimeText = '00:00'; //永久
                    }
                    setLinkInfo(link);
                    let clState = link.isStart ? 1 : 0
                    if (linkStatus !== 0) clState = 2
                    updateControlState({"state": clState})
                }
                if (creator) {
                    let toys = dealToys(creator.toys)
                    setToyStatus( {tStatus: toys} );
                    console.log(`--toysData: `, toysData.toys?.length);
                    // if(!toysData.toys?.length && toys.length){
                    if(toysData.toys?.length !== toys.length || toys.some((toy,index) => (toy.workMode !== toysData.toys?.[index].workMode))){
                        setToysData({toys})
                    }
                }
                if (linkStatus !== 0) {
                    const { controlLinkData: { punishment } } = obj
                    if(`${punishment.controllerBanned}` === 'true') {
                        setIsEnd({end: true, endType:`ban-${punishment.timeUnit}-${punishment.banTime}`})
                        return
                    }
                    setIsEnd({end:true});
                }
            }

            if (tmpSocketService) {
                const e = tmpSocketService._events;
                if (!e.ANON_QUERY_CONTROL_INFO_TC) {
                    tmpSocketService.on(SocketEvent.ANON_QUERY_CONTROL_INFO_TC, anonQueryControlInfoTcEvent);
                }

            }
            return function () {
                if (tmpSocketService) {
                    tmpSocketService.off(SocketEvent.ANON_QUERY_CONTROL_INFO_TC, anonQueryControlInfoTcEvent);
                }
            }
        }
    }, [tmpSocketService, updateControlState, isEnd, tips, isCheck, urlInfo, isT2, longKey, updateControlPermission, updateLiveControlPermission, toysData.toys])

    useEffect(()=>{
        // 客户端接收控制权限的响应数据
        const controlPermissionResponseTcEvent = (res) => {
            console.log(`--controlPermissionResponseTcEvent: `, res);
            const { operationType } = JSON.parse(res)
            if(operationType === 'accept'){
                updateLiveControlPermission({joinerHasLiveControlPermission: true})
            }else{
                const tipsMap = {
                    decline: 'declined',
                    expired: 'has expired',
                }
                tipsMap[operationType] && updateChatList([{...getMsgBody(linkId, { tips: `Control request ${tipsMap[operationType]}` }, 'tips', `outroom-${linkId}`), createTime: Date.now(), msgId: generateMsgId()}])
            }
        }
        tmpSocketService.on(SocketEvent.CL_CONTROL_PERMISSION_RESPONSE_TC, controlPermissionResponseTcEvent);
        return ()=>{
            tmpSocketService.off(SocketEvent.CL_CONTROL_PERMISSION_RESPONSE_TC, controlPermissionResponseTcEvent);
        }
    }, [tmpSocketService, updateLiveControlPermission, updateChatList, linkId]);

    useEffect(()=>{
        if(isEnd && isEnd.end) clearTimeout(timeOut.current)
    }, [isEnd]);

    // 更新第一次进入状态为false
    const joinerFirstTimeChange = () => {
        setJoinerFirstTime(false)
    }

    // 点击结束socket按钮
    const endSocket = () => {
        tmpSocketService.socketEmitMsg(SocketEvent.ANON_END_CONTROL_LINK_TS, {linkId: linkId});
        // 三秒后没收到服务器响应则跳转默认结束页面
        timeOut.current = setTimeout(() => {
            tmpSocketService.disconnect()
            setIsEnd({end: true})
        }, 3000);
    }

    const updateUserId = (userId) => {
        setUserId(userId)
    }
    // 通知浏览器结束, 通知占用倒计时消息开启/停止
    const endEvent = (e, time) => {
        let endType = e || ''
        if(e === 'reachMax') return setReachMax(true)
        if(e === 'over') return setStop('over')
        if(e === 'start') {
            if (time) setTime(time)
            return
        }
        // if(e && e.includes('ban-')) console.log('auto_banned');
        tmpSocketService.disconnect()
        setIsEnd({end:true, endType})
    }

    // 发送停止倒计时指令
    const closeTime = () => {
        if(tmpSocketService){
            tmpSocketService.socketEmitMsg(SocketEvent.Q_CANCEL_OCCUPY_COUNTDOWN_TS, {linkId: linkId});
            setStop('over')
        }
    }

    return (tips?
        (<Dialog content={tips} closeTips={closeTips} />):
        (<div className="pc-page">
            <HeaderLogo />
            <div className="pc-main">
                <ToyControl
                    socketServiceInstanct={tmpSocketService}
                    userId={userId}
                    userOnline={userOnline}
                    linkId={linkId}
                    isEnd={isEnd}
                    joinerFirstTime={joinerFirstTime}
                    chatInfo={chatInfo}
                    linkInfo={linkInfo}
                    joinerFirstTimeChange={joinerFirstTimeChange}
                    qrCode={qrCode}
                    fromCam={fromCam}
                    toysData={toysData.toys}
                    toyStatus={toyStatus.tStatus}
                    endSocket={endSocket}
                    setIsEnd={setIsEnd}
                    updateUserId={updateUserId}
                />
                <ChatArea
                    socketServiceInstanct={tmpSocketService}
                    userId={userId}
                    userOnline={userOnline}
                    linkId={linkId}
                    joinerFirstTime={joinerFirstTime}
                    chatInfo={chatInfo}
                    linkInfo={linkInfo}
                    joinerFirstTimeChange={joinerFirstTimeChange}
                    qrCode={qrCode}
                    toysData={toysData.toys}
                    toyStatus={toyStatus.tStatus}
                    endSocket={endSocket}
                    updateUserId={updateUserId}
                    endEvent={endEvent}
                    time={time}
                    stop={stop}
                    reachMax={reachMax}
                    closeTime={closeTime}
                />
            </div>
            {/*初始加载页*/}
            {loading && (<InitPage/>)}

            {/*失效页面*/}
            {(isEnd && isEnd.end) && (<EndChat code={urlInfo.code} endType={isEnd.endType} email={email} linkId={linkId} />)}
            {/*异常页面*/}
            {(errPageStatus && <ErrorPage pageStatus={errPageStatus}/>)}
        </div>)
    )
}

export default connect(state => ({
    chatList: state.chatList,
    joinerInfo: state.joinerInfo
}), {
    updateChatList,
    updateControlState,
    updateMovingBallState,
    updateJoinerInfo,
    updateControlPermission,
    updateLiveControlPermission,
})(PcPage);
