import { FROM_APP_TOY_COMMAND } from "../constant";
const fromAppToyCommand = (preState = {}, action) => {
    const {type, data} = action
    switch (type) {
        case FROM_APP_TOY_COMMAND:
            return {...preState, ...data}
        default:
            return preState
    }
}
export default fromAppToyCommand;
