import React, { useState, useRef, useEffect, useCallback } from 'react';
import f_logo_v from '@/assets/images/business/control_ball_img/wave.png';
import f_logo_v2 from '@/assets/images/business/control_ball_img/wave.png';
import f_logo_v3 from '@/assets/images/business/control_ball_img/wave.png';
import f_logo_r from '@/assets/images/business/control_ball_img/rotation.png';
import f_logo_p from '@/assets/images/business/control_ball_img/contraction.png';
import f_logo_s from '@/assets/images/business/control_ball_img/suck.png';
import f_logo_f from '@/assets/images/business/control_ball_img/fingering.png';
import f_logo_t from '@/assets/images/business/control_ball_img/thrusting.png';
import f_logo_d from '@/assets/images/business/control_ball_img/depth.png';
import f_logo_pos from '@/assets/images/business/control_ball_img/position.png';
import f_logo_o from '@/assets/images/business/control_ball_img/oscillate.png';
import { orderUtils } from '@/utils/order-utils.js';
import { updateOrderLine } from '@/redux/action/order-line.js';
import { updateControlState } from '@/redux/action/control-state.js';
import { updateCurrentBallInfo } from '@/redux/action/current-ball-info.js';
import {connect} from 'react-redux';
import throttle from 'lodash.throttle';
import SocketEvent from '@/serve/socket-event';
import { v4 as uuidv4 } from 'uuid';

const SlideBtn = (props) => {
    let logoImg = f_logo_v;
    let btnId = "toy1-drag-v-btn-id";
    let {
      data,
      orderLine,updateOrderLine,
      controlState,updateControlState,
      currentBallInfo,updateCurrentBallInfo,
      touchEndAndSendOrder,
    } = props;

    let { linkId,toyId,isCreator,orderType,socketServiceInstanct, ballCount, ballIndex, ballRadius, zIndex, setZIndex, toyName } = data;
    let ballImgClass = 'ball-img-pink'; //滑球样式

    //根据玩具、马达，计算滑球按钮的domId
    let toyIndexFlag = toyId;
    switch (orderType) {
      case 'v1':
        logoImg = f_logo_v;
        btnId = toyIndexFlag + "-drag-v1-btn-id"
        ballImgClass = 'ball-img-pink'
        break
      case 's':
        logoImg = f_logo_s;
        btnId = toyIndexFlag + "-drag-s-btn-id"
        ballImgClass = 'ball-img-pink'
        break
      case 'v':
        logoImg = f_logo_v
        btnId = toyIndexFlag + "-drag-v-btn-id"
        ballImgClass = 'ball-img-pink'
        break
      case 'v2':
        logoImg = f_logo_v2
        btnId = toyIndexFlag + "-drag-v2-btn-id"
        ballImgClass = 'ball-img-blue'
        break
      case 'v3':
        logoImg = f_logo_v3
        btnId = toyIndexFlag + "-drag-v3-btn-id"
        ballImgClass = 'ball-img-purple'
        break
      case 'r':
        logoImg = f_logo_r
        btnId = toyIndexFlag + "-drag-r-btn-id"
        ballImgClass = 'ball-img-blue'
        break
      case 'p':
        logoImg = f_logo_p
        btnId = toyIndexFlag + "-drag-p-btn-id"
        ballImgClass = 'ball-img-purple'
        break
      case 'f':
        logoImg = f_logo_f
        btnId = toyIndexFlag + "-drag-f-btn-id"
        ballImgClass = 'ball-img-purple'
        break
      case 't':
        logoImg = f_logo_t
        btnId = toyIndexFlag + "-drag-t-btn-id"
        ballImgClass = 'ball-img-pink'
        break
      case 'd':
        logoImg = f_logo_d
        btnId = toyIndexFlag + "-drag-d-btn-id"
        ballImgClass = 'ball-img-blue'
        break
      case 'pos':
        logoImg = f_logo_pos
        btnId = toyIndexFlag + "-drag-pos-btn-id"
        ballImgClass = 'ball-img-blue'
        break
      case 'o':
        logoImg = f_logo_o
        btnId = toyIndexFlag + "-drag-o-btn-id"
        ballImgClass = 'ball-img-blue'
        break
      default:
        logoImg = f_logo_v
        btnId = toyIndexFlag + "-drag-v-btn-id"
        ballImgClass = 'ball-img-pink'
        break
    }
    const [prevY, setPrevY] = useState(0);
    const [prevBtnTop, setPrevBtnTop] = useState(0);
    const [touchBtnId, setTouchBtnId] = useState("");
    // const ballRadius = ballCount <= 2 ? 30 : 20 // 球半径
    const ballDiameter = ballRadius * 2 // 球直径
    // const perRem = window.screen.width / 10
    const perRem = document.querySelector('.main').offsetWidth / 10
    const padtbRem = 0.32
    const padlr = perRem * 0.32
    // let defaultTop = 142;
    let defaultTop = Math.ceil(perRem * (5.32 - padtbRem * 2) - ballDiameter);
    const [slideBtnTop, setSlideBtnTop] = useState(defaultTop);
    // const slideBtnDefaultLeft = (perRem * 7.74 - ballRadius - (padlr)) / ballCount // const control_page_left_ele = document.querySelector(".balls-area") -> 290
    const mainRem = document.querySelector('.evake-remote') ? 7.74 : 10.04
    const slideBtnParentWidth = ((perRem * mainRem) - (padlr * 2)) / ballCount // const control_page_left_ele = document.querySelector(".balls-area") -> 290
    const [slideBtnTranslateX, setSlideBtnTranslateX] = useState(0);
    const [drawTimer, setDrawTimer] = useState(null);
    const [lastOrder, setLastOrder] = useState("");
    const [ballShadowClass, setBallShadowClass] = useState(""); //滑球样式

    //发给玩具指令
    const sendOrderToToy = (orderLine,linkId)=>{
        let toyData = {...orderLine};
        let orderBody = {};
        Object.keys(toyData).forEach(function(key){
            const curToy = toyData[key]
            let ctId = curToy.toyId;
            orderBody[ctId] = { v: -1, v1: -1, v2: -1, v3: -1, s: -1, p: -1, r: -1, f: -1, t: -1, d: -1 };

            let toyFunArr = curToy.toyFun.split(',');
            toyFunArr.forEach(order => {
              let level = -1;
              let lineArr = []
              switch (order) {
                case 'v':
                case 'v1':
                case 's':
                  lineArr = curToy.line1
                  break
                case 'v2':
                case 'r':
                case 'p':
                case 'f':
                case 'd':
                case 'pos':
                case 'o':
                  lineArr = curToy.line2
                  break
                case 't':
                  if(curToy.type.toLowerCase()==='solace') {
                    lineArr = curToy.line1
                  }else{
                    lineArr = curToy.line2
                  }
                  break
                case 'v3':
                  lineArr = curToy.line3
                  break
                default:
                  lineArr = curToy.line1
                  break
              }
              if(lineArr.length > 1){
                  level = lineArr[lineArr.length - 1];
              }
              if (order === 'p') {
                  if(level >=1 && level <= 6){
                      level = 1;
                  }else if(level > 6 && level < 14){
                      level = 2;
                  }else if(level >= 14){
                      level = 3;
                  }
              }
              if (order === 'pos' && level > 0) {
                level *= 5;
              }
              orderBody[ctId] = { ...orderBody[ctId], [order]: level }
            })
        });
        let cate = { "version": 5, "cate": "id", "id": orderBody };
        let order = {
            "toyCommandJson": JSON.stringify(cate),
            "linkId": linkId,
            "userTouch": false
        }
        return order;
    }

    //防抖函数引用
    const throttleSaveLevel = useRef(throttle((value, orderLineTmp, linkId, mouseDown)=>{
      addPointToLine(value, orderLineTmp, linkId, mouseDown);
    }, 100)).current;
    //节流方法
    const addPointToLine = useCallback((value, orderLineTmp, linkId, mouseDown)=>{
      let level = orderUtils.heightToLevel(value, defaultTop, 0);
      // console.log(`--level: `, level);
      // console.log(`--value: `, value);
      setSlideBtnTop(value);
      let tmpArr = [...orderLineTmp];
      for(let i=0; i<tmpArr.length; i++){
        let func = tmpArr[i].toyFun.split(",");
        if(tmpArr[i].toyId === toyId){
          if(['v', 'v1', 's'].includes(orderType) || (tmpArr[i].type.toLowerCase()==='solace' && orderType==='t' )){
            tmpArr[i].line1.push(level)
            if(func.length > 1){
              let length = tmpArr[i].line2.length
              let n = tmpArr[i].line2[length-1]
              tmpArr[i].line2.push(n);
              if(tmpArr[i].line3) {
                let length = tmpArr[i].line3.length
                let n = tmpArr[i].line3[length-1]
                tmpArr[i].line3.push(n)
              }
            }
          }else if(['v2', 'r', 'p', 'f', 't', 'd', 'o', 'pos'].includes(orderType)){
            tmpArr[i].line2.push(level)
            if (func.length > 1) {
              let length = tmpArr[i].line1.length
              let n = tmpArr[i].line1[length-1]
              tmpArr[i].line1.push(n);
              if(tmpArr[i].line3) {
                let length = tmpArr[i].line3.length
                let n = tmpArr[i].line3[length-1]
                tmpArr[i].line3.push(n);
              }
            }
          }else if(['v3'].includes(orderType)){
            tmpArr[i].line3.push(level)
            if (func.length > 1) {
              let length = tmpArr[i].line1.length
              let n = tmpArr[i].line1[length-1]
              tmpArr[i].line1.push(n);
              {
                let length = tmpArr[i].line2.length
                let n = tmpArr[i].line2[length-1]
                tmpArr[i].line2.push(n)
              }
            }
          }
        }else{ //自动补点其他曲线数据，保持曲线x轴长度一致
          Object.keys(tmpArr[i]).forEach(key => {
            if (key.includes('line')) {
              let length = tmpArr[i][key].length
              let n = tmpArr[i][key][length-1]
              tmpArr[i][key].push(n)
            }
          })
        }
        updateOrderLine(tmpArr);
      }
      if(orderLine && linkId){
        let order = sendOrderToToy(orderLine,linkId);
        order.userTouch = Boolean(mouseDown)
        let orderStr = JSON.stringify(order);
        if(lastOrder !== orderStr){
          socketServiceInstanct.socketEmitMsg(SocketEvent.ANON_COMMAND_LINK_TS, order);
          setLastOrder(orderStr);
        }
      }
    },[lastOrder,orderLine,orderType,toyId,defaultTop,updateOrderLine,socketServiceInstanct]);

    //点击控制面板，根据点击位置改变选中的控制球的高度(Level)
    useEffect(()=>{
      // let targetEl = document.getElementById("mobile-balls-area");
      let targetEl = document.querySelector(".ball-item");
      const clickMobileBallsArea = (e) => {
        if(btnId === currentBallInfo.currentSelBallId){
          let clickTarget = (e.target.nodeName + "").toLowerCase();
          if(clickTarget === "div"){
            let height = e.offsetY - ballRadius;
            if(height < 0){
              height = 0;
            }else if(height > defaultTop){
              height = defaultTop;
            }
            setSlideBtnTop(height);
            throttleSaveLevel(parseInt(height), orderLine, linkId, true);
          }
        }
      }
      if(targetEl){
        targetEl.addEventListener('click',clickMobileBallsArea, false);
      }
      //事件解绑
      return function(){
        if(targetEl){
          targetEl.removeEventListener('click',clickMobileBallsArea, false);
        }
      }
    }, [defaultTop,btnId,currentBallInfo,orderLine,throttleSaveLevel,linkId, ballRadius]);

    //加入者进入页面就画一根直线水平播放
    const [defaultDrawTimer, setDefaultDrawTimer] = useState(null);
    useEffect(()=>{
      let tm = null;
      if(!isCreator){
        tm = setTimeout(()=>{
          addPointToLine(parseInt(slideBtnTop), orderLine);
        },100);
        setDefaultDrawTimer(tm);
      }
      return function(){
        clearTimeout(tm);
        setDefaultDrawTimer(null);
      }
    },[isCreator,slideBtnTop,orderLine,addPointToLine]);

    //计算选择的滑球外发光样式
    useEffect(()=>{
      let classStr = "";
      if(btnId === currentBallInfo.currentSelBallId){
        if (['v', 'v1', 's', 't'].includes(orderType)) {
          classStr = 'ball-shadow-pink'
        } else if (['f', 'v3', 'p'].includes(orderType)) {
          classStr = 'ball-shadow-purple'
        } else {
          classStr = 'ball-shadow-blue'
        }
      }
      setBallShadowClass(classStr);
    },[btnId, currentBallInfo, orderType])

    const [stopToys, setStopToys] = useState(false);
    useEffect(()=>{
      //触碰滑动球则开始控制
      const touchStartEvent = (e)=>{
        const newZIndex = Array(ballCount)
        newZIndex[ballIndex] = 2
        setZIndex(newZIndex)
        let eId = e.currentTarget.id;
        if(eId){
          //清除自动补点画线定时器
          clearInterval(drawTimer);
          setDrawTimer(null);
          if(controlState.state === 0){
            updateControlState({"state":1});
            //查询controlLink信息，同步数据
            if(socketServiceInstanct){
              let json1 = { ackId:uuidv4(), "linkId":linkId };
              setTimeout(()=>{
                socketServiceInstanct.socketEmitMsg(SocketEvent.ANON_QUERY_CONTROL_INFO_TS, json1);
              },200);
            }
          }
          setTouchBtnId(eId);
          setPrevY(e.targetTouches[0].screenY);
          let tEl = document.getElementById(eId);
          if(tEl){
            setPrevBtnTop(tEl.offsetTop - 60);
          }
        }
        updateCurrentBallInfo({...currentBallInfo, "currentSelBallId": btnId});
      }
      //滑动转换为指令等级
      const touchMoveEvent = (e)=>{
        e.stopPropagation();
        e.preventDefault()

        // const control_page_left_ele = document.querySelector(".ball-list")
        // const translateX = e.touches[0].clientX - ((slideBtnParentWidth) / 2) - (ballIndex * ((control_page_left_ele.clientWidth - padlr) / ballCount))
        // const translateX = e.touches[0].clientX - ((slideBtnParentWidth + ballRadius) / 2) - (ballIndex * ((control_page_left_ele.clientWidth - ballRadius) / ballCount))
        const translateX = e.touches[0].clientX - (slideBtnParentWidth / 2) - padlr - (ballIndex * slideBtnParentWidth) - ((document.body.offsetWidth - document.querySelector('.main').offsetWidth)/2)
        // console.log(`--translateX: `, translateX);
        // setSlideBtnTranslateX(translateX)
        const xMin = -(slideBtnParentWidth / 2 - ballRadius + (slideBtnParentWidth * ballIndex))
        // console.log(`--xMin: `, xMin);
        const xMax = slideBtnParentWidth / 2 - ballRadius + (slideBtnParentWidth * (ballCount - 1 - ballIndex))
        // console.log(`--xMax: `, xMax);
        setSlideBtnTranslateX(translateX > xMax ? xMax : translateX < xMin ? xMin : translateX)

        let clientY = e.targetTouches[0].screenY;
        let changeY = prevY - clientY - 50;
        let newTop = prevBtnTop - changeY;
        if(newTop<=0){
          newTop = 0;
        }else if(newTop >= defaultTop){
          newTop = defaultTop;
        }
        setDefaultDrawTimer(null);
        clearInterval(defaultDrawTimer);
        if(newTop === defaultTop){ //停止指令不能被节流方法过滤掉
          if(!stopToys){
            setStopToys(true);
            addPointToLine(parseInt(defaultTop), orderLine);
            let tm = setTimeout(()=>{
              addPointToLine(parseInt(defaultTop), orderLine);
              setStopToys(false);
              clearTimeout(tm);
            },100);
          }
        }else{
          setSlideBtnTop(newTop);
          throttleSaveLevel(parseInt(newTop), orderLine);
        }
      };
      //手指离开控制球，需开启定时器自动补点画线
      const touchEndEvent = ()=>{
        clearInterval(drawTimer);
        setDrawTimer(null);
        let timer = null;
        if(controlState.state !== 2){
          timer = setInterval(()=>{
            let tmpArr = [...orderLine];
            for(let i=0; i<tmpArr.length; i++){
              //自动补点其他曲线数据，保持曲线x轴长度一致
              Object.keys(tmpArr[i]).forEach(key => {
                if (key.includes('line')) {
                  let length = tmpArr[i][key].length
                  let n = tmpArr[i][key][length-1]
                  tmpArr[i][key].push(n)
                }
              })
            }
            updateOrderLine(tmpArr);
          }, 100);
          setDrawTimer(timer);
        }else{
          //结束控制
          clearInterval(timer);
          setDrawTimer(null);
          socketServiceInstanct.disconnect();
        }
        //为避免最后滑动的指令被防抖过滤掉
        //这里在手指touchend的时候不经过防抖方法补发最后一个指令
        touchEndAndSendOrder();
      }
      //事件绑定
      if(btnId){
        let element = document.getElementById(btnId);
        if(element){
          element.addEventListener('touchstart',touchStartEvent, false);
          element.addEventListener('touchmove',touchMoveEvent, false);
          element.addEventListener('touchend',touchEndEvent, false);
        }
      }
      //事件和定时器 解绑
      return function(){
        if(btnId){
          let element = document.getElementById(btnId);
          if(element){
            element.removeEventListener('touchstart',touchStartEvent, false);
            element.removeEventListener('touchmove',touchMoveEvent, false);
            element.removeEventListener('touchend',touchEndEvent, false);
          }
        }
        clearInterval(drawTimer);
        setDrawTimer(null);
      }

    },[btnId,touchBtnId,addPointToLine,controlState,defaultTop,stopToys,
      ballIndex, ballCount, zIndex, setZIndex, slideBtnParentWidth, ballRadius, padlr,
      drawTimer,orderLine,prevBtnTop,prevY,throttleSaveLevel,linkId,defaultDrawTimer,slideBtnTop,
      touchEndAndSendOrder,updateControlState,updateOrderLine,socketServiceInstanct,
      currentBallInfo,updateCurrentBallInfo]);

    return (
        <>
            <div className="ball-div" style={{ zIndex: zIndex[ballIndex] || 1 }}>
                <div id={btnId} draggable="true" className="drag-btn" style={{marginTop:slideBtnTop+"px", transform: `translateX(${slideBtnTranslateX}px)`}}>
                    {/* <span className="ball-line"></span> */}
                    {
                      <div className={ `drag-btn-box ${ballImgClass} ${ballShadowClass}` } >
                        <img src={logoImg} alt=""/>
                        <div className={`toyname ${toyName.length >= 8 && 'minfont'} ${toyName.length >= 9 && 'line2'}`} >{toyName}</div>
                      </div>
                    }
                </div>
            </div>
        </>
    );
}

export default connect(state => ({
    token: state.token,
    orderLine: state.orderLine,
    controlState: state.controlState,
    currentBallInfo: state.currentBallInfo
}), {
  updateOrderLine,
  updateControlState,
  updateCurrentBallInfo,
})(SlideBtn);
