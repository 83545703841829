import React, {useState, useEffect} from 'react';
import remoteLogo from '@/assets/images/business/remote_logo.png';
import configValue from '@/utils/constant.js';
import { isAndroid, getUrlParam } from '@/utils/common.js';
import $api from '@/serve/api';
import { Base64 } from "js-base64";
import './index.scss';
import { useTranslation } from 'react-i18next'

const EvakeRemote = (props) => {
    const { t } = useTranslation();
    const {qrCode, linkId} = props;

    // const [wakeAppUrl, setWakeAppUrl] = useState("");
    const [wakeAppUrl, setWakeAppUrl] = useState("" + (isAndroid() ? configValue.evokeAndroidAppUrl : configValue.evokeIosAppUrl));

    useEffect(()=>{
        if(qrCode){
            const base64LinkId = Base64.encode(qrCode);
            setWakeAppUrl((pre)=>pre.replace("#parameter", base64LinkId));
        }
    }, [qrCode]);

    useEffect(()=>{
        const refererChannel = getUrlParam('referer_channel')
        if(refererChannel){
            setWakeAppUrl((pre)=>pre + '&referer_channel=' + refererChannel);
        }
    }, []);


    const [isShow, setIsShow] = useState(false);
    //关闭提示框
    const close = () => {
        setIsShow(false);
    }
    // //打开提示框
    // const open = () => {
    //     setIsShow(true);
    // }
    // const delayClose = () => {
    //     setTimeout(()=>{
    //         setIsShow(false);
    //     }, 3000);
    // }

    const handleClick = () => {
        // delayClose()
        window.location.href = wakeAppUrl;
        setTimeout(()=>{
            if(isAndroid() && document.visibilityState==="visible"){ // 落地页位于最前端，认为没有唤醒app
                window.location.href = configValue.downloadUrlAndroid
            }
        },3000);
        const text = {
            logNo: 'S0009',
            content: JSON.stringify({
                page_name: 'Control Link Open',
                event_id: 'controllinkjs_control_link_open_click',
                event_type: 'click',
                element_id: 'open_' + linkId,  //元素id，xxx为 lastActiveSessionId
            }),
            timeStamp: new Date().getTime(),
        }
        $api.logsNewV2(text)
    }
    return (
        <>
            <div className="evake-remote-top" onClick={handleClick}>
                <img src={remoteLogo} alt=""/>
                <div className="evoke-remote-text">
                    <div className='t1'>Lovense Remote</div>
                    <div className='t2'>{ t('des_remote_control_link_h5') }</div>
                </div>
                <div className='open'>Open</div>
            </div>
            {isShow && (
                <div className="Wake-app">
                    <div className="container">
                        <div className="mask"/>
                        <div className="main">
                            <div className="content">
                                <div className="close" onClick={close}/>
                                <h2 className="title">{t('open_remote_title')}</h2>
                                <div className="text">
                                    <div className="info">
                                        <span>1.</span><p>{t('open_remote_des1')}</p>
                                    </div>
                                    <div className="info">
                                        <span>2.</span><p>{t('open_remote_des2')}</p>
                                    </div>
                                    <div className="info">
                                        <span>3.</span><p>{t('open_remote_des3')}</p>
                                    </div>
                                </div>
                                <a href={wakeAppUrl} className="btn" onClick={handleClick}>
                                    {t('button_open_remote')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default EvakeRemote;
