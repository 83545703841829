const toysArr = {
  "ambi": "Ambi",
  "calor": "Calor",
  "diamo": "Diamo",
  "dolce": "Dolce",
  "domi": "Domi",
  "domi2": "Domi2",
  "edge": "Edge",
  "edge2": "Edge2",
  "ferri": "Ferri",
  "gush": "Gush",
  "hush": "Hush",
  "hush2": "Hush2",
  "hyphy": "Hyphy",
  "lush": "Lush",
  "lush2": "Lush2",
  "lush3": "Lush3",
  "max": "Max",
  "max2": "Max2",
  "mission": "Mission",
  "nora": "Nora",
  "osci": "Osci",
  "osci2": "Osci2",
  "osci3": "Osci3",
  "quake": "Quake",
  "xmachine": "XMachine",
  "exomoon": "Exomoon",
  "tenera": "Tenera",
  'flexer': "Flexer",
  'gemini': "Gemini",
  'gravity': "Gravity",
  'z10': "Z10",
  'ridge': "Ridge",
  'lapis': "Lapis",
  'h01': "H01",
  'solace': "Solace",
  "mini xmachine": "Mini XMachine",
  "solace pro": "Solace Pro",
  "c15": "C15",
  "mission2": "Mission2",
  "vulse": "Vulse",
};

export default toysArr;