import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateOrderLine } from '@/redux/action/order-line.js';
import { updateFromAppToyCommand } from '@/redux/action/from-app-toy-command.js';
import LdrBalls from './ldr/ldr-balls/index.js';
import LdsControl from './lds/index.js';
import './index.scss';
import { useTranslation } from 'react-i18next'
import i18next from "@/utils/i18n.js"

//控制状态
const imagesMap = {
  controlling: require("@/assets/images/business/sync_controlling.png").default,
  controlled: require("@/assets/images/business/sync_controlled.png").default,
  interactive: require("@/assets/images/business/sync_interactive.png").default
};

const SyncControl = (props)=>{
  const { t } = useTranslation();
  const { 
    linkId,isCreator,toysData,joinerToy,creatorToy,ldsJoinerToyList,ldsCreatorToyList,isEnd,ldsOrLdr,
    socketServiceInstanct,ldrControlType,ldsControlType,ldsJoinerToyLevel,ldsCreatorToyLevel,setLdsJoinerToyLevel,isSupportTouchPanel
  } = props;
  const liveOrSync = "sync";
  const getBallStyle = () => {
    let show = isCreator ? "block" : "none";
    if(ldsOrLdr === "ldr" && ldrControlType === 2){ //被控，不显示控制球
      show = "none";
    }
    return { 
      display: show
    }
  }
  const [txt, setTxt] = useState(i18next.t('sync_control_type_controlling'));
  useEffect(()=>{
    if(ldsOrLdr === "ldr"){
      if(ldrControlType === 1){
        setTxt(i18next.t('sync_control_type_controlling'));
      }else{
        setTxt(i18next.t('sync_control_type_controlled'));
      }
    }else if(ldsOrLdr === "lds"){
      if(ldsControlType === 0){
        setTxt(i18next.t('sync_control_type_interactive'));
      }else if(ldsControlType === 1){
        setTxt(i18next.t('sync_control_type_controlling'));
      }else if(ldsControlType === 2){
        setTxt(i18next.t('sync_control_type_controlled'));
      }
    }
  },[ldsOrLdr,ldrControlType,ldsControlType])

  return (
    <div className="sync-control">
      <div className="balls" style={getBallStyle()}>
        {
          ldsOrLdr === "ldr" ?
          <LdrBalls 
            linkId={linkId}
            toysData={toysData}
            joinerToy={joinerToy}
            creatorToy={creatorToy}
            isCreator={isCreator}
            isEnd={isEnd}
            liveOrSync={liveOrSync}
            socketServiceInstanct={socketServiceInstanct}>
          </LdrBalls>
          :
          <LdsControl 
            linkId={linkId}
            toysData={toysData}
            joinerToy={joinerToy}
            creatorToy={creatorToy}
            ldsJoinerToyList={ldsJoinerToyList}
            ldsCreatorToyList={ldsCreatorToyList}
            isCreator={isCreator}
            isEnd={isEnd}
            liveOrSync={liveOrSync}
            ldsControlType={ldsControlType}
            ldsJoinerToyLevel={ldsJoinerToyLevel}
            ldsCreatorToyLevel={ldsCreatorToyLevel}
            setLdsJoinerToyLevel={setLdsJoinerToyLevel}
            isSupportTouchPanel={isSupportTouchPanel}
            socketServiceInstanct={socketServiceInstanct}>
          </LdsControl>
        }
      </div>
      <div className="tip-area">
        <div className="msg1">{t('sync_control_text')}</div>
        <div className="con-dir">{txt}</div>
        <img src={imagesMap.controlling} style={{ display: ldsOrLdr === "ldr" && ldrControlType===1 ? 'block':'none' }} alt=""/>
        <img src={imagesMap.controlled} style={{ display: ldsOrLdr === "ldr" && ldrControlType===2 ? 'block':'none' }} alt=""/>
        <img src={imagesMap.controlling} style={{ display: ldsOrLdr === "lds" && ldsControlType===1 ? 'block':'none' }} alt=""/>
        <img src={imagesMap.controlled} style={{ display: ldsOrLdr === "lds" && ldsControlType===2 ? 'block':'none' }} alt=""/>
        <img src={imagesMap.interactive} style={{ display: ldsOrLdr === "lds" && ldsControlType===0 ? 'block':'none' }} alt=""/>
      </div>
    </div>
  )
}

export default connect(state => ({
  orderLine: state.orderLine,
  fromAppToyCommand: state.fromAppToyCommand
}), {
updateOrderLine,
updateFromAppToyCommand
})(SyncControl);
