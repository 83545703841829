import {CONTROL_STATE} from "../constant";
/*
    1.该文件是用于创建一个为Count组件服务的reducer，reducer的本质就是一个函数
    2.reducer函数会接到两个参数，分别为：之前的状态(preState)，动作对象(action)
*/

//玩具控制状态 0未开始，1控制中 2已失效

const controlState = (preState = {}, action) => {
    //从action对象中获取：type、data
    const {type, data} = action
    switch (type) {
        case CONTROL_STATE:
            return {...data}
        default:
            return preState
    }
}

export default controlState;
