
//connect app 使用wenview嵌套H5的controlLink功能

const postMessageToApp = {
  post(body){
    console.log('postMessageToApp:', body);
    if(window.controlink !== undefined) {  //安卓
      window.controlink.postMessage(body);
    }
    if(window.webkit !== undefined) {  //IOS
      if (window.webkit.messageHandlers.controlink !== undefined) {
        window.webkit.messageHandlers.controlink.postMessage(body);
      }
    }
  }
}
export default postMessageToApp;

// 通信内容的数据结构
// let body = {
//   "version": 1,
//   "linkId": "",
//   "event": "",
//   "message": "", //不同event对应的消息体字符串
// };

// event取值及解释：
// controllinkDidStart:  controlLink开始控制玩具
// onClickEndButton:     controlLink点击结束按钮
// controllinkDidEnd:    controlLink结束控制玩具
// toyCommand:           controlLink玩具指令

//注意：
//以上代码中，controlink、messageHandlers、postMessage，需与app开发者协定
//只有app支持了这些类或方法，被app嵌套的H5页面才能通过以上形式提交数据给app