import {CLOSE_OPERATE} from "../constant";

const closeOperateStatus = (preState = [], action) => {
    //从action对象中获取：type、data
    const {type, data} = action
    if (CLOSE_OPERATE === type) {
        return data
    }
    return preState || true
}

export default closeOperateStatus;
