// UncontrolledLottie.jsx
import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from './icon.json'

class UncontrolledLottie extends Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }

    return (
        <div className='voice-icon'>
            <Lottie options={defaultOptions} style={{pointerEvents: 'none'}}/>
        </div>
    )
  }
}

export default UncontrolledLottie
