
const SocketEvent = {
  //Socket消息事件
  ANON_YOU_MUST_REFRESH_CONTROL_LINK_TC: "anon_you_must_refresh_control_link_tc",  //通知浏览器,需要刷新control_link信息
  Q_END_CONTROL_LINK_TS: "q_end_control_link_ts", //加入者主动结束控制链接
  ANON_LINK_IS_END_TC: "anon_link_is_end_tc",  //通知浏览器,controllink已经结束了

  ANON_QUERY_CONTROL_INFO_TS: "anon_query_control_info_ts", //加入者查询当前control_link的基本信息
  ANON_QUERY_CONTROL_INFO_TC: "anon_query_control_info_tc", //回应加入者查询当前control_link的基本信息

  CON_QUERY_CONTROL_INFO_TS: "con_query_control_info_ts", //创建者查询当前control_link的基本信息
  CON_QUERY_CONTROL_INFO_TC: "con_query_control_info_tc", //回应创建者查询当前control_link的基本信息

  ANON_OPEN_CONTROL_PANEL_TS:'anon_open_control_panel_ts', //通知服务器,加入者已经打开了控制面板
  ANON_END_CONTROL_LINK_TS:'anon_end_control_link_ts', //加入者主动结束控制链接
  ANON_UTID_REFRESH_TC:'anon_utid_refresh_tc', //页面升级app刷新当前页面selfId

  Q_SEND_IM_MSG_TS: "q_send_im_msg_ts", //A 发消息给服务器B
  Q_ACK_SEND_IM_MSG_TC: "q_ack_send_im_msg_tc", //服务器回应A (checked)
  Q_YOU_HAVE_SOME_NEW_IM_MSG_TC: "q_you_have_some_new_im_msg_tc", //收到这个事件,说明有人给B发送消息了,B自己去拉一下消息吧。1.如果是一个全新的聊天,也就是APP本地的聊天列表没有B;那就去拉一下B的信息,把列表构造出来
  Q_GET_USER_NEW_MSG_LIST_TS: "q_get_user_new_msg_list_ts", //B向服务器去拉取新的消息
  Q_ACK_USER_NEW_MSG_LIST_TC: 'q_ack_user_new_msg_list_tc',  //服务器回应B拉取新的消息 (checked)
  Q_DELIVER_IM_MSG_TS:"q_deliver_im_msg_ts",  //标记一批消息为送达

  ANON_COMMAND_LINK_TS: 'anon_command_link_ts', //发送指令
  ANON_COMMAND_LINK_TC: "anon_command_link_tc", //接收指令
  WHICH_APP_PAGE_OPEN_NOW_TC: "which_app_page_open_now_tc", //服务端询问客户端现在打开了哪个页面
  APP_OPEN_THIS_PAGE_NOW_TS: "app_open_this_page_now_ts",   //客户端上报当前打开的页面
  QUERY_USER_ON_LINE_INFO_TS: "query_user_on_line_info_ts", //查询用户在线状态
  ACK_QUERY_USER_ON_LINE_INFO_TC: "ack_query_user_on_line_info_tc", //返回用户在线状态的查询结果
  ANON_NOTIFY_BREAK_CONTROL_TC: "anon_notify_break_control_tc", // PC端扫码失效（ "linkStatus":  1、已经有人使用 控制中    2、失效）
  Q_COMMAND_LINK_TC: "q_command_link_tc", //服务器把加入者转发过来的玩具指令(当前是创建者的时候使用)
  Q_YOU_MUST_REFRESH_CONTROL_LINK_TC: "q_you_must_refresh_control_link_tc", //开始控制玩具(倒计时开始)
  ANON_TID_REFRESH_TC: "anon_tid_refresh_tc", //服务端通知创建者当前加入者的socketId发生了变化
  Q_REFRESH_OCCUPY_COUNTDOWN_TC: "q_refresh_occupy_countdown_tc", //服务器通知客户端刷新占用倒计时提醒
  Q_CANCEL_OCCUPY_COUNTDOWN_TS: "q_cancel_occupy_countdown_ts", //创建者取消了占用倒计时提醒
  Q_JOINER_CANCEL_OCCUPY_COUNTDOWN_TS: "q_joiner_cancel_occupy_countdown_ts", // 加入者取消占用倒计时

  CL_CONTROL_PERMISSION_REQUEST_TS: 'cl_control_permission_request_ts', // 控制请求 - 客户端发出申请控制权限(或者取消前面发出的申请),数据带上请求的具体权限
  CL_CONTROL_PERMISSION_RESPONSE_TC: 'cl_control_permission_response_tc', // 控制请求 - 客户端接收控制权限的响应数据
}

export default SocketEvent;
