import React from 'react'
import './index.scss'
import {connect} from 'react-redux'
import { useRef, useEffect, useMemo } from "react"
import drawCurve from '@/utils/draw-line.js'

const LdrOrderLine = (props) => {
    let { orderLine } = props
    const boardCanvasV = useRef(null)
    const boardCanvasS = useRef(null)
    const boardCanvasR = useRef(null)
    const boardCanvasP = useRef(null)
    const boardCanvasF = useRef(null)
    const boardCanvasT = useRef(null)
    const boardCanvasAll = useMemo(() => {
      return {
        v: boardCanvasV,
        s: boardCanvasS,
        r: boardCanvasR,
        p: boardCanvasP,
        f: boardCanvasF,
        t: boardCanvasT
      }
    }, [])
    const lineColor = useMemo(() => {
      return {
        v: '#FF2D89',
        s: '#FF2D89',
        r: '#0C9EF2',
        p: '#DE1EDB',
        f: '#DE1EDB',
        t: '#DE1EDB'
      }
    }, [])
    let lineArr = [];
    const funArr = useMemo(() => {
      let arr = (orderLine[0] && orderLine[0].toyFun && orderLine[0].toyFun.split(',')) || []
      arr.reverse()
      return arr
    }, [orderLine])
    let str = <div className="line-item" key={"sync-line-area-1"}>
                {
                  funArr.map(order => {
                    return <canvas key={`sync-cvs-line${order}`} className="board-canvas" style={{ width: "100%" }} ref={boardCanvasAll[order]}></canvas>
                  })
                }
              </div>;
    lineArr.push(str);
    useEffect(() => {
      let clientWidth = document.body.clientWidth;
      if(clientWidth > 540){
        clientWidth = 540;
      }
      let maxPointNum = parseInt(clientWidth/5);
      const boardCanvas = (funArr[0] && boardCanvasAll[funArr[0]]) || null
      if(boardCanvas && boardCanvas.current){
        const { clientWidth: width, clientHeight: height } = boardCanvas.current;
        funArr.forEach(order => {
          let curBoardCanvas = boardCanvasAll[order]
          curBoardCanvas.current.width = width
          curBoardCanvas.current.height = height
        })

        for(let a=0; a<orderLine.length; a++){
          if(a === 0){
            let lineObj = orderLine[a];
            for (let key in lineObj.line) {
              let lineArr = lineObj.line[key]
              if (lineArr.length > 1) {
                if (lineArr.length > maxPointNum) { //截取最后maxPointNum个点画线(刚好一屏)
                  lineArr = lineArr.slice(lineArr.length - maxPointNum)
                }
                let pts = handleLineData(lineArr, width, height)
                const ctx = boardCanvasAll[key].current.getContext("2d")
                ctx.clearRect(0, 0, width, height)
                let lineWidth = 1.4
                let smoothLevel = 1
                let color = lineColor[key]
                drawCurve(ctx, pts, smoothLevel, color, lineWidth, true, false)
              }
            }
          }
        }
      }
    }, [boardCanvasAll, funArr, lineColor, orderLine]);

    return (
        <>
          <div className="order-lines">
          {
            lineArr
          }
          </div>
        </>
    );
}
//生成曲线x、y坐标
function handleLineData(lineArr, width, height){
  let xNum = parseFloat(width/height).toFixed(2)
  let arr = [];
  for(let i=0; i<lineArr.length; i++){
    let x = i*xNum;
    let y = 20 - (lineArr[i]*(xNum/2) - parseInt(height/(xNum/2.8)));
    arr.push(x, y);
  }
  return arr;
}

export default connect(state => ({orderLine:state.orderLine}), {})(LdrOrderLine);
