// UncontrolledLottie.jsx
import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from './white_receive_black.json'
import rightData from './dark_send_black.json'
import leftPng from './white_receive_black.png'
import rightPng from './dark_send_black.png'

class UncontrolledLottie extends Component {
    render() {
            const defaultOptions = {
                loop: true,
                autoplay: true,
                animationData: this.props.right? rightData : animationData,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                },
            }

        return (
            <div className='voice-icon'>
                {
                    this.props.on? 
                    (<Lottie options={defaultOptions} style={{pointerEvents: 'none',fontSize: 0}}/>):
                    (<img className='voice-icon' style={{float: 'left'}} src={this.props.right? rightPng : leftPng} alt=''/>)
                }
            </div>
        )
    }
}

export default UncontrolledLottie
