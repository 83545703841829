import React from 'react';
import './index.scss'
import { isPc } from "@/utils/common.js";
const pc = isPc()

const Dialog = (props) => {
    const {content, closeTips} = props;
    return (
        content && (<div className={pc?"pc-toast-box":"toast-box"}>
            <div className='content'>
                <div className='text-box'>{content}</div>
                <div onClick={closeTips} className='okbtn'>OK</div>
            </div>
        </div>)
    );

}

export default Dialog;
