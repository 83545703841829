import React, { useState, useRef, useEffect, useCallback } from 'react';
import f_logo_v from '@/assets/images/business/control_ball_samll/f_logo_v.png';
import f_logo_v2 from '@/assets/images/business/control_ball_samll/f_logo_v2.png';
import f_logo_v3 from '@/assets/images/business/control_ball_samll/f_logo_v3.png';
import f_logo_r from '@/assets/images/business/control_ball_samll/f_logo_r.png';
import f_logo_p from '@/assets/images/business/control_ball_samll/f_logo_p_2.png';
import f_logo_s from '@/assets/images/business/control_ball_samll/f_logo_s.png';
import f_logo_f from '@/assets/images/business/control_ball_samll/f_logo_f.png';
import f_logo_t from '@/assets/images/business/control_ball_samll/f_logo_t.png';
import f_logo_d from '@/assets/images/business/control_ball_samll/f_logo_d.png';
import f_logo_pos from '@/assets/images/business/control_ball_samll/f_logo_pos.png';
import f_logo_o from '@/assets/images/business/control_ball_samll/f_logo_o.png';
import './index.scss';
import { orderUtils } from '@/utils/order-utils.js';
import { updateOrderLine } from '@/redux/action/order-line.js';
import { updateControlState } from '@/redux/action/control-state.js';
import { updateCurrentBallInfo } from '@/redux/action/current-ball-info.js';
import { updateFromAppToyCommand } from '@/redux/action/from-app-toy-command.js';
import {connect} from 'react-redux';
import throttle from 'lodash.throttle';
import SocketEvent from '@/serve/socket-event';
import { v4 as uuidv4 } from 'uuid';

const SlideBtn = (props) => {
    let logoImg = f_logo_v;
    let btnId = "toy1-drag-v-btn-id";
    let {
      data,
      orderLine,updateOrderLine,
      controlState,updateControlState,
      currentBallInfo,updateCurrentBallInfo,
      touchEndAndSendOrder,
      fromAppToyCommand
    } = props;

    let { linkId,isEnd,orderType,socketServiceInstanct } = data;
    //根据玩具、马达，计算滑球按钮的domId
    switch (orderType) {
      case 'v1':
        logoImg = f_logo_v;
        btnId = "toy1-drag-v1-btn-id"
        break
      case 's':
        logoImg = f_logo_s;
        btnId = "toy1-drag-s-btn-id"
        break
      case 'v':
        logoImg = f_logo_v
        btnId = "toy1-drag-v-btn-id"
        break
      case 'v2':
        logoImg = f_logo_v2
        btnId = "toy1-drag-v2-btn-id"
        break
      case 'v3':
        logoImg = f_logo_v3
        btnId = "toy1-drag-v3-btn-id"
        break
      case 'r':
        logoImg = f_logo_r
        btnId = "toy1-drag-r-btn-id"
        break
      case 'p':
        logoImg = f_logo_p
        btnId = "toy1-drag-p-btn-id"
        break
      case 'f':
        logoImg = f_logo_f
        btnId = "toy1-drag-f-btn-id"
        break
      case 't':
        logoImg = f_logo_t
        btnId = "toy1-drag-t-btn-id"
        break
      case 'd':
        logoImg = f_logo_d
        btnId = "toy1-drag-d-btn-id"
        break
      case 'pos':
        logoImg = f_logo_pos
        btnId = "toy1-drag-pos-btn-id"
        break
      case 'o':
        logoImg = f_logo_o
        btnId = "toy1-drag-o-btn-id"
        break
      default:
        logoImg = f_logo_v
        btnId = "toy1-drag-v-btn-id"
        break
    }
    const [prevY, setPrevY] = useState(0);
    const [prevBtnTop, setPrevBtnTop] = useState(0);
    const [touchBtnId, setTouchBtnId] = useState("");
    let defaultTop = 154;
    const [slideBtnTop, setSlideBtnTop] = useState(154);
    const [drawTimer, setDrawTimer] = useState(null);
    const [ballImgClass, setBallImgClass] = useState(""); //滑球样式
    //防抖函数引用
    const throttleSaveLevel = useRef(throttle((value, orderLineTmp)=>{
      addPointToLine(value, orderLineTmp);
    }, 100)).current;
    //节流方法
    const addPointToLine = useCallback((value, orderLineTmp)=>{
      let level = orderUtils.heightToLevel(value, defaultTop, 0);
      setSlideBtnTop(value);
      let tmpArr = [...orderLineTmp];
      for(let i=0; i<tmpArr.length; i++){
        for (let key in tmpArr[i].line) {
          if (key === orderType) {
            tmpArr[i].line[key].push(level)
          } else {
            const length = tmpArr[i].line[key].length
            const n = tmpArr[i].line[key][length-1]
            tmpArr[i].line[key].push(n)
          }
        }
        updateOrderLine(tmpArr);
      }
    },[orderType,defaultTop,updateOrderLine]);

    //点击控制面板，根据点击位置改变选中的控制球的高度(Level)
    useEffect(()=>{
      let targetEl = document.getElementById("mobile-balls-area");
      const clickMobileBallsArea = (e) => {
        if(btnId === currentBallInfo.currentSelBallId){
          let clickTarget = (e.target.nodeName + "").toLowerCase();
          if(clickTarget === "div"){
            let height = e.offsetY - 20;
            if(height < 0){
              height = 0;
            }else if(height > defaultTop){
              height = defaultTop;
            }
            setSlideBtnTop(height);
            throttleSaveLevel(parseInt(height), orderLine);
          }
        }
      }
      if(targetEl){
        targetEl.addEventListener('click',clickMobileBallsArea, false);
      }
      //事件解绑
      return function(){
        if(targetEl){
          targetEl.removeEventListener('click',clickMobileBallsArea, false);
        }
      }
    }, [defaultTop,btnId,currentBallInfo,orderLine,throttleSaveLevel]);

    //加入者进入页面就画一根直线水平播放
    const [defaultDrawTimer, setDefaultDrawTimer] = useState(null);
    useEffect(()=>{
      let tm = setTimeout(()=>{
        if(isEnd && isEnd.end){
          clearTimeout(tm);
          setDefaultDrawTimer(null);
        }else{
          addPointToLine(parseInt(slideBtnTop), orderLine);
          setDefaultDrawTimer(tm);
        }
      },100);
      return function(){
        clearTimeout(tm);
        setDefaultDrawTimer(null);
      }
    },[isEnd,slideBtnTop,orderLine,addPointToLine]);

    //计算选择的滑球外发光样式
    useEffect(()=>{
      let classStr = "";
      if(btnId === currentBallInfo.currentSelBallId){
        if (['r'].includes(orderType)) {
          classStr = 'ball-img-blue'
        } else if (['p', 'f', 'v3'].includes(orderType)) {
          classStr = 'ball-img-purple'
        } else {
          classStr = 'ball-img-pink'
        }
      }
      setBallImgClass(classStr);
    },[btnId, currentBallInfo, orderType]);

    const [stopToys, setStopToys] = useState(false);
    const [lastAppToys, setLastAppToys] = useState(fromAppToyCommand);

    //根据来自connectApp的玩具指令画曲线
    useEffect(()=>{
      const changeObj2 = (o) =>{
        let newObj = {};
        let effective = ['t', 'f', 's', 'p', 'r', 'v', 'd', 'o', 'pos']
        effective.forEach(key => {
          if (+o[key] !== -1) {
            newObj[key] = o[key]
          }
        })
        return newObj;
      }
      if(fromAppToyCommand.toyCommandJson){
        let appToys = fromAppToyCommand.toyCommandJson; //{ v: 1, v1: -1, v2: -1, s: -1, p: 0, r: -1 }
        if(JSON.stringify(appToys) !== JSON.stringify(lastAppToys)){
          setDefaultDrawTimer(null);
          clearInterval(defaultDrawTimer);
          let res = changeObj2(appToys); //{f:0, s:5, p:0, r:0, v:1}
          Object.keys(res).forEach(function(orderType, index){
            let tmpArr = [...orderLine]; //[{toyFun:"v,r,p,s",line: {}},]
            for(let i=0; i<tmpArr.length; i++){
              let funcArr = (tmpArr[i].toyFun + "").split(",");
              let curLevel = parseInt(res[orderType])
              if (orderType === 'p') {
                if(curLevel === 0){
                  curLevel = 0
                }else if(curLevel === 1){
                  curLevel = 6
                }else if(curLevel === 2){
                  curLevel = 13
                }else{
                  curLevel = 20
                }
              }
              if(funcArr.indexOf(orderType) >= 0){
                tmpArr[i].line[orderType].push(curLevel)
                let newTop = orderUtils.levelToHeight(curLevel, 154, 0)
                setSlideBtnTop(154 - newTop); //控制曲线保持高度
              }
              updateOrderLine(tmpArr);
            }
          });
          setLastAppToys(appToys);
        }
      }
    },[defaultDrawTimer,fromAppToyCommand,orderLine,updateOrderLine,orderType,lastAppToys]);


    useEffect(()=>{
      //触碰滑动球则开始控制
      const touchStartEvent = (e)=>{
        let eId = e.currentTarget.id;
        if(eId){
          clearInterval(drawTimer);
          setDrawTimer(null);
          if(controlState.state === 0){
            updateControlState({"state":1});
            //查询controlLink信息，同步数据
            if(socketServiceInstanct){
              let json1 = { ackId:uuidv4(), "linkId":linkId };
              setTimeout(()=>{
                socketServiceInstanct.socketEmitMsg(SocketEvent.ANON_QUERY_CONTROL_INFO_TS, json1);
              },200);
            }
          }
          setTouchBtnId(eId);
          setPrevY(e.targetTouches[0].screenY);
          let tEl = document.getElementById(eId);
          if(tEl){
            setPrevBtnTop(tEl.offsetTop - 60);
          }
        }
        updateCurrentBallInfo({...currentBallInfo, "currentSelBallId": btnId});
      }
      //滑动转换为指令等级
      const touchMoveEvent = (e)=>{
        e.stopPropagation();
        let clientY = e.targetTouches[0].screenY;
        let changeY = prevY - clientY - 50;
        let newTop = prevBtnTop - changeY;
        if(newTop<=0){
          newTop = 0;
        }else if(newTop >= defaultTop){
          newTop = defaultTop;
        }
        setDefaultDrawTimer(null);
        clearInterval(defaultDrawTimer);
        if(newTop === defaultTop){ //停止指令不能被节流方法过滤掉
          if(!stopToys){
            setStopToys(true);
            addPointToLine(parseInt(defaultTop), orderLine);
            let tm = setTimeout(()=>{
              addPointToLine(parseInt(defaultTop), orderLine);
              setStopToys(false);
              clearTimeout(tm);
            },100);
          }
        }else{
          setSlideBtnTop(newTop);
          throttleSaveLevel(parseInt(newTop), orderLine);
        }
      };
      //手指离开控制球，需开启定时器自动补点画线
      const touchEndEvent = ()=>{
        clearInterval(drawTimer);
        setDrawTimer(null);
        let timer = null;
        if(controlState.state !== 2){
          timer = setInterval(()=>{
            let tmpArr = [...orderLine];
            for(let i=0; i<tmpArr.length; i++){
              for (let key in tmpArr[i].line) {
                const length = tmpArr[i].line[key].length
                const n = tmpArr[i].line[key][length-1]
                tmpArr[i].line[key].push(n)
              }
            }
            updateOrderLine(tmpArr);
          }, 100);
          setDrawTimer(timer);
        }else{
          //结束控制
          clearInterval(timer);
          setDrawTimer(null);
          socketServiceInstanct.disconnect();
        }
        //为避免最后滑动的指令被防抖过滤掉
        //这里在手指touchend的时候不经过防抖方法补发最后一个指令
        touchEndAndSendOrder();
      }
      //事件绑定
      if(btnId){
        let element = document.getElementById(btnId);
        if(element){
          element.addEventListener('touchstart',touchStartEvent, false);
          element.addEventListener('touchmove',touchMoveEvent, false);
          element.addEventListener('touchend',touchEndEvent, false);
        }
      }
      //事件和定时器 解绑
      return function(){
        if(btnId){
          let element = document.getElementById(btnId);
          if(element){
            element.removeEventListener('touchstart',touchStartEvent, false);
            element.removeEventListener('touchmove',touchMoveEvent, false);
            element.removeEventListener('touchend',touchEndEvent, false);
          }
        }
        clearInterval(drawTimer);
        setDrawTimer(null);
      }

    },[btnId,touchBtnId,addPointToLine,controlState,defaultTop,stopToys,
      drawTimer,orderLine,prevBtnTop,prevY,throttleSaveLevel,linkId,defaultDrawTimer,slideBtnTop,
      touchEndAndSendOrder,updateControlState,updateOrderLine,socketServiceInstanct,
      currentBallInfo,updateCurrentBallInfo]);

    return (
        <>
            <div className="ball-div">
                <div id={btnId} draggable="true" className="drag-btn" style={{marginTop:slideBtnTop+"px"}}>
                    <span className="ball-line"></span>
                    {
                      <img className={ballImgClass} src={logoImg} alt=""/>
                    }
                </div>
            </div>
        </>
    );
}

export default connect(state => ({
    token: state.token,
    orderLine: state.orderLine,
    controlState: state.controlState,
    currentBallInfo: state.currentBallInfo,
    fromAppToyCommand: state.fromAppToyCommand
}), {
  updateOrderLine,
  updateControlState,
  updateCurrentBallInfo,
  updateFromAppToyCommand
})(SlideBtn);
