import React from 'react';
import './index.scss'
import { LoadingOutlined } from '@ant-design/icons';

const Loading = (props) => {
    return (
        <div className='loading-box'>
            <div className='content'>
                <LoadingOutlined />
            </div>
        </div>
    );
}

export default Loading;
