import {CHAT_LIST} from "../constant";

const chatList = (preState = [], action) => {
    //从action对象中获取：type、data
    const {type, data} = action
    switch (type) {
        case CHAT_LIST: //添加一条聊天记录
            let repeatArr = []
            let nowState = JSON.parse(JSON.stringify(data))
            if(preState.length){
                data.forEach((row, i) => {
                    let key = 'ackId'
                    if(!row[key]) key = 'msgId'
                    const num = preState.map(item => item[key]).indexOf(row[key])
                    if(num > -1) {
                        repeatArr.push(i)
                        preState.splice(num, 1, row)
                    }
                })
            }
            if(repeatArr.length){
                repeatArr.forEach(row => {
                    nowState[row] = null
                })
                nowState = nowState.filter(item => item)
            }
            return [...preState, ...nowState]
        default:
            return preState
    }
}

export default chatList;
