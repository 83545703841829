import React, { useEffect, useState, useRef } from 'react';
import './index.scss';
import logo from '@/assets/images/business/logo.png';
import {connect} from 'react-redux';
import {formatSeconds} from "@/utils/common.js";
import SocketEvent from '@/serve/socket-event';
import { updateControlState } from '@/redux/action/control-state.js';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next'
const pathData = {
    development: '',
    test: '/c2',
    production: '/v2',
}

const Header = (props) => {
    const { t } = useTranslation();
    const {
        linkId,
        toysData,
        linkInfo,
        controlState,
        controlPermission,
        updateControlState,
        socketServiceInstanct,
        isEnd,
        setIsEnd
    } = props;
    const [countdownTxt, setCountdownTxt] = useState("");
    const timeOut = useRef(null);
    const [count, setCount] = useState(0);

    useEffect(()=>{
      if(isEnd && isEnd.end) clearTimeout(timeOut.current)
    }, [isEnd])

    const setTimeTxt = (t)=>{
        let tmpTime = formatSeconds(t);
        setCountdownTxt(tmpTime);
    }

    useEffect(()=>{
        setTimeTxt(count);
    }, [count])

    useEffect(()=>{
        let timer = null;
        if(linkInfo){
            let lctTime = linkInfo.leftControlTime;
            if(linkInfo.isStart && !timer){ //controlLink已经处于控制中状态，重新刷新页面进来
                if(linkInfo.expires < 0){
                    let sec = lctTime-1;
                    if(sec<0){
                        sec=0;
                    }
                    setCount(sec);
                }else{
                    setCount(lctTime+1);
                }
            }else{  //controlLink处于未开始控制 重新刷新页面进来
                if(linkInfo.expires < 0){
                    setCount(0);
                    setCountdownTxt("00:00");
                }else{
                    setCount(linkInfo.expires);
                }
            }
            clearInterval(timer);
            if(controlState && controlState.state === 1){
                if(linkInfo.expires < 0){ //控制不受时间限制
                    setCount(pre=>pre+1)
                    timer = setInterval(()=>{
                        setCount(pre=>pre+1)
                    }, 1000);
                }else{
                    setCount(pre=>pre-1)
                    timer = setInterval(()=>{
                        if(lctTime - 1 <= 0){  //显示过期页面
                            //为确保玩具停止震动，加推停止指令
                            if(toysData){
                                let orderBody = {};
                                for(let i=0; i<toysData.length; i++){
                                    let toyId = toysData[i].id;
                                    orderBody[toyId] = { "v":0, "v1":0, "v2":0, "v3":0, "s":0, "p":0, "r":0, "f":0, "t":0, "d": 0, "o": 0, "pos": 0 };
                                }
                                let cate = { "version": 5, "cate": "id", "id": orderBody };
                                let order = {
                                    "ackId": uuidv4(),
                                    "toyCommandJson": JSON.stringify(cate),
                                    "linkId": linkId,
                                    "userTouch": true
                                }
                                if(socketServiceInstanct){
                                    socketServiceInstanct.socketEmitMsg(SocketEvent.ANON_COMMAND_LINK_TS, order);
                                }
                                // updateControlState({"state":2});
                                clearInterval(timer);
                                // 三秒后没收到服务器响应则跳转默认结束页面
                                timeOut.current = setTimeout(() => {
                                  updateControlState({ state: 2 })
                                  setIsEnd({end: true})
                                }, 3000)
                            }
                        }
                        setCount(pre=>pre-1)
                    }, 1000);
                }
            }
        }

        return function(){
            if(timer){
                clearInterval(timer);
            }
        }
    },[linkId,toysData,linkInfo,controlState,socketServiceInstanct,updateControlState,setIsEnd]);

    // 是否显示结束弹框
    let show = false;
    if(controlState && controlState.state === 2){
        show = true;
    }
    const [isShow, setIsShow] = React.useState(show);
    // 结束聊天窗口
    const endSocket = () => {
        if(toysData){
            let orderBody = {};
            for(let i=0; i<toysData.length; i++){
                let toyId = toysData[i].id;
                orderBody[toyId] = { "v":0, "v1":0, "v2":0, "v3":0, "s2": 0, "p":0, "r":0, "f": 0, "t": 0, "d": 0, "o": 0, "pos": 0 };
            }
            let cate = { "version": 5, "cate": "id", "id": orderBody };
            let order = {
                "toyCommandJson": JSON.stringify(cate),
                "linkId": linkId,
                "userTouch": true
            }
            if(socketServiceInstanct){
                socketServiceInstanct.socketEmitMsg(SocketEvent.ANON_COMMAND_LINK_TS, order);
            }
            updateControlState({"state":2});
        }
        props.endSocket();
    }
    const handleClickEnd = () => {
        setIsShow(true);
    }
    // 关闭结束弹框
    const close = () => {
        setIsShow(false);
    }
    // 跳转到官网
    const jumpWebsite = () => {
        window.open(
            "https://www.lovense.com/",
            "_blank"
        );
    }

    return (
        <>
        <div className="header flex acenter">
            <div className="left title" onClick={jumpWebsite}>
                <img src={logo} alt="" />
            </div>
            <div className="right flex acenter">
                <div className="report" onClick={()=>{ window.open(`${window.location.origin}${pathData[process.env.REACT_APP_ENV]}/report?linkId=${linkId}`, "_blank") }}>
                    <i/>
                </div>
                {
                    (controlPermission.joinerHasLiveControlPermission || controlPermission.openControlPermission === false) && (
                        <div className="countdown flex column acenter">
                            <i onClick={ handleClickEnd }/>
                            <div>{ countdownTxt || '' }</div>
                        </div>
                    )
                }
            </div>
        </div>
        {/*结束聊天弹框 start*/}
        {
            isShow && (
                <div className="end-socket">
                    <div className="main">
                        <div className="mask-layer" onClick={close}/>
                        <div className="container">
                            <div className="content">
                                <p>{t('notification_end_control_link')}</p>
                            </div>
                            <div className="btn">
                                <span className="cancel" onClick={close}>{t('button_cancel')}</span>
                                <span className="end" onClick={endSocket}>{t('button_end')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        {/*结束聊天弹框 start*/}
        </>
    );
}

export default connect(state => ({
    controlState: state.controlState,
    controlPermission: state.controlPermission,
}), {updateControlState})(Header);
