import { CURRENT_SEL_BALL_ID } from "../constant";
const currentBallInfo = (preState = {}, action) => {
    const {type, data} = action
    switch (type) {
        case CURRENT_SEL_BALL_ID:
            return {...data}
        default:
            return preState
    }
}
export default currentBallInfo;
