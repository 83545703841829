import "./index.scss";
import QRcode from "qrcode";
import prevImg from "@/assets/images/business/scan_preview.png";
import closeImg from "@/assets/images/business/pop_close.png";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
const QrCode = ({cancelEvent,data}) => {

  const { t } = useTranslation();
  const [qrcodeUrl, setQrcodeUrl] = useState(null);
  useEffect(()=>{
    if(data){
      QRcode.toDataURL(data, {
        type: "image/png", //类型
        quality: 0.5, //图片质量A Number between 0 and 1
        width: 130,   //高度
        height: 130,  //宽度
        errorCorrectionLevel: "L", //容错率
        margin: 2,   //外边距
        color: {
          dark: "#000", //前景色
          light: "#fff" //背景色
        }
      }).then(imgUrl => {
        setQrcodeUrl(imgUrl);
      })
    }
  },[data])

  return (
    <>
      <div className="pc-qrcode">
        <div className="close-area" onClick={cancelEvent}>
          <img src={closeImg} alt=""/>
        </div>
        <div className="qrcode-box">
          <div className="cwlr-txt">{t('open_remote_title')}</div>
          <div className="gray-box">
            <div className="box-item">
              <div className="item-num">1:</div>
              <div className="item-text">{t('open_remote_des1')}</div>
            </div>
            <div className="box-item">
              <div className="item-num">2:</div>
              <div className="item-text">{t('open_remote_des2')}</div>
            </div>
            <div className="box-item">
              <div className="item-num">3:</div>
              <div className="item-text">{t('open_remote_des3')}</div>
            </div>
          </div>
          <div className="scan-title">{t('open_remote_scan_qr')}</div>
          <div className="scan-tip">{t('open_remote_page_expire')}</div>
          <div className="qr-code-img">
            <img src={qrcodeUrl} alt=""/>
          </div>
          <img src={prevImg} alt="" className="scan-preview-img"/>
        </div>
      </div>
    </>
  )
}

export default QrCode;