import CryptoJS from 'crypto-js';

// 对聊天发送和接收的信息进行加密和解密
let aesUtils = {};

aesUtils.aesEncryptXy = function (word) {
    let {x: dynamicKey, y: dynamicValue} = JSON.parse(localStorage.getItem('aesEncryp')) || {}
    let key = CryptoJS.enc.Utf8.parse(dynamicKey);
    let iv = CryptoJS.enc.Utf8.parse(dynamicValue);
    // let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(word, key, {iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
}
aesUtils.aesDecryptXy = function (word) {
    let {x: dynamicKey, y: dynamicValue} = JSON.parse(localStorage.getItem('aesEncryp')) || {}
    let key = CryptoJS.enc.Utf8.parse(dynamicKey);
    let iv = CryptoJS.enc.Utf8.parse(dynamicValue);
    // let iv = key
    let decrypt = CryptoJS.AES.decrypt(word, key, {iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7});
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
export default aesUtils
