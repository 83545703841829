
import "./index.scss";
import i18next from "@/utils/i18n.js"
const EndDialog = ({cancelEvent, confirmEvent})=>{

  return (
    <>
      <div className="end-Dialog">
        <div className="text">{i18next.t('notification_end_control_link')}</div>
        <div className="btn cancel-btn" onClick={cancelEvent}>{i18next.t('button_cancel')}</div>
        <div className="btn end-btn" onClick={confirmEvent}>{i18next.t('button_end')}</div>
      </div>
    </>
  )
}

export default EndDialog;