import io from "socket.io-client";
import SocketEvent from "./socket-event";
const EventEmitter = require('events').EventEmitter; //事件转发
//注意：
//socket通道与video标签是一对一的关系
//当用户切换视频时，video标签改变，也就是pattern改变了
//此时需新增一个socket通道
//判断用户是否切换视频，只需要判断当前用户操作的videoId是否变了

class SocketService extends EventEmitter {
    constructor() {
        super();
        this.socketIo = null;
        this.connectStatus = 0;
        this.videoId = "";
    };
    initSocket(wsServerUrl, socketIoPath) {
        const socketIo = io(wsServerUrl, {
            path: socketIoPath,
            transports: ['websocket'] //直连模式
        });
        this.socketIo = socketIo;
        socketIo.on("connect", socket => {
            this.connectStatus = 1;
            // 你需要刷新control_link信息
            socketIo.on(SocketEvent.ANON_OPEN_CONTROL_PANEL_TS, (data) => {
                this.emit(SocketEvent.ANON_OPEN_CONTROL_PANEL_TS, data);
            })
            // 你需要刷新control_link信息
            socketIo.on(SocketEvent.ANON_YOU_MUST_REFRESH_CONTROL_LINK_TC, (data) => {
                this.emit(SocketEvent.ANON_YOU_MUST_REFRESH_CONTROL_LINK_TC, data);
            })
            // 主动结束控制链接
            socketIo.on(SocketEvent.Q_END_CONTROL_LINK_TS, (data) => {
                this.emit(SocketEvent.Q_END_CONTROL_LINK_TS, data);
            })
            // 通知 控制链接的另一方 结束
            socketIo.on(SocketEvent.ANON_LINK_IS_END_TC, (data) => {
                this.emit(SocketEvent.ANON_LINK_IS_END_TC, data);
            })
            // 查询当前control_link 基本信息
            socketIo.on(SocketEvent.ANON_QUERY_CONTROL_INFO_TS, (data) => {
                this.emit(SocketEvent.ANON_QUERY_CONTROL_INFO_TS, data);
            })
            // 回应 查询当前control_link 基本信息
            socketIo.on(SocketEvent.ANON_QUERY_CONTROL_INFO_TC, (data) => {
                this.emit(SocketEvent.ANON_QUERY_CONTROL_INFO_TC, data);
            })
            // 回应：服务器回复-是否正常使用（应用场景多人扫的情况，与/api/remote/controllink/info接口都会检测）
            socketIo.on(SocketEvent.Q_ACK_SEND_IM_MSG_TC, (data) => {
                this.emit(SocketEvent.Q_ACK_SEND_IM_MSG_TC, data);
            })

            // 服务端告诉网页需要拉取新消息
            socketIo.on(SocketEvent.Q_YOU_HAVE_SOME_NEW_IM_MSG_TC, (data) => {
                this.emit(SocketEvent.Q_YOU_HAVE_SOME_NEW_IM_MSG_TC, data);
            })
            // 网页向服务器拉取新消息
            socketIo.on(SocketEvent.Q_GET_USER_NEW_MSG_LIST_TS, (data) => {
                this.emit(SocketEvent.Q_GET_USER_NEW_MSG_LIST_TS, data);
            })
            // 服务器返回给网页最新的未读消息
            socketIo.on(SocketEvent.Q_ACK_USER_NEW_MSG_LIST_TC, (data) => {
                this.emit(SocketEvent.Q_ACK_USER_NEW_MSG_LIST_TC, data);
            })
            // 上报已读消息
            socketIo.on(SocketEvent.Q_DELIVER_IM_MSG_TS, (data) => {
                this.emit(SocketEvent.Q_DELIVER_IM_MSG_TS, data);
            })

            // 加入者主动结束控制链接
            socketIo.on(SocketEvent.ANON_END_CONTROL_LINK_TS, (data) => {
                this.emit(SocketEvent.ANON_END_CONTROL_LINK_TS, data);
            })
            // 页面升级app刷新当前页面selfId
            socketIo.on(SocketEvent.ANON_UTID_REFRESH_TC, (data) => {
                this.emit(SocketEvent.ANON_UTID_REFRESH_TC, data);
            })
            // 聊天对接 -----start
            socketIo.on(SocketEvent.Q_SEND_IM_MSG_TS, (data) => {
                this.emit(SocketEvent.Q_SEND_IM_MSG_TS, data);
            })
            //发送指令
            socketIo.on(SocketEvent.ANON_COMMAND_LINK_TS, (data) => {
                this.emit(SocketEvent.ANON_COMMAND_LINK_TS, data);
            })
            // 聊天对接 -----end

            // 占用问题对接 -----start
            // 服务器通知客户端刷新自动失效倒计时提醒
            socketIo.on(SocketEvent.Q_REFRESH_OCCUPY_COUNTDOWN_TC, (data) => {
                this.emit(SocketEvent.Q_REFRESH_OCCUPY_COUNTDOWN_TC, data);
            })
            // 创建者取消了自动失效倒计时提醒
            socketIo.on(SocketEvent.Q_CANCEL_OCCUPY_COUNTDOWN_TS, (data) => {
                this.emit(SocketEvent.Q_CANCEL_OCCUPY_COUNTDOWN_TS, data);
            })
            // 加入者取消占用倒计时
            socketIo.on(SocketEvent.Q_JOINER_CANCEL_OCCUPY_COUNTDOWN_TS, (data) => {
                this.emit(SocketEvent.Q_JOINER_CANCEL_OCCUPY_COUNTDOWN_TS, data);
            })

            // 占用问题对接 -----end

            //服务器询问客户端现在打开了哪个页面
            socketIo.on(SocketEvent.WHICH_APP_PAGE_OPEN_NOW_TC, (data) => {
                let res = JSON.parse(data);
                let json = { "pepsiId": res.pepsiId, "webPage": "control_link" };
                let urlInfo = localStorage.getItem("urlInfo");
                if(urlInfo){
                    let isCreator = JSON.parse(urlInfo).isCreator;
                    if(isCreator){
                        json = { "pepsiId": res.pepsiId, "appPage": "control_link" };
                    }
                }
                this.socketEmitMsg(SocketEvent.APP_OPEN_THIS_PAGE_NOW_TS, json);
            });
            //app询问网页端是否在线,服务端返回结果
            socketIo.on(SocketEvent.ACK_QUERY_USER_ON_LINE_INFO_TC, (data) => {
                this.emit(SocketEvent.ACK_QUERY_USER_ON_LINE_INFO_TC, data);
            });
            //PC端扫码失效
            socketIo.on(SocketEvent.ANON_NOTIFY_BREAK_CONTROL_TC, (data) => {
                this.emit(SocketEvent.ANON_NOTIFY_BREAK_CONTROL_TC, data);
            });
            //服务端通知创建者当前加入者的socketId发生了变化
            socketIo.on(SocketEvent.ANON_TID_REFRESH_TC, (data) => {
                this.emit(SocketEvent.ANON_TID_REFRESH_TC, data);
            });
            //创建者查询controlLink基本信息
            socketIo.on(SocketEvent.CON_QUERY_CONTROL_INFO_TC, (data) => {
                this.emit(SocketEvent.CON_QUERY_CONTROL_INFO_TC, data);
            });
            //转发玩具指令
            socketIo.on(SocketEvent.Q_COMMAND_LINK_TC, (data) => {
                this.emit(SocketEvent.Q_COMMAND_LINK_TC, data);
            });
            //开始控制玩具
            socketIo.on(SocketEvent.Q_YOU_MUST_REFRESH_CONTROL_LINK_TC, (data) => {
                this.emit(SocketEvent.Q_YOU_MUST_REFRESH_CONTROL_LINK_TC, data);
            });
            // 客户端发出申请控制权限(或者取消前面发出的申请),数据带上请求的具体权限
            socketIo.on(SocketEvent.CL_CONTROL_PERMISSION_REQUEST_TS, (data) => {
                this.emit(SocketEvent.CL_CONTROL_PERMISSION_REQUEST_TS, data);
            });
            // 客户端发出申请控制权限(或者取消前面发出的申请),数据带上请求的具体权限
            socketIo.on(SocketEvent.CL_CONTROL_PERMISSION_REQUEST_TS, (data) => {
                this.emit(SocketEvent.CL_CONTROL_PERMISSION_REQUEST_TS, data);
            });
            // 客户端接收控制权限的响应数据
            socketIo.on(SocketEvent.CL_CONTROL_PERMISSION_RESPONSE_TC, (data) => {
                this.emit(SocketEvent.CL_CONTROL_PERMISSION_RESPONSE_TC, data);
            });
        });
        socketIo.on('disconnect', () => {
            this.emit(SocketEvent.SocketDisconnectDTO, {});
        });
    }
    disconnect() {
        if(this.socketIo){
            this.socketIo.disconnect();
        }
    }
    socketEmitMsg(type, data) {
        if (this.socketIo && (this.connectStatus === 1 || this.connectStatus === 0)) {
            this.socketIo.emit(type, data);
        }
    }
    //type:0表示仅查询，1表示查询后进行后续业务操作
    getSyncStatus(type = 0) {
        // if(this.socketIo && this.connectStatus === 1) {
        if (this.socketIo && (this.connectStatus === 1 || this.connectStatus === 0)) {
            this.socketIo.emit(SocketEvent.GetSyncStatusDTO, (data) => {
                if (type === 1) {
                    let obj = {...data, type: 1}
                    this.emit(SocketEvent.GetSyncStatusDTO, obj);
                } else {
                    this.emit(SocketEvent.GetSyncStatusDTO, data);
                }
            });
        }
    }
    getListenerCount(eventName) {
        return this.listenerCount(eventName)
    }
    //普通消息事件(非socket)
    setPlaySpeed(speed) {
        this.emit(SocketEvent.SetPlaySpeedDTO, {speed: speed});
    }
}
export {SocketService};
