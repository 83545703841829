import React, { useEffect } from "react";
import "@/utils/i18n.js";
import MobilePage from './views/mobile/index.js';
import PcPage from './views/pc/index.js';
import {isPc,getUrlParam} from "@/utils/common.js";
import $api from '@/serve/api'


function Editor(props) {
    let paramsObj = props.match.params;
    let code = paramsObj.code;
    let linkId = paramsObj.linkId;
    let urlInfo = {code,linkId:"",isCreator:false};
    if(linkId && linkId !== "c2" && linkId !== "v2"){ //路由有linkId代表当前url是创建者的controlLink
        urlInfo = {code,linkId,isCreator:true};
    }
    let device = getUrlParam('device', props.location.search);
    let pc = isPc();
    if(device === "android" || device === "ios"){
        pc = false;
        urlInfo = { ...urlInfo,device }
    }
    let isPad = /iPad|iPod/i.test(navigator.userAgent);
    if(device === "pad"){
        isPad = true;
        pc = false;
        urlInfo = { ...urlInfo,device }
    }
    let isSafari = /Safari/i.test(navigator.userAgent);
    let hasTouchFunc = 'ontouchend' in document;
    if(isSafari && hasTouchFunc){
        isPad = true;
    }
    if(isPad){
        pc = false;
    }

    let newObj = { ...urlInfo,device }
    localStorage.setItem("urlInfo", JSON.stringify(newObj));

    useEffect(() => {
        $api.getCommonBaseData().then(res => {
            if (res.result) {
                localStorage.setItem("countryCode", res.data.countryCode || '');
            }
        })
    }, []);

    return (
        <>
            {
                pc ? <PcPage urlInfo={urlInfo}/> : <MobilePage urlInfo={urlInfo}/>
            }
        </>
    );
}

export default Editor;
