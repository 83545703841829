import React, { useEffect } from 'react';
import remoteLogo from '@/assets/images/business/remote_logo.png';
import download_google from '@/assets/images/business/download/download_google.png';
import download_appstore from '@/assets/images/business/download/download_appstore.png';
import download_windows from '@/assets/images/business/download/download_windows.png';
import download_mac from '@/assets/images/business/download/download_mac.png';
import $api from '@/serve/api'
import './index.scss';

const urls = {
    google: 'https://play.google.com/store/apps/details?id=com.lovense.wear',
    appstore: 'https://apps.apple.com/us/app/lovense-remote/id1027312824',
    windows: 'https://www.lovense.com/files/apps/remote/remote.exe',
    mac: 'https://www.lovense.com/files/apps/remote/remote.dmg',
}

const DownloadRemote = (props) => {

    useEffect(() => {
        $api.getRemoteDownloadUrl().then(res => {
            if (res.result && res.data?.list?.length) {
                res.data.list.forEach(item=>{
                    if(item.platform && item.url){
                        urls[item.platform] = item.url
                    }
                })
            }
        })
    }, [])

    return (
        <div className="download-remote-top">
            <div className="download-remote-content flex between">
                <div className="download-remote-left flex acenter">
                    <img  className="logo" src={remoteLogo} alt="Lovense Remote"/>
                    <div>Lovense Remote</div>
                </div>
                <div className="download-remote-right flex acenter">
                    <a href={ urls.google } target="_blank" rel="noreferrer"><img src={download_google} alt="Google Play"/></a>
                    <a href={ urls.appstore } target="_blank" rel="noreferrer"><img src={download_appstore} alt="App Store"/></a>
                    <a href={ urls.windows } target="_blank" rel="noreferrer"><img src={download_windows} alt="Windows"/></a>
                    <a href={ urls.mac } target="_blank" rel="noreferrer"><img src={download_mac} alt="Mac"/></a>
                </div>
            </div>
        </div>
    );
}

export default DownloadRemote;
