import React, { useEffect,useRef,useCallback,useState } from 'react';
import SlideBtn from './slide-btn';
import './index.scss';
import { updateOrderLine } from '@/redux/action/order-line.js';
import { updateCurrentBallInfo } from '@/redux/action/current-ball-info.js';
import {connect} from 'react-redux';
import SocketEvent from '@/serve/socket-event';
import toysArr from "@/utils/toy.js";

const SlideBalls = (props) => {
    let {
        linkId,
        isEnd,
        toysData,
        socketServiceInstanct,
        orderLine,
        controlState,
        updateOrderLine,
        updateCurrentBallInfo
     } = props;
    const [htmlArr, setHtmlArr] = useState([]);
    const [zIndex, setZIndex] = useState([])

    const ballCount = (toysData || []).reduce((count, toy)=>{
        let funcArr = toy.toyFun.split(",");
        count += funcArr.length
        return count
    }, 0)
    const ballRadius = ballCount <= 2 ? 30 : 24 // 球半径

    useEffect(()=>{
        let tmpArr = [];
        if(toysData && toysData.length){
            //构造控制球按钮的DOM结构
            let index = 0;
            for(let t=0; t<toysData.length; t++){
                let toy = toysData[t];
                let toyTypeLowerCase = toysArr[(toy.type+"").toLowerCase()];
                let toyName = (toyTypeLowerCase ? toyTypeLowerCase : toy.type) + (toy.version ? (" " + toy.version) : "")
                let funcArr = toy.toyFun.split(",");
                for(let i=0; i<funcArr.length; i++){
                    const order = funcArr[i]
                    let item = (
                        <SlideBtn
                            key={toy.id + order}
                            data={{
                                linkId,
                                orderType: order,
                                toyName: toyName,
                                ballCount: ballCount,
                                ballIndex: index,
                                ballRadius,
                                zIndex: zIndex,
                                setZIndex: setZIndex,
                                toyId: toy.id,
                                isEnd,
                                socketServiceInstanct,
                            }}
                        ></SlideBtn>
                    )
                    tmpArr.push(item);
                    index++;
                }
            }
            setHtmlArr(tmpArr);
        }
    }, [isEnd,toysData,linkId,socketServiceInstanct, zIndex, ballCount, ballRadius]);

    //默认选中第一个玩具的第一个按钮
    useEffect(()=>{
        if (toysData && toysData.length) {
            let toyId = 'toy1-drag-v-btn-id'
            let toy = toysData[0]
            if (toy.toyFun.includes('v1')) {
                toyId = 'toy1-drag-v1-btn-id'
            } else if (toy.toyFun.includes('s')) {
                toyId = 'toy1-drag-s-btn-id'
            } else if (toy.toyFun.includes('v3')) {
                toyId = 'toy1-drag-v3-btn-id'
            }
            updateCurrentBallInfo({"currentSelBallId": toyId})
        }
    },[updateCurrentBallInfo, toysData])

    //初始化曲线数据结构
    useEffect(()=>{
        console.warn(`--toysData: `, toysData);
        if(toysData && toysData.length > 0){
            let orderLineObj = [];
            for(let x=0; x<toysData.length; x++){
                let {id, name, type, version, status, toyFun = 'v' } = toysData[x];
                let obj = { toyId: id, name, type, version, status, toyFun }
                let orders = toyFun.split(',')
                orders.forEach((o, i) => {
                    let lineIdx = 1
                    if (['v', 'v1', 's'].includes(o) || (type.toLowerCase()==='solace' && o==='t')) {
                        lineIdx = 1
                    } else if (o === 'v3') {
                        lineIdx = 3
                    } else {
                        lineIdx = 2
                    }
                    obj[`line${lineIdx}`] = [0] // 根据指令个数添加画线条数
                })
                orderLineObj.push(obj);
            }
            updateOrderLine(orderLineObj);
        }
    },[toysData,updateOrderLine]);

    //有记忆的hooks，记住上一次渲染计算的结果
    let savedSendTimerCallback = useRef();
    const [time, setTime] = useState(0)
    const callback = useCallback(() => {
        setTime(time + 1)
        let order = sendOrderToToy(orderLine,linkId);
        if(time % 10 === 0){
            socketServiceInstanct.socketEmitMsg(SocketEvent.ANON_COMMAND_LINK_TS, order);
        }
    },[time, orderLine, linkId, socketServiceInstanct]);

    //发给玩具指令
    const sendOrderToToy = (orderLine,linkId)=>{
        let toyData = {...orderLine};
        let orderBody = {};
        Object.keys(toyData).forEach(function(key){
            const curToy = toyData[key]
            let ctId = curToy.toyId;
            orderBody[ctId] = { v: -1, v1: -1, v2: -1, v3: -1, s: -1, p: -1, r: -1, f: -1, t: -1, d: -1, o: -1, pos: -1 };

            let toyFunArr = curToy.toyFun.split(',');
            toyFunArr.forEach(order => {
                let level = -1;
                let lineArr = []
                switch (order) {
                    case 'v':
                    case 'v1':
                    case 's':
                        lineArr = curToy.line1
                        break
                    case 'v2':
                    case 'r':
                    case 'p':
                    case 'f':
                    case 'd':
                    case 'pos':
                    case 'o':
                        lineArr = curToy.line2
                        break
                    case 't':
                        if(curToy.type.toLowerCase()==='solace') {
                          lineArr = curToy.line1
                        }else{
                          lineArr = curToy.line2
                        }
                        break
                    case 'v3':
                        lineArr = curToy.line3
                        break
                    default:
                        lineArr = curToy.line1
                        break
                }
                if(lineArr.length > 1){
                    level = lineArr[lineArr.length - 1];
                }
                if (order === 'p') {
                    if(level >=1 && level <= 6){
                        level = 1;
                    }else if(level > 6 && level < 14){
                        level = 2;
                    }else if(level >= 14){
                        level = 3;
                    }
                }
                if (order === 'pos' && level > 0) {
                  level *= 5;
                }
                orderBody[ctId] = { ...orderBody[ctId], [order]: level }
            })
        });
        // console.log(`--orderBody: `, orderBody);
        let cate = { "version": 5, "cate": "id", "id": orderBody };
        let order = {
            "toyCommandJson": JSON.stringify(cate),
            "linkId": linkId,
            "userTouch": true
        }
        return order;
    }

    //每次渲染，更新ref为最新的回调
    useEffect(()=>{
        savedSendTimerCallback.current = ()=>{
            callback();
        }
    }, [callback])
    useEffect(()=>{
        let timer = setInterval(()=>{
            if (isEnd && isEnd.end) {
                clearInterval(timer)
            }
            if(controlState.state === 1){
                savedSendTimerCallback.current();
            }
        }, 100);
        return function(){
            clearInterval(timer);
        }
    },[controlState, isEnd]);

    return (
        <>
          <div id="pc-balls-area" className="pc-balls-area" style={{'--ballRadius': `${ballRadius}px`, '--ballDiameter': `${ballRadius * 2}px`}}>
            <div className="ball-item" onTouchMove={ (e)=>{ e.stopPropagation() } }>
                <div className="ball-list">
                {
                    htmlArr
                }
                </div>
            </div>
          </div>
        </>
    );
}

export default connect(state => ({
    orderLine: state.orderLine,
    controlState: state.controlState,
}), {
    updateOrderLine,
    updateCurrentBallInfo
})(SlideBalls);
