
const orderUtils = {

  heightToLevel: (height, min=0, max=172) => {
    let level = Math.ceil((height - min)/((max-min)/20));
    if(level > 20){
      level = 20;
    }else if(level < 1){
      level = 0;
    }
    return parseInt(level);
  },
  //更heightToLevel方法反向计算
  levelToHeight: (level, max=154, min=0) => {
    let height = Math.floor(level*max/20);
    if(height > max){
      height = max;
    }else if(height < min){
      height = min;
    }
    return parseInt(height);
  }
};

// 指令优先级
const orderLevel = ['v1', 's', 'v', 'v2', 'v3', 'r', 'p', 'f', 't', 'd', 'o', 'pos']

export { orderUtils, orderLevel };