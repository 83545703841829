import React, { useState, useEffect } from 'react';
import './index.scss';
import SocketEvent from '@/serve/socket-event';
import { getMsgBody, generateMsgId } from "@/utils/common.js";
import {updateChatList} from "@/redux/action/chat-list";
import {connect} from "react-redux";
import $api from '@/serve/api';

const ControlRequest = (props) => {
    let {
        socketServiceInstanct,
        linkId,
        toysData,
        controlPermission,
        updateChatList,
    } = props;

    const [isRequestingControl, setIsRequestingControl] = useState(false);

    useEffect(()=>{
        if(controlPermission.creatorExistUntreatedLiveControlRequest && Date.now() - controlPermission.creatorLastApplyLiveControlTime < 60*1000) {
            setIsRequestingControl(true)
        }
    }, [controlPermission.creatorExistUntreatedLiveControlRequest, controlPermission.creatorLastApplyLiveControlTime])


    const ballCount = (toysData || []).reduce((count, toy)=>{
        let funcArr = toy.toyFun.split(",");
        count += funcArr.length
        return count
    }, 0)
    const ballRadius = ballCount <= 2 ? 30 : 24 // 球半径


    useEffect(()=>{
        // 客户端接收控制权限的响应数据
        const controlPermissionResponseTcEvent = (res) => {
            console.log(`--controlPermissionResponseTcEvent 2: `, res);
            setIsRequestingControl(false)

            const { operationType } = JSON.parse(res)
            // 埋点
            $api.logsNewV2({
                "logNo": "S0009",
                "content": JSON.stringify({
                    "page_name": "Control Link Open",//所在页面名称，默认
                    "event_id": "controllinkjs_permission_popup_disappear",  //事件id，默认
                    "event_type": "click",  //事件类型，默认
                    "element_id": "open_" + linkId,  //元素id，xxx为lastActiveSessionId
                    "element_content": {
                        'accept': '4',
                        'decline': '3',
                        'expired': '2',
                        // 'cancel': '1',
                    }[operationType], //元素内容，1--表示点击取消按钮，2--表示等待超过60秒自动取消，3--对方拒绝，4--对方接受
                }),
                "timeStamp":new Date().getTime()
            })
        }

        if (socketServiceInstanct) {
            socketServiceInstanct.on(SocketEvent.CL_CONTROL_PERMISSION_RESPONSE_TC, controlPermissionResponseTcEvent);
        }
        return function () {
            if (socketServiceInstanct) {
                socketServiceInstanct.off(SocketEvent.CL_CONTROL_PERMISSION_RESPONSE_TC, controlPermissionResponseTcEvent);
            }
        }
    }, [socketServiceInstanct, linkId]);

    const handleRequestingControl = () => {
        const wantRequesting = !isRequestingControl
        socketServiceInstanct.socketEmitMsg(SocketEvent.CL_CONTROL_PERMISSION_REQUEST_TS, {
            linkId,
            linkPermissionType: 'live_control',
            operationType: wantRequesting ? 'request' : 'cancel',
        });
        !wantRequesting && updateChatList([{...getMsgBody(linkId, { tips: `Control request cancelled` }, 'tips', `outroom-${linkId}`), createTime: Date.now(), msgId: generateMsgId()}])
        setIsRequestingControl(wantRequesting)

        if(wantRequesting){
            if(!sessionStorage.getItem('joiner_cancel_occupy_countdown_'+linkId)) { // 加入者取消占用倒计时
                sessionStorage.setItem('joiner_cancel_occupy_countdown_'+linkId, 1)
                socketServiceInstanct.socketEmitMsg(SocketEvent.Q_JOINER_CANCEL_OCCUPY_COUNTDOWN_TS, {linkId: linkId});
            }
        }

        // 埋点
        $api.logsNewV2({
            "logNo": "S0009",
            "content": JSON.stringify({
                "page_name": "Control Link Open",//所在页面名称，默认
                "event_id": wantRequesting ? "controllinkjs_permission_popup_exposure" : "controllinkjs_permission_popup_disappear",  //事件id，默认
                "event_type": wantRequesting ? "exposure" : "click",  //事件类型，默认
                "element_id": "open_" + linkId,  //元素id，xxx为lastActiveSessionId
                "element_content": "1", //元素内容，1--表示等待live control授权
            }),
            "timeStamp":new Date().getTime()
        })
    }


    return (
        <>
          <div className="pc-control-request" style={{'--ballRadius': `${ballRadius}px`, '--ballDiameter': `${ballRadius * 2}px`}}>
            <div className="ball-item" onTouchMove={  (e)=>{ e.stopPropagation() } }>
                <div className="ball-list">
                    <div className="control-request-type">Live Control</div>
                    <div className={`control-request-tips ${isRequestingControl && 'ellipsisflag'}`}>{ !isRequestingControl ? `You need to send a Live Control request to control the other user's toy.` : 'Waiting for the other user to respond'}&nbsp;</div>
                    <div className="control-request-btn" onClick={handleRequestingControl}>{ !isRequestingControl ? 'Send Request' : 'Cancel' }</div>
                </div>
            </div>
          </div>
        </>
    );
}

export default connect(state => ({
    controlPermission: state.controlPermission,
}), {
    updateChatList,
})(ControlRequest);

