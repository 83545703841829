import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import './index.scss';
import { updateOrderLine } from '@/redux/action/order-line.js';
import { updateFromAppToyCommand } from '@/redux/action/from-app-toy-command.js';
import Balls from '../balls';
import { useTranslation } from 'react-i18next'

const LiveControl = (props)=>{
  let {
    linkId,isCreator,toysData,joinerToy,orderType,orderLine,
    updateOrderLine,fromAppToyCommand,socketServiceInstanct
  } = props;
  const [lastAppToys, setLastAppToys] = useState(fromAppToyCommand);
  const { t } = useTranslation();
  useEffect(()=>{
    const changeObj = (o) =>{
      let newObj = {};
      let effective = ['pos', 'o', 'd', 't', 'f', 's', 'p', 'r', 'v1', 'v2', 'v3', 'v']
      effective.forEach(key => {
        if (+o[key] !== -1) {
          newObj[key] = o[key]
        }
      })
      return newObj;
    }
    if(fromAppToyCommand.toyCommandJson){
      let appToys = fromAppToyCommand.toyCommandJson;
      if(JSON.stringify(appToys) !== JSON.stringify(lastAppToys)){
        // {dc49c0d41c46: { v: 1, v1: -1, v2: -1, s: -1 p: 0, r: -1, f: -1 }}
        Object.keys(appToys).forEach(function(toyId, index){
          let tmpArr = [...orderLine];
          for(let i=0; i<tmpArr.length; i++){
            if(tmpArr[i].toyId === toyId){
              let orderObj = changeObj(appToys[toyId]); //{v:1,p:0}
              Object.keys(orderObj).forEach(function(ckey, index){
                let curLevel = parseInt(orderObj[ckey]);
                if(ckey === "v" || ckey === "v1" || ckey === 's'){ //红线v指令
                // if(ckey === "v" || ckey === "v1" || ckey === 's' || (ckey === "t" && toysData.find(item=>item.id===toyId)?.type.toLowerCase()==='solace')){ //红线v指令
                  tmpArr[i].line1.push(curLevel);
                }else if(ckey === "p"){
                  if(curLevel === 0){
                    curLevel = 0;
                  }else if(curLevel === 1){
                    curLevel = 6;
                  }else if(curLevel === 2){
                    curLevel = 13;
                  }else{
                    curLevel = 20;
                  }
                  tmpArr[i].line2.push(curLevel);
                }else if(ckey === "v2" || ckey === "r" || ckey === "f" || ckey === "t" || ckey === "d"){
                  tmpArr[i].line2.push(curLevel);
                }else if(ckey === "v3"){
                  tmpArr[i].line3.push(curLevel);
                }
              });
            }else{
              //自动补点其他曲线数据，保持曲线x轴长度一致
              if (tmpArr[i].line) {
                for (let key in tmpArr[i].line) {
                  const length = tmpArr[i].line[key].length
                  const n = tmpArr[i].line[key][length-1]
                  tmpArr[i].line[key].push(n)
                }
              } else {
                Object.keys(tmpArr[i]).forEach(key => {
                  if (key.includes('line')) {
                    let length = tmpArr[i][key].length
                    let n = tmpArr[i][key][length-1]
                    tmpArr[i][key].push(n)
                  }
                })
              }
            }
            updateOrderLine(tmpArr);
          }
        });
        setLastAppToys(appToys);
      }
    }
  },[toysData,fromAppToyCommand,orderLine,updateOrderLine,orderType,lastAppToys]);

  return (
    <div className="live-control">
      <div className="msg1" style={{ display:(isCreator && joinerToy.length > 0 ? "none" : "block") }}>
        {t('live_control_panel_partner_no_toy')}
      </div>
      <div className="balls" style={{ display:(isCreator && joinerToy.length > 0 ? "block" : "none") }}>
        <Balls
          linkId={linkId}
          toysData={toysData}
          isCreator={isCreator}
          socketServiceInstanct={socketServiceInstanct}>
        </Balls>
      </div>
      <div className="msg2">{t('live_control_text')}</div>
    </div>
  )
}

export default connect(state => ({
  orderLine: state.orderLine,
  fromAppToyCommand: state.fromAppToyCommand
}), {
updateOrderLine,
updateFromAppToyCommand
})(LiveControl);
