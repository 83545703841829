import React, { useState, useEffect, useCallback } from 'react';
import {connect} from 'react-redux';
import OrderLines from './order-lines';
import SyncOrderLines from './sync-control/ldr/ldr-order-lines/index.js';
import Balls from './balls';
import LiveControl from './live-control';
import SyncControl from './sync-control';
import { orderUtils } from '@/utils/order-utils.js';
import { updateOrderLine } from '@/redux/action/order-line.js';
import { updateFromAppToyCommand } from '@/redux/action/from-app-toy-command.js';
import './index.scss';

const ToyControl = (props) => {
  const { orderLine,updateOrderLine,fromAppToyCommand,updateFromAppToyCommand } = props;
  const {
    toysData,toyStatus,linkId,isCreator,userOnline,isEnd,joinerToy,creatorToy,ldsOrLdr,
    ldsJoinerToyList,ldsCreatorToyList,socketServiceInstanct,liveOrSync,ldrControlType,ldsControlType,isSupportTouchPanel
  } = props.data;
  let defaultTop = 154;
  const [slideBtnTop, setSlideBtnTop] = useState(defaultTop);
  //lds控制玩具感应到的通过connectApp传过来的强度
  const [ldsCreatorToyLevel, setLdsCreatorToyLevel] = useState({all: {v: -1}});
  const [ldsJoinerToyLevel, setLdsJoinerToyLevel] = useState({all: {v: -1}});
  let toyId = "toy1-drag-v-btn-id";
  let orderType = "v";

  const [syncToysData, setSyncToysData] = useState([]);
  // 合并所有玩具指令
  useEffect(()=>{
    let allArr = [];
    let toyNameArr = [];
    let toys = [...joinerToy, ...creatorToy]
    toys.forEach(toy => {
      let toyFun = toy.toyFun
      //syncControl根据功能生成滚动球，v1、v2与v同属震动，故将v1、v2转为v
      if (toyFun.includes('v1')) {
        toyFun = toyFun.replace('v1', 'v')
      }
      if (toyFun.includes('v2')) {
        toyFun = toyFun.replace('v2', 'v')
      }
      if (toyFun.includes('v3')) {
        toyFun = toyFun.replace('v3', 'v')
      }
      let toyFunArr = (toyFun + "").split(",")
      allArr = allArr.concat(toyFunArr)
      toyNameArr.push(toy.type)
    })
    let newToyFun = Array.from(new Set(allArr)).join(",");
    let newToyName = Array.from(new Set(toyNameArr)).join(",");
    let tmpArr = [];
    tmpArr.push({ toyFun:newToyFun,name:newToyName }); // [{toyFun: 'p,v,s', name: 'Max,tenera'}]
    setSyncToysData(tmpArr);
  }, [joinerToy,creatorToy]);

  //初始化曲线数据结构
  useEffect(()=>{
    if(isCreator){
      if(liveOrSync === "sync"){
        if(syncToysData && syncToysData.length > 0){
          let orderLineObj = [];
          for(let x=0; x<syncToysData.length; x++){
            let curToy = syncToysData[x];
            let obj = {
              toyId: curToy.id,
              name: curToy.name,
              type: curToy.type,
              fVersion: curToy.fVersion,
              status: curToy.status,
              toyFun: curToy.toyFun,
              line: {}
            }
            let orders = curToy.toyFun.split(',')
            orders.forEach((o, i) => {
              obj.line[o] = [0]  // 根据指令添加画线条数
            })
            orderLineObj.push(obj);
          }
          updateOrderLine(orderLineObj);
        }
      }else{
        if(toysData && toysData.length > 0){
          let orderLineObj = [];
          for(let x=0; x<toysData.length; x++){
              let {id, name, type, version, status, toyFun = 'v' } = toysData[x];
              let obj = { toyId: id, name, type, fVersion: version, status, toyFun }
              let orders = toyFun.split(',')
              orders.forEach((o, i) => {
                let lineIdx = 1
                if (['v', 'v1', 's'].includes(o) || (type.toLowerCase()==='solace' && o==='t')) {
                  lineIdx = 1
                } else if(o === 'v3') {
                  lineIdx = 3
                } else {
                  lineIdx = 2
                }
                obj[`line${lineIdx}`] = [0] // 根据指令个数添加画线条数
              })
              orderLineObj.push(obj);
          }
          updateOrderLine(orderLineObj);
        }
      }
    }
  },[isCreator,liveOrSync,syncToysData,toysData,updateOrderLine]);

  const addPointToLine = useCallback((value, orderLineTmp)=>{
    let level = orderUtils.heightToLevel(value, defaultTop, 0);
    setSlideBtnTop(value);
    let tmpArr = [...orderLineTmp];
    for(let i=0; i<tmpArr.length; i++){
      let func = (tmpArr[i].toyFun + "").split(",");
      if(tmpArr[i].toyId === toyId){
        if(['v', 'v1', 's'].includes(orderType) || (tmpArr[i].type.toLowerCase()==='solace' && orderType==='t' )){
          tmpArr[i].line1.push(level)
          if(func.length > 1){
            let length = tmpArr[i].line2.length
            let n = tmpArr[i].line2[length-1]
            tmpArr[i].line2.push(n)
            if(tmpArr[i].line3) {
              let length = tmpArr[i].line3.length
              let n = tmpArr[i].line3[length-1]
              tmpArr[i].line3.push(n)
            }
          }
        }else if (['v2', 'r', 'p', 'f', 't', 'd', 'o', 'pos'].includes(orderType)){
          tmpArr[i].line2.push(level)
          if (func.length > 1) {
            let length = tmpArr[i].line1.length
            let n = tmpArr[i].line1[length-1]
            tmpArr[i].line1.push(n)
            if(tmpArr[i].line3) {
              let length = tmpArr[i].line3.length
              let n = tmpArr[i].line3[length-1]
              tmpArr[i].line3.push(n);
            }
          }
        }
      }else if(['v3'].includes(orderType)){
        tmpArr[i].line3.push(level)
        if (func.length > 1) {
          let length = tmpArr[i].line1.length
          let n = tmpArr[i].line1[length-1]
          tmpArr[i].line1.push(n);
          {
            let length = tmpArr[i].line2.length
            let n = tmpArr[i].line2[length-1]
            tmpArr[i].line2.push(n)
          }
        }
      }else{ //自动补点其他曲线数据，保持曲线x轴长度一致
        Object.keys(tmpArr[i]).forEach(key => {
          if (key.includes('line')) {
            let length = tmpArr[i][key].length
            let n = tmpArr[i][key][length-1]
            tmpArr[i][key].push(n)
          }
        })
      }
      updateOrderLine(tmpArr);
    }
  },[orderType, toyId, defaultTop, updateOrderLine]);
  //创建者根据玩具指令画曲线方法
  useEffect(()=>{
    let tm = null;
    if(isCreator && liveOrSync !== "sync"){
      tm = setTimeout(()=>{
        addPointToLine(parseInt(slideBtnTop), orderLine);
      },100);
    }
    return function(){
      clearTimeout(tm);
    }
  },[slideBtnTop,liveOrSync,orderLine,addPointToLine,isCreator]);

  //来自app转发过来的消息
  //syncControl的指令格式
  //message = "{\"cate\":\"all\",\"all\":{\"v\":5,\"v1\":-1,\"v2\":-1,\"p\":-1,\"r\":5}}"
  //liveControl的指令格式：
  //message = "{\"cate\":\"id\",\"id\":{\"dc49c0d41c46\":{\"v\":4,\"v1\":-1,\"v2\":-1,\"p\":11,\"r\":-1}}}"
  useEffect(()=>{
    // 此方法为connect APP 发出的指令传输到H5
    window.postMsgToH5 = (arg) => {
      try{
        arg = decodeURIComponent(arg);
        let obj = JSON.parse(arg);
        console.log("postMsgToH5 obj--->", JSON.stringify(obj));
        if(obj.event === "toyCommand"){  //玩具指令
          let message = JSON.parse(obj.message)
          if(liveOrSync === "sync"){
            let toyCommandJson = message[message.cate]
            if(ldsOrLdr === "ldr"){   //镜面模式
              updateFromAppToyCommand({ ...fromAppToyCommand, toyCommandJson });
            }else if(ldsOrLdr === "lds"){ //感应模式
              if(toyCommandJson){
                let tmp = message.cate === 'all' ? {all: message.all} : message.id
                if(ldsControlType === 0){
                  setLdsJoinerToyLevel(tmp);
                }else if(ldsControlType === 1){
                  setLdsJoinerToyLevel(tmp);
                  setLdsCreatorToyLevel(tmp);
                }else{
                  setLdsCreatorToyLevel(tmp);
                }
              }
            }
          }else{
            let toyCommandJson = message.id;
            updateFromAppToyCommand({ ...fromAppToyCommand, toyCommandJson });
          }
        }
        return true;
      }catch(e){
        console.error("115 error:" + e.message, arg);
        return false;
      }
    }
    //感应模式中，remote的指令流程： toy --> remoteApp --> 服务端 --> connectApp --> H5
    //此方法专门用于connectApp转发RemoteApp在感应模式中产生的指令到H5网页
    window.postRemoteMsgToH5 = (arg) => {
      try{
        arg = decodeURIComponent(arg);
        let obj = JSON.parse(arg);
        console.log("postRemoteMsgToH5 obj--->", JSON.stringify(obj));
        if(obj.event === "toyCommand"){  //玩具指令
          let message = JSON.parse(obj.message)
          if(liveOrSync === "sync"){
            let toyCommandJson = message[message.cate]
            // for(let key in toyCommandJson) {
            //   toyCommandJson[key] = +toyCommandJson[key] // 统一level值为数值类型
            // }
            if(ldsOrLdr === "ldr"){   //镜面模式
              updateFromAppToyCommand({ ...fromAppToyCommand, toyCommandJson });
            }else if(ldsOrLdr === "lds"){ //感应模式
              if(toyCommandJson){
                let tmp = message.cate === 'all' ? {all: message.all} : message.id
                if(ldsControlType === 0){
                  setLdsCreatorToyLevel(tmp);
                }else if(ldsControlType === 2){
                  setLdsCreatorToyLevel(tmp);
                  setLdsJoinerToyLevel(tmp);
                }else{
                  setLdsJoinerToyLevel(tmp);
                }
              }
            }
          }else{
            let toyCommandJson = message.id;
            updateFromAppToyCommand({ ...fromAppToyCommand, toyCommandJson });
          }
        }
        return true;
      }catch(e){
        console.error("115 error:" + e.message, arg);
        return false;
      }
    }
  }, [liveOrSync,ldsOrLdr,ldsControlType,fromAppToyCommand,updateFromAppToyCommand]);

  return (
      <>
        <div className="toy-control">
          {
            ldsOrLdr === "lds" ? "" :
            (<div className="order-line">
              {
                liveOrSync === "sync" ?
                <SyncOrderLines toyStatus={toyStatus} userOnline={userOnline}></SyncOrderLines>
                :
                <OrderLines toyStatus={toyStatus} userOnline={userOnline}></OrderLines>
              }
            </div>)
          }
          {
            isCreator ?
            (
              (liveOrSync === "live" &&
              <LiveControl
                linkId={linkId}
                toyId={toyId}
                orderType={orderType}
                toysData={toysData}
                joinerToy={joinerToy}
                isCreator={isCreator}
                socketServiceInstanct={socketServiceInstanct}>
              </LiveControl>) ||
              (
                liveOrSync === "sync" &&
                <SyncControl
                  linkId={linkId}
                  toyId={toyId}
                  orderType={orderType}
                  toysData={toysData}
                  joinerToy={joinerToy}
                  creatorToy={creatorToy}
                  ldsJoinerToyList={ldsJoinerToyList}
                  ldsCreatorToyList={ldsCreatorToyList}
                  isCreator={isCreator}
                  ldsOrLdr={ldsOrLdr}
                  isEnd={isEnd}
                  ldrControlType={ldrControlType}
                  ldsControlType={ldsControlType}
                  ldsJoinerToyLevel={ldsJoinerToyLevel}
                  ldsCreatorToyLevel={ldsCreatorToyLevel}
                  setLdsJoinerToyLevel={setLdsJoinerToyLevel}
                  isSupportTouchPanel={isSupportTouchPanel}
                  socketServiceInstanct={socketServiceInstanct}>
                </SyncControl>)
            )
            :
            <div className='balls-control'>
              <div className="balls">
                <Balls
                  linkId={linkId}
                  isEnd={isEnd}
                  isCreator={isCreator}
                  toysData={toysData}
                  liveOrSync={liveOrSync}
                  socketServiceInstanct={socketServiceInstanct}>
                </Balls>
              </div>
            </div>
          }
          <div className="b-space"></div>
        </div>
      </>
  );
}

export default connect(state => ({
  orderLine: state.orderLine,
  fromAppToyCommand: state.fromAppToyCommand
}), {
updateOrderLine,
updateFromAppToyCommand
})(ToyControl);
