import axios from "axios"
// import tokenUtils from "./token-utils"
import { message } from 'antd';

// axios.defaults.baseURL = config.baseUrl
// 是否带cookies
// axios.defaults.withCredentials = true

/* 请求之前拦截器 */
axios.interceptors.request.use(
    (config) => {
        // const tokens = tokenUtils.getToken()
        // if (tokens) {
        //     config.headers["tokens"] = tokens
        // }
        return config
    },
    (err) => {
        return Promise.reject(err)
    }
)

// 请求后置处理

axios.interceptors.response.use(
    /* 对响应成功做点什么 （statusCode === 200），必须return response*/
    (res) => {
        if(res.status === 200){
            if (!getCodeMsg(res.data)) {
                return Promise.reject(res.data)
            }
        }
        return res.data
    },
    (err) => {
        /*  对响应错误做点什么 （statusCode !== 200），必须return response*/
        // const errMsg = err.toString()
        // const code = errMsg.substr(errMsg.indexOf("code") + 5)
        // if (!getStatusMsg(parseInt(code))) {
        //     if (parseInt(code) === 5002) {
        //         ext.loginOut()
        //     }
        // } else {
        //     message.error("糟糕，网络开小差，请重新操作！")
        // }
        return Promise.reject(err)
    }
)

/**
 * 网络请求获取业务code 转移提醒
 * @param code
 * @returns {boolean}
 */
function getCodeMsg(data) {
    if ([0, 50091, 50092, 50093, 5009101, 5009102, 5009103, 5009104].includes(data.code)) {
        return true
    } else if (data.code === -1) {
        message.error("糟糕，网络开小差，请重新操作！")
        return false
    } else {
        // debugger
        message.error(data.message)
        return false
    }
}

/**
 * 获取响应状态 转义提醒
 * @param code
 * @returns {boolean}
 */
// function getStatusMsg(code) {
//     switch (code) {
//         case 400:
//             message.error("Login certificate is invalid, please login again")
//             return false
//         case 401:
//             message.error("Login certificate is invalid, please login again")
//             return false
//         case 403:
//             message.error("No operation permission!")
//             return false
//         case 500:
//             message.error("Bad, the network drifts, please operate again!")
//             return false
//         case 404:
//             message.error("Request address not found!")
//             return false
//         default:
//             return true
//     }
// }

export default axios
