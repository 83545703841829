import './index.scss'
import i18next from "@/utils/i18n.js"
const errorPage = (props) => {
    const {
        pageStatus
    } = props;
    const pageArr = {
        default: <p>{i18next.t('failed_open_control_link')}</p>, // 异常页面
        isAnotherPlaying: <p>{i18next.t('cant_open_multiple_links')}</p>, // 链接已被使用页面
        isShowLimit: (<p>
                    <span>You have been banned from opening control links for violating our <a style={{color: '#FF2D89'}} href='https://hyttoapps.bandnana.com/remote/terms-and-conditions' target='_blank' rel="noreferrer">Terms & Conditions.</a>  You can try again later or contact us <a style={{color: '#FF2D89'}} href='https://www.lovense.com/contact' target='_blank' rel="noreferrer">here.</a></span>
                </p>), // 被限制页面
        isBanPage: <p>{i18next.t('controller_temporarily_banned')}</p> // 被禁止访问页面
    }
    return (
        <div className="error">
            <div className="content">{pageArr[pageStatus || 'default']}</div>
        </div>
    )
}
export default errorPage;
