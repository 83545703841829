import React, { createRef, useEffect, useState, useRef } from 'react'
import './index.scss'
import unisex1 from '@/assets/images/business/avatar_unisex1.png'
import unisex2 from '@/assets/images/business/avatar_unisex2.png'
import errorIcon from '@/assets/images/business/icon_chat_error.png'
import durationIcon from '@/assets/images/business/icon-time.svg'
import couponImg from '@/assets/images/business/coupon_card.png'
import expressin from '@/utils/expressin'
import { connect } from "react-redux"
import aesUtils from '@/utils/aes/aes-utils'
import { updateCloseOperate } from "@/redux/action/close-operate"
import toysArr from '@/utils/toy.js'
import { urlToObj } from '@/utils/common.js'
import { useTranslation } from 'react-i18next'
import LoadingIcon from '@/components/loading-icon'
import SocketEvent from '@/serve/socket-event'
import VoiceIcon from '@/components/voice-icon'
import { updateChatList } from "@/redux/action/chat-list"
import BenzAMRRecorder from 'benz-amr-recorder'
import { isPc } from "@/utils/common.js"
import $api from '@/serve/api'

const ChatInfoList = (props) => {
	const { t } = useTranslation()
	const { chatList, isCreator, time, closeTime, stop, reachMax, socketServiceInstanct, controlPermission } = props
	const [list, setList] = useState([])
	const [num, setNum] = useState(time)
	const [stopped, setStopped] = useState(Boolean(stop))
	const [chatListRef, setChatListRef] = useState(null)
	const [out, setOut] = useState(false)
	const interval = useRef(null)
	const audioRef = useRef()
	const [amr, setAmr] = useState(null)
	// 当前播放的语音url
	const [audioUrl, setAudioUrl] = useState('')
	const [playStatus, setPlayStatus] = useState('')
	const [widthArr, setWidthArr] = useState({})
	const [audioArr, setAudioArr] = useState([]) // eslint-disable-line no-unused-vars
	const [countryCode, setCountryCode] = useState('') // eslint-disable-line no-unused-vars

	useEffect(() => {
		setCountryCode(localStorage.getItem("countryCode") || '')
	}, [])

	useEffect(() => {
		if (time) {
			if (stop && stop !== 'over') {
				clearInterval(interval.current)
				setNum(0)
				setStopped(true)
				return
			}
			if (!interval.current) {
				let data = time
				interval.current = setInterval(() => {
					data--
					setNum(data)
					if (data === 0) {
						clearInterval(interval.current)
						setStopped(true)
						setOut(true)
					}
				}, 1000)
			}
		}
		if (stop === 'over') {
			clearInterval(interval.current)
			setStopped(true)
			if (isCreator) setOut(true)
		}
	}, [time, stop, isCreator])

	useEffect(() => {
		let ttt = createRef()
		setChatListRef(ttt)
	}, [])
	// 判断文本是否存在表情
	const isExistEmoticon = (str) => {
		if (!str) {
			return
		}
		let isFlag = false
		for (const item of expressin) {
			if (!isFlag && str.indexOf(`[${item.key}]`) > -1) {
				isFlag = true
				return isFlag
			}
		}
	}
	// 时间转换AM/PM
	const timeToText = (val) => {
		if (!val) return
		let now = new Date(val)
		let h = now.getHours()
		let mm = now.getMinutes()
		let str
		if (h > 12) {
			h -= 12
			str = " PM"
		} else {
			str = " AM"
		}
		h = h < 10 ? "0" + h : h
		mm = mm < 10 ? "0" + mm : mm
		let xy = h + ":" + mm
		xy += str
		return xy
	}
	// 去重
	const deWeight = (list = []) => {
		let arr = JSON.parse(JSON.stringify(list))
		if (arr.length === 0) return arr
		for (let i = 0; i < arr.length - 1; i++) {
			for (let j = i + 1; j < arr.length; j++) {
				if (arr[i].msgId === arr[j].msgId) {
					if (arr[i].msgWaitState === '' || arr[j].msgWaitState === '') {
						arr[i].msgWaitState = ''
					} else {
						if (arr[i].msgWaitState === 'reload' || arr[j].msgWaitState === 'reload') {
							arr[i].msgWaitState = 'reload'
						} else if (arr[i].msgWaitState === 'error' || arr[j].msgWaitState === 'error') {
							arr[i].msgWaitState = 'error'
						}
					}
					arr.splice(j, 1)
					//因为数组长度减小1，所以直接 j++ 会漏掉一个元素，所以要 j--
					j--
				}
			}
		}
		return arr
	}
	const closeChatOperate = () => {
		props.updateCloseOperate(false)
	}
	const resendMsg = (e) => {
		e.msgWaitState = 'reload'
		if (e.msgType === 'audio') {
			let fileData = new FormData()
			let file = new window.File([e.blob], "record.am")
			fileData.append('file', file)
			$api.uploadAudio(fileData).then(res => {
				const fileUrl = res.data
				e.url = fileUrl
				delete e.blob
				socketServiceInstanct.socketEmitMsg(SocketEvent.Q_SEND_IM_MSG_TS, e)
			}).catch(() => {
				e.msgWaitState = 'error'
				props.updateChatList([{ ...e }])
			})
			props.updateChatList([{ ...e }])
			return
		}
		socketServiceInstanct.socketEmitMsg(SocketEvent.Q_SEND_IM_MSG_TS, e)
		props.updateChatList([{ ...e }])
	}

	// 秒转换分秒显示
	const format = (s) => {
		let h = Math.floor(s / 60)
		s = s % 60
		h += ''
		s += ''
		if (h.length === 1) h = `0${h}`
		if (s.length === 1) s = `0${s}`
		return `${h}:${s}`
	}

	const playAudio = (e) => {
		// 安卓原生录音是am格式，需要用benz-amr-recorder播放
		if (e.audioUrl.includes('.am')) {
			if (amr && amr.isPlaying()) {
				amr.stop()
				if (e.audioUrl === playStatus) {
					setPlayStatus('')
					return
				}
			}
			try {
				const amrObj = new BenzAMRRecorder()
				amrObj.initWithUrl(e.audioUrl).then(() => {
					amrObj.onPlay(() => {
						// 开启音频动画
						setPlayStatus(e.audioUrl)
					})
					amrObj.onAutoEnded(() => {
						// 关闭音频动画
						setPlayStatus('')
						if (amr && amr.isPlaying()) amr.stop()
					})
					amrObj.play()
					setAmr(amrObj)
					navigator.mediaDevices.enumerateDevices().then(devices => {
						let list = []
						devices.forEach((device) => {
							if (device.kind === 'audiooutput') {
								list.push(device)
							}
						})
						if (list && list.length) {
							const deviceId = list[0].deviceId
							if (deviceId) {
								navigator.mediaDevices.getUserMedia({ audio: { deviceId } })
							}
						}
					})
				})
			} catch (err) {
				console.log(err.name + ': ' + err.message)
			}
		} else {
			if (playStatus === e.audioUrl) {
				audioRef.current.stop()
				setPlayStatus('')
				setAudioUrl('')
				return
			}
			setAudioUrl(e.audioUrl)
			audioRef.current.play()
			setPlayStatus(e.audioUrl)
			setTimeout(() => {
				setPlayStatus('')
			}, e.audioTime * 1000)
		}
	}

	// 计算宽度
	const getAudioWidth = (e) => {
		let num = 2
		if (isPc()) num = 1
		if (e <= 10) return `${num}rem`
		const percent = 4 * (e - 10) / 60
		return `${percent + num}rem`
	}

	// 优惠券卡片 couponCard 埋点
	const handleCouponCardLog = ({ couponUrl='' }={}) => {
		$api.logsNewV2({
			"logNo": "S0009",
			"content": JSON.stringify({
				"page_name": "Save by Sharing",//所在页面名称，默认
				"event_id": "save_by_sharing_share_link_click",  //事件id，默认
				"event_type": "click",  //事件类型，默认
				"element_id": "save_by_sharing_share_link",  //元素id
				"element_type": "link", //元素类型，
				"element_name":"3", //元素名称，3--表示点击control link内链接
				"element_content": couponUrl ? (couponUrl.includes('&') ? JSON.stringify(urlToObj(couponUrl)) : couponUrl) : '', //元素内容，链接url
			}),
			"timeStamp": new Date().getTime()
		})
	}

	useEffect(() => {
		if (chatList && chatList.length > 0) {
			// 文本替换表情
			const replaceEmoji = (str) => {
				if (!str) {
					return
				}
				if (isExistEmoticon(str)) {
					let text = str
					for (const item of expressin) {
						const key = `[${item.key}]`
						if (text.indexOf(key) > -1) {
							let n = (text.split(key)).length - 1
							let num = 0
							// 处理多个一样的表情
							while (num < n) {
								const em = require("@/assets/images/business/emoji/" + item.emoji).default
								let img = `<img src=${em} style="width:20px;height:20px;" alt=""/>`
								// 把所有文本表情替换成标签img
								text = text.replace(key, img)
								num++
							}
						}
					}
					return text
				}
				return str
			}
			for (const item in chatList) {
				let createTime = chatList[item].createTime
				if (!chatList[item].isFirst) {
					if (isExistEmoticon(chatList[item].msgDataText)) {
						chatList[item].msgDataText = replaceEmoji(chatList[item].msgDataText)
					}
					//相对上一条数据时间超过一分钟，显示当前时间
					if (item > 0 && createTime - chatList[Number(item) - 1].createTime > 60000) {
						chatList[item].timeText = timeToText(createTime)  //插入时间
					}
				} else {
					chatList[item].timeText = createTime && timeToText(createTime) //插入时间
				}
			}
			// 计算语音宽度
			const audioList = chatList.filter(item => item.msgType === "audio")

			if (audioList && audioList.length) {
				let obj = {}
				audioList.forEach(ele => {
					obj[`${ele.audioTime}`] = getAudioWidth(ele.audioTime)
					if (ele.msgType === 'audio') {
						setAudioArr(e => {
							if (!e.includes(ele.audioUrl)) {
								const amrObj = new BenzAMRRecorder()
								amrObj.initWithUrl(ele.audioUrl)
							}
							return [...e, ele.audioUrl]
						})
					}
				})
				setWidthArr(obj)
			}
		}
		let resArr = deWeight(chatList)
		setList(resArr)
		if (chatListRef && chatListRef.current) {
			//判断元素是否出现了滚动条
			// chatListRef.current.scrollTop = chatListRef.current.scrollHeight + 288;
			setTimeout(() => {
				if (chatListRef && chatListRef.current) chatListRef.current.scrollTop = chatListRef.current.scrollHeight
			}, 100)
		}
	}, [chatList, chatListRef])

	return (
		<div id="lvs-chat-list" className="chat-list" ref={chatListRef}>
			<ul onClick={closeChatOperate}>
				{
					list.map((item, index) => {
						let toys = []
						let msgDataObj = null
						if (typeof item.msgData === "string") {
							try {
								let tmpStr = aesUtils.aesDecryptXy(item.msgData)
								if (tmpStr) {
									msgDataObj = JSON.parse(tmpStr)
								} else {
									let { x: dynamicKey, y: dynamicValue } = JSON.parse(localStorage.getItem('aesEncryp')) || {}
									console.log("无法aesDecryptXy解密的数据 item=", item, "x=" + dynamicKey, "y=" + dynamicValue)
									item.msgType = "error" //无法解密的数据，把msgType标记为error，不做任何dom渲染处理
								}
							} catch (e) {
								console.error("138 e=", e.message, item)
								let { x: dynamicKey, y: dynamicValue } = JSON.parse(localStorage.getItem('aesEncryp')) || {}
								console.log("无法aesDecryptXy解密的数据 item=", item, "x=" + dynamicKey, "y=" + dynamicValue)
								item.msgType = "error" //无法解密的数据，把msgType标记为error，不做任何dom渲染处理
							}
						} else {
							msgDataObj = item.msgData
						}
						if (item.msgType === 'controllink') {
							if (msgDataObj && msgDataObj.creator) {
								toys = msgDataObj.creator.toys
							}
						}
						// let toyName = ''
						// if(item.msgType === 'controllink'){
						// 	if(toys && toys.length > 1){
						// 		if(msgDataObj.toysName){
						// 			toyName = msgDataObj.toysName
						// 		}else{
						// 			let toysName = [];
						// 			for (const item of toys) {
						// 				let tmpName = toysArr[(item.type+"").toLowerCase()];
						// 				if(!tmpName){
						// 					tmpName = item.type;
						// 				}
						// 				if(item.version){
						// 					toysName.push(tmpName + " " + item.version);
						// 				}else{
						// 					toysName.push(tmpName);
						// 				}
						// 			}
						// 			toyName = toysName.join(',')
						// 		}
						// 	}else{
						// 		const toyConfigValue = toysArr[(toys[0].type + "").toLowerCase()]
						// 		const toyType = toyConfigValue ? toyConfigValue : toys[0].type
						// 		toyName = toyType + (toys[0].version ? (" " + toys[0].version) : "")
						// 	}
						// }

						return (
							(item.msgType === 'controllink' && toys.length > 0 && (
                <>
								<li key={(item.ackId || item.msgId) + index + '-toycard'} className='control-link-li'>
									{item.timeText && (
										<div className="time">
											<span>{item.timeText}</span>
										</div>
									)}
									<div className="chat-area">
										<div className="content">
											<div className="info">
												{/* <p className="chat-text">{t('start_message_des')}</p> */}
												<div className="top-info">
													<div className="flex center">
														<div className="img">
															{
																toys.map(toyItem => {
																	let path = ""
																	let type = (toyItem.type + "").toLowerCase()
																	const version = (toyItem.version && Number(toyItem.version) !== 1) ? ('_' + toyItem.version) : ''
																	if (type && Object.keys(toysArr).indexOf(type) >= 0) {
																		path = require("@/assets/images/business/toys_icon/" + type + version + ".png").default
																	} else {
																		path = require("@/assets/images/business/toys_icon/noType.png").default
																	}
																	return (
																		<img
																			className="oneImg"
																			key={toyItem.id}
																			src={path}
																			alt=""
																		/>
																	)
																})
															}
														</div>

														<div className="toy-detail flex1">
															<div className="toy-title">{ msgDataObj.link.linkDesc || window.location.href }</div>
														</div>
													</div>

													<div className="flex fwrap">
														<div className="toy-tag toy-tag-red">
															<img className="toy-tag-duration" src={ durationIcon } alt="" />
															{msgDataObj.link.expires > 0 ? msgDataObj.link.leftControlTimeText : t('start_message_duration_unlimited')}
														</div>
														{
															msgDataObj.link.tags?.map((item)=>(
																<div className="toy-tag" key={item} >{ item }</div>
															))
														}
													</div>
												</div>
											</div>
										</div>
									</div>

									{(time || time === 0) && <div className='auto-disable'>
										<div className='disable-time' onClick={() => {
											if (isCreator && !stopped) closeTime()
										}}>
											<i className='time-icon'></i>
											{`${format(num)}`}
											{stopped ? ((!out || isCreator) && <span className='stop-tips'>{` ${t('common_stopped')}`}</span>) : (isCreator && <span className='stop-btn'>{` ${t('common_stop')}`}</span>)}
										</div>
										<div className='disable-content'>
											{isCreator ? t('auto_disable_controllee_des2') : (reachMax ? t('auto_disable_controller_des4') : t('auto_disable_controller_des3'))}
										</div>
									</div>}
								</li>

								<li key={(item.ackId || item.msgId) + index + '-sayhi'}>
									<div className='chat-r-area r-user-info'>
										<div className="content">
											<div className="info">
												<p className="chat-text">{t('new_control_link_start_message')}</p>
											</div>
										</div>
										<span className="arrow" />
										<img src={unisex2} alt="" />
									</div>
                </li>

                {!isCreator && !controlPermission.openControlPermission &&
                <li key={'tips-disable-' + (item.ackId || item.msgId) + '-' + index}>
                  {<div className='tips-disable'>
                    <div className='tips-disable-content'>
                      The other user hasn't joined this room yet. You can still control their toy(s).
                    </div>
                  </div>}
                </li>}
                </>
							)) ||
							(item.msgType === "tips" && (
								<li key={(item.ackId || item.msgId) + index + '-tips'}>
									{<div className='tips-disable'>
										<div className='tips-disable-content'>
											{msgDataObj.tips}
										</div>
									</div>}
								</li>
							)) ||
							(item.msgType === "roomtips" && (
								<li key={(item.ackId || item.msgId) + index + '-roomtips'}>
									{<div className='tips-disable'>
										<div className='tips-disable-content'>
											{msgDataObj.tips}
										</div>
									</div>}
								</li>
							)) ||
							((item.msgType === "chat" || item.msgType === "audio") && item.fix === 'right' && (
								<li key={(item.ackId || item.msgId) + index + '-chataudio'}>
									{item.timeText && (
										<div className="time">
											<span>{item.timeText}</span>
										</div>
									)}
									<div className='chat-r-area r-user-info'>
										<div className="content">
											<div className="info">
												<div className="chat-load">
													{item.msgWaitState === 'error' && (
														<img src={errorIcon} alt='' style={{
															width: '22px',
															height: '22px',
															color: '#ff1010',
															position: 'absolute',
															display: 'block',
															top: '50%',
															left: '-0.4rem',
															transform: 'translate(-50%, -50%)',
															pointerEvents: 'auto'
														}} onClick={() => { resendMsg(item) }} />)}
													{(item.msgWaitState === 'loading' || item.msgWaitState === 'reload') && (
														<LoadingIcon />
													)}
												</div>
												{item.msgType === "audio" ? <div className="chat-text chat-audio" style={{ width: widthArr[`${item.audioTime}`] }} onClick={() => { playAudio(item) }}>
													<span dangerouslySetInnerHTML={{ __html: item.msgDataText }} />
													<VoiceIcon right={true} on={playStatus === item.audioUrl} />
												</div> : <div className="chat-text" dangerouslySetInnerHTML={{ __html: item.msgDataText }} />}
											</div>
										</div>
										<span className="arrow" />
										<img src={unisex2} alt="" />
									</div>
								</li>
							)) ||
							((item.msgType === "chat" || item.msgType === "audio" || item.msgType === "couponcardv1") && item.fix === 'left' && (
								<li key={(item.ackId || item.msgId) + index + '-chataudio'}>
									{item.timeText && (
										<div className="time">
											<span>{item.timeText}</span>
										</div>
									)}
									<div className='chat-l-area'>
										<img className='avatar' src={unisex1} alt="" />
										<span className="arrow" />
										<div className="content">
											<div className="info">
												{
													item.msgType === "audio" && (
														<div className='chat-text chat-audio' style={{ width: widthArr[`${item.audioTime}`] }} onClick={() => { playAudio(item) }}>
															<VoiceIcon on={playStatus === item.audioUrl} />
															<span dangerouslySetInnerHTML={{ __html: item.msgDataText }} />
														</div>
													)
												}
												{
													item.msgType === "chat" && (
														<div className='chat-text' dangerouslySetInnerHTML={{ __html: item.msgDataText }} />
													)
												}
												{
													item.msgType === "couponcardv1" && (
														<a className='chat-text chat-coupon' href={ item.couponUrl } target='_blank' rel="noreferrer" onClick={() => handleCouponCardLog(item) } >
															<div className='chat-coupon-text' dangerouslySetInnerHTML={{ __html: t('coupon_message').replace('<#1#>', `<span>${ item.countryCodeToAmounts?.[countryCode] || item.countryCodeToAmounts?.['US'] || '' }</span>`,) }} ></div>
															<img src={ couponImg } alt='coupon' />
														</a>
													)
												}
											</div>
										</div>
									</div>
								</li>
							))
						)
					})
				}
			</ul>
			<audio className="audio-play" controls ref={audioRef} preload="auto" style={{
				position: 'fixed',
				top: '-1000px',
				left: '-1000px',
				opacity: 0
			}} sv="1">
				<source src={audioUrl} type='audio/mpeg' />
			</audio>
		</div>
	)
}

export default connect(state => ({
	chatList: state.chatList,
	controlPermission: state.controlPermission,
}), { updateChatList, updateCloseOperate })(ChatInfoList)
