import React from 'react';
import './index.scss'
import $api from '@/serve/api';
import logo from '@/assets/images/business/logo.png';
import Dialog from '../dialog';
import Loading from './loading';
import i18next from "@/utils/i18n.js"
import { isPc, getUrlParam } from "@/utils/common.js";

const Reportpage = (props) => {
    const linkId = getUrlParam('linkId')
    const role = getUrlParam('role')
    const pc = isPc();

    const [photoItem, setPhotoItem] = React.useState([])
    const [showErr, setShowErr] = React.useState([])
    const [fileList, setFileList] = React.useState([])
    const [showSelect, setShowSelect] = React.useState(false)
    const [canSend, setCanSend] = React.useState(false)
    const [showLoad, setshowLoad] = React.useState(false)
    const [tips, setTips] = React.useState('')
    const [submitData, setSubmitData] = React.useState({
        name: '',
        email: '',
        remail: '',
        type: '',
        issue: ''
    })

    // usememo

    // 跳转到官网
    const jumpWebsite = () => {
        window.open(
            "https://www.lovense.com/",
            "_blank"
        );
    }

    const option = [
        {
            placeholder: i18next.t('report_info_name'),
            name: 'name',
            error: ''
        },
        {
            placeholder: i18next.t('report_info_email1'),
            name: 'email',
            error: i18next.t('report_invalid_email')
        },
        {
            placeholder: i18next.t('report_info_email2'),
            name: 'remail',
            error: i18next.t('report_email_not_match')
        },
        {
            placeholder: i18next.t('report_type'),
            name: 'type',
            error: ''
        },
        {
            placeholder: i18next.t('report_info_description'),
            name: 'issue',
            error: 'content must more than 15 characters'
        }
    ]

    const selectData =[
        i18next.t('user_report_reason2'),
        i18next.t('user_report_reason3'),
        i18next.t('user_report_reason4')
    ]

    const addItem = () => {
        let addBtn = document.getElementById('add-file')
        addBtn.click()
    }

    const delItem = (e) => {
        let arr = [...photoItem]
        arr.splice(e, 1)
        let fileArr = [...fileList]
        fileArr.splice(e, 1)
        setFileList(fileArr)
        setPhotoItem(arr)
        setSend(submitData, arr)
    }

    const setSend = (obj ,arr = photoItem) => {
        if(obj){
            if(!arr.length) return setCanSend(false)
            for (const key in obj) {
                if (Object.hasOwnProperty.call(obj, key)) {
                    const element = obj[key].trim()
                    if(!element) return setCanSend(false)
                }
            }
            setCanSend(true)
        }
    }

    const closeTips = () => {
        if(tips==='success') window.open("about:blank","_self").close()
        setTips('')
    }

    const changeSelect = (e) => {
        if(e && typeof e === 'string') {
            let obj = { ...submitData }
            obj['type'] = e
            setSubmitData(obj)
            setSend(obj)
        }
        setShowSelect(!showSelect)
    }

    const changeInput = (e) => {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        let obj = { ...submitData }
        obj[name] = value
        setSubmitData(obj)
        setSend(obj)
    }

    const sendMsg = async () => {
        if(!canSend) return
        if(showErr.length) return
        try {
            setshowLoad(true)
            let reportType = selectData.indexOf(submitData.type) + 1
            let params = {
                name: submitData.name,
                email: submitData.email,
                content: submitData.issue,
                reportType
            }
            params.reportedRole = `${role}` === '1' ? 1 : 2
            params.linkId = linkId
            let fileData = new FormData();
            for (const e of fileList) {
                fileData.append('files', e);
            } 
            const { data: fileUrl } = await $api.uploadMultipicture(fileData)
            let bodyFormData = new FormData();
            params.medias = fileUrl
            for (const key in params) {
                if (Object.hasOwnProperty.call(params, key)) {
                    const element = params[key];
                    bodyFormData.append(key, element);
                }
            }
            const { message:data } = await $api.controlLinkReport(bodyFormData)
            if(data === 'success'){
                setTips("success")
                setshowLoad(false)
                return
            }
            setshowLoad(false)
            setTips('failed')
        } catch (error) {
            setshowLoad(false)
            setTips(error.message || 'failed')
        }
    }

    const regEmail = (name) => {
        window.scrollTo(0,0)
        let newArr = [...showErr]
        let e = submitData[name]
        if(name === 'remail') {
            if (e !== submitData.email) {
                if(!newArr.includes('remail')) newArr.push(name)
                setShowErr(newArr)
                return 
            }
        }
        if(name === 'email') {
            let reg = /^(\w)+([-.\w+])*@([\w\-#])+((\.\w+)+)$/
            if(submitData.remail && submitData.remail !== e && !newArr.includes('remail')){
                newArr.push('remail')
            }
            if (e === '' || !reg.test(e)) {
                if(!newArr.includes('email')) newArr.push(name)
                setShowErr(newArr)
                return 
            }
            if(submitData.remail === e){
                const num = newArr.indexOf('remail')
                if(num !== -1){
                    newArr.splice(num, 1)
                }
            }
        }
        if(name === 'issue'){
            if(e.length <= 15) {
                if(!newArr.includes('issue')) newArr.push(name)
                setShowErr(newArr)
                return
            }
        }
        const num = newArr.indexOf(name)
        if(num !== -1){
            newArr.splice(num, 1)
        }
        setShowErr(newArr)
    }

    const upImgSize = (e, size = 3) => {
        let flag = false;
        let file = e.target.files[0];
        if (file.size <= 1024 * 1024 * size) {
            flag = true;
        } else {
            flag = false;
        }
        return flag
    }

    const uploadEvent = (info) => {
        var file = info.target.files[0];
        if(!file) return
        if (!upImgSize(info, 3)) {
            setTips(i18next.t('add_photo_maxsize'))
            let addBtn = document.getElementById('add-file')
            if(addBtn) addBtn.value = ''
            return 
        }
        var reads= new FileReader();
        reads.readAsDataURL(file);
        reads.onload = (e) => {
            let arr = [...photoItem]
            arr.push({
                src: e.currentTarget.result,
                id: photoItem.length
            })
            setFileList([...fileList, file])
            setPhotoItem(arr)
            setSend(submitData, arr)
            let addBtn = document.getElementById('add-file')
            if(addBtn) addBtn.value = ''
        }
    }

    return (
        <div className={pc?"pc-report-page":"report-page"}>
            {showLoad && <Loading />}
            <div className="report-header" onClick={jumpWebsite}>
                <img src={logo} alt="" />
            </div>
            <div className='report-title'>
                {i18next.t('patterns_menu_report')}
            </div>
            <div className='report-content'>
                <div className="title">
                    {i18next.t('report_page_title')}
                </div>
                {
                    option.map((data, i) => {
                        return (
                            <div className="input-item" key={i}>
                                {data.name !== 'type' ? <input onBlur={()=>regEmail(data.name)}  name={data.name} onChange={changeInput} placeholder={data.placeholder} />:(
                                    <div onClick={changeSelect} className='select-item' style={{height:'100%'}}>
                                        <span className={`select ${submitData.type&&'onselect'}`}>{submitData.type|| i18next.t('report_type')}</span>
                                        <i className={`select-icon ${showSelect && 'rotate'}`} />
                                        <div className={`select-box ${showSelect && 'show'}`}>
                                            {selectData.map((row, index) => (
                                                <div className={`select-data ${row===submitData.type && 'on'}`} onClick={()=>changeSelect(row)} key={index}>{row}</div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {showErr.includes(data.name) && (<div className='err-tips'>{data.error}</div>)}
                            </div>
                        )
                    })
                }
                <div className="photo-list">
                    {
                        photoItem.map((e, i) => {
                            return (<div className="photo-item" key={i}>
                                <img className='photo' src={e.src} alt={e.id} />
                                <i className='close-icon' onClick={() => delItem(i)} />
                            </div>)
                        })
                    }
                    {photoItem.length !== 5 && (<div className="photo-item upload" onClick={addItem}>
                        <input id='add-file' accept="image/*" type="file" onChange={uploadEvent} />
                        <div className='up-img'></div>
                        <div className='text'>
                            {photoItem.length?`${photoItem.length}/5`:i18next.t('button_add_photo')}
                        </div>
                    </div>)}
                </div>
                <div className={`send-btn ${canSend && !showErr.length && 'on'}`} disabled={!canSend} onClick={sendMsg}>Send</div>
            </div>
            <Dialog content={tips} closeTips={closeTips} />
        </div>
    );

}

export default Reportpage;
