import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import 'lib-flexible';
import './assets/styles/index.scss';
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReportPage from "@/components/report-page";
import Vconsole from '@/utils/vconsole.js';
console.log(Vconsole);

const routerArr = window.location.pathname.split('/').filter(row=>(row&&row!=='c2'&&row!=='v2'))
const hasReport = routerArr.includes('report')
const hasT2 = routerArr.includes('t2')

const pathData = {
    development: '',
    test: '/c2',
    production: '/v2',
}

ReactDOM.render(
    <Provider store={store}>
      <Router  path="/" component={App}>
      {
        hasReport? <Route path={`${pathData[process.env.REACT_APP_ENV]}/report`} exact component={ReportPage} />:
        <>
            {
                hasT2? <Route path={`/t2/:code`} exact component={App} />:
                <>
                    <Route path={`${pathData[process.env.REACT_APP_ENV]}/:code`} exact component={App} />
                    <Route path={`${pathData[process.env.REACT_APP_ENV]}/:linkId/:code`} exact component={App} />
                </>
            }
        </>
      }
      </Router>
    </Provider>,
  document.getElementById('root')
);

