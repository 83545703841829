import { v4 as uuidv4 } from 'uuid';
import aesUtils from '@/utils/aes/aes-utils';

const isAndroid = ()=>{
  const res = navigatorInfo();
  return res.android;
}

const isIphone = ()=>{
  const res = navigatorInfo();
  return res.iPhone;
}

const isMac = ()=>{
  return /macintosh|mac os x/i.test(navigator.userAgent)
}

const isPc = ()=>{
  let res = /Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent);
  if(res){
    return false;
  }
  return true;
}

const getDevice = () => {
  let webLog = {}
  let userAgent = navigator.userAgent
  let m1 = userAgent.match(/MicroMessenger.*?(?= )/)
  if (m1 && m1.length > 0) {
    webLog.wechat = m1[0]
  }
  if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
    if (userAgent.includes('iPad')) {
      webLog.device = 'iPad'
    } else {
      webLog.device = 'iPhone'
    }
    m1 = userAgent.match(/iPhone OS .*?(?= )/)
    if (m1 && m1.length > 0) {
      webLog.system = m1[0]
    }
  }
  if (userAgent.includes('Android')) {
    m1 = userAgent.match(/Android.*; ?(.*(?= Build))/)
    if (m1 && m1.length > 1) {
      webLog.device = m1[1]
    }
    m1 = userAgent.match(/Android.*?(?=;)/)
    if (m1 && m1.length > 0) {
      webLog.system = m1[0]
    }
  }
  return webLog.device || ''
}

const navigatorInfo = () => {
  const u = navigator.userAgent;
  return {
    trident: u.indexOf("Trident") > -1 /*IE内核*/,
    presto: u.indexOf("Presto") > -1 /*opera内核*/,
    webKit: u.indexOf("AppleWebKit") > -1 /*苹果、谷歌内核*/,
    gecko:  u.indexOf("Gecko") > -1 && u.indexOf("KHTML") === -1 /*火狐内核*/,
    mobile: !!u.match(/AppleWebKit.*Mobile.*/) /*是否为移动终端*/,
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) /*ios终端*/,
    android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 || (u.indexOf("Safari") > -1 && (u.indexOf("Chrome") > -1)) /*android终端或者uc浏览器或者华为浏览器*/,
    iPhone: u.indexOf("iPhone") > -1 /*是否为iPhone或者QQHD浏览器*/,
    iPad: u.indexOf("iPad") > -1 /*是否iPad*/,
    webApp: u.indexOf("Safari") === -1 /*是否web应该程序，没有头部与底部*/,
    souyue: u.indexOf("souyue") > -1,
    superapp: u.indexOf("superapp") > -1,
    weixin: u.toLowerCase().indexOf("micromessenger") > -1,
    Safari: u.indexOf("Safari") > -1,
  };
}

// 转换时分秒显示
const formatSeconds = (time) => {
  let result = parseInt(time)
  let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
  let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
  let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
  if (Number(h) > 0) {
      return `${h}:${m}:${s}`;
  }else{
      return `${m}:${s}`;
  }
}

const firstUpper = (str)=>{
  if(str){
    let c = (str + "").substring(0,1).toUpperCase();
    let c2 = (str + "").substring(1);
    str = c + c2;
  }
  return str;
}

//构造聊天消息体
const getMsgBody = (linkId, msgData, msgType='controllink', toId) => {
  return {
    ackId: uuidv4(),
    dateImType: 'control_link',
    dateImTypeData: linkId,
    msgData: aesUtils.aesEncryptXy(JSON.stringify(msgData)),
    msgType: msgType,
    msgVer: 8,
    toId: toId,
  }
}

//生成msgId
const generateMsgId = () => {
  const now = new Date().getTime();
  return now.toString(36).slice(0, 6) + now;
};

//支持lds功能的玩具
const supportLdsToy = (type) => {
  // const arr = ["nora","max","calor","xmachine","mission","lapis","solace"];
  const arr = ["nora","max","calor","xmachine","mini xmachine","mission","lapis"];
  let str = (type + "").toLowerCase();
  if(arr.indexOf(str) >= 0){
    return true;
  }
  return false;
}

const getUrlParam = (queryName, url) => {
  let query = "";
  if(url){
    query = decodeURI(url.substring(1)); // ?a=1
  }else{
    query = decodeURI(window.location.search.substring(1)); //https://baidu.com?a=1
  }
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] === queryName) {
      return pair[1];
    }
  }
  return null;
}


const copyText = (copyUrl) => {
  const node = document.createElement('input')
  document.body.appendChild(node)
  node.setAttribute('value', copyUrl)
  if (!node) {
    return
  }
  var result
  var tempTextarea = document.createElement('textarea')
  document.body.appendChild(tempTextarea)
  if (typeof node == 'object') {
    if (node.value) {
      tempTextarea.value = node.value
    } else {
      tempTextarea.value = node.innerHTML
    }
  } else {
    tempTextarea.value = node
  }
  var u = navigator.userAgent
  if (u.match(/(iPhone|iPod|iPad);?/i)) {
    window.getSelection().removeAllRanges()
    var range = document.createRange()
    range.selectNode(tempTextarea)
    window.getSelection().addRange(range)
    result = document.execCommand('copy')
    window.getSelection().removeAllRanges()
  } else {
    tempTextarea.select()
    result = document.execCommand('Copy')
  }
  document.body.removeChild(tempTextarea)
  document.body.removeChild(node)
  if (result) {
    console.log('success copy')
  } else {
    console.log('fail copy')
    navigator?.clipboard?.writeText(copyUrl)
  }

  return result
}

function urlToObj(url) {
  const obj = {};
  const [path, search] = url.split('?')
  obj.path = path
  const searchObj = {}
  search.trim().split('&').forEach(item => {
    const [key, value] = item.split('=')
    searchObj[key] = value
  });
  obj.search = searchObj
  return obj;
}
export { isAndroid,isIphone,isPc,isMac,getDevice,formatSeconds,firstUpper,getMsgBody,generateMsgId,supportLdsToy,getUrlParam,copyText,
  urlToObj,
}
