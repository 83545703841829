import React from 'react'
import './index.scss'
import { connect } from 'react-redux'
import { useRef, useEffect, useState, useMemo } from "react"
import drawCurve from '@/utils/draw-line.js'
import toysArr from '@/utils/toy.js'
import { useTranslation } from 'react-i18next'
const OrderLine = (props) => {
  const { t } = useTranslation()
  let { toyStatus, orderLine } = props
  const boardCanvasArr = useRef([[],[]])
  const setCanvasRefs = (i, j, el) => {
    if(boardCanvasArr.current[i][j] || !el) return
    boardCanvasArr.current[i][j] = el
  }
  const lineColor = useMemo(() => ['#FF2D89', '#0C9EF2', '#DE1EDB'], []) // 红蓝紫
  let lineArr = []
  if (toyStatus && toyStatus.length > 0) {
    let top = toyStatus.length === 1 ? '30px' : '0px'
    for(let r = 0; r < toyStatus.length; r++){
      let olJson = toyStatus[r];
      let toyType = toysArr[(olJson.type + "").toLowerCase()] || olJson.type
      let toyName = toyType + (olJson.version ? (" " + olJson.version) : "")
      let statusStr = ""
      if ((olJson.status + "") === "false") {
        statusStr = (<span style={{ color: "#FF3E3E" }}> ({t('toy_reconnecting')})</span>)
      }

      let str = <div className="line-item" key={`line-area-${r+1}`}>
        <div className="toy-name">{toyName}{statusStr}</div>
        {
          [2, 1, 0].map(i => {
            return <canvas key={`cvs-line${r}${i+1}`} className="board-canvas" style={{ width: "100%", top }} ref={el=>setCanvasRefs(r,i,el)}></canvas>
          })
        }
      </div>
      lineArr.push(str)
    }
  }

  const [maxPointNum, setMaxPointNum] = useState(60)
  useEffect(() => {
    const onResize = () => {
      var pc_order_line = document.querySelector('.pc-order-lines')
      // 每6个像素设一个点
      setMaxPointNum(Math.round(pc_order_line.offsetWidth / 6))
    }
    onResize()
    window.addEventListener('resize', onResize, false)

    if (orderLine && orderLine.length) {
      for (let i = 0; i < orderLine.length; i++) {
        if (boardCanvasArr.current[i][0]) {
          const { clientWidth: width, clientHeight: height } = boardCanvasArr.current[i][0]
          boardCanvasArr.current[i].forEach(item => {
            item.width = width
            item.height = height
          })
          let lineObj = orderLine[i]
          Object.keys(lineObj).forEach(function(key) {
            if (key.includes('line')) { // line1, line2, line3
              let idx = key.slice(-1) * 1  // 1, 2, 3
              let lineArr = lineObj[key]
              if (lineArr.length > 1) {
                if (lineArr.length > maxPointNum) { //截取最后maxPointNum个点画线(刚好一屏)
                  lineArr = lineArr.slice(lineArr.length - maxPointNum)
                }
                let pts = handleLineData(lineArr, width, height)
                const ctx = boardCanvasArr.current[i][idx - 1].getContext("2d")
                ctx.clearRect(0, 0, width, height)
                let lineWidth = 1.4
                let smoothLevel = 0
                let color = lineColor[idx - 1]
                if (idx === 2) {
                  if (lineObj.toyFun.includes('f') || lineObj.toyFun === 'p' ) { // f指令线条为紫色
                    color = lineColor[2]
                  } else if (lineObj.toyFun.includes('d') || lineObj.toyFun.includes('pos') || lineObj.toyFun === 'o') { // d指令线条为蓝色
                    color = lineColor[1]
                  } else if (lineObj.toyFun.includes('t')) { // t指令线条为粉色
                    color = lineColor[0]
                  }
                }
                drawCurve(ctx, pts, smoothLevel, color, lineWidth, true, false)
              }
            }
          })
        }
      }
    }

    return function () {
      window.removeEventListener('resize', onResize, false)
    }
  }, [lineColor, maxPointNum, orderLine])

  return (
    <>
      <div className="pc-order-lines">
        {
          lineArr
        }
      </div>
    </>
  )
}
//生成曲线x、y坐标
function handleLineData (lineArr, width, height) {
  let arr = []
  for (let i = 0; i < lineArr.length; i++) {
    let x = i * 6
    let y = parseInt((20 - lineArr[i]) * 3.6 + 34)
    arr.push(x, y)
  }
  return arr
}

export default connect(state => ({ orderLine: state.orderLine }), {})(OrderLine)
