import {JOINER_INFO} from "../constant"
const joinerInfo = (preState = {}, action) => {
    const {type, data} = action
    switch (type) {
        case JOINER_INFO:
            return  {...data}
        default:
            return preState
    }
}

export default joinerInfo
