import { ENDCHAT_AD_REQUESTID } from "../constant";

const endchatAd = (preState = {}, action) => {
    //从action对象中获取：type、data
    const {type, data} = action
    switch (type) {
        case ENDCHAT_AD_REQUESTID:
            return {...preState, requestId: data}
        default:
            return preState
    }
}

export default endchatAd;
