import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import levelImg0 from '@/assets/images/business/level0.png';
import levelImg1 from '@/assets/images/business/level1.png';
import levelImg2 from '@/assets/images/business/level2.png';
import levelImg3 from '@/assets/images/business/level3.png';
import levelImg4 from '@/assets/images/business/level4.png';
import control_dir_0 from '@/assets/images/business/control_dir_0.png';
import control_dir_1 from '@/assets/images/business/control_dir_1.png';
import control_dir_2 from '@/assets/images/business/control_dir_2.png';
import LdsBalls from './lds-balls/index.js'
import './index.scss';
import { useTranslation } from 'react-i18next'
import i18next from "@/utils/i18n.js"
import { supportLdsToy } from '@/utils/common.js'

//long distance sex模式，即摇晃玩具的玩法
const LdsControl = (props) => {
  const { t } = useTranslation();
  const { linkId, toysData, joinerToy, isEnd, socketServiceInstanct, ldsJoinerToyList, ldsCreatorToyList, ldsControlType, ldsJoinerToyLevel, ldsCreatorToyLevel, setLdsJoinerToyLevel, isSupportTouchPanel} = props;

  const [txt, setTxt] = useState(i18next.t('sync_control_controller'));
  useEffect(()=>{
    if(ldsControlType === 2) {
      setTxt(i18next.t('sync_control_controller'))
    } else{
      setTxt(i18next.t('sync_control_controllee'))
    }
  },[ldsControlType])

  // 判断玩具列表中是否有具备感应模式的玩具
  const hasInteractToy = (toys) => {
    return toys.some(item => supportLdsToy(item.type))
  }

  // 是否显示手势控制球，0：不显示  1：显示在创建者区域(且需显示控制面板)  2：显示在加入者区域
  const [showGesture, setShowGesture] = useState(0)
  useEffect(() => {
    if (isSupportTouchPanel && ldsControlType === 0) { // 互控模式下
      if (!hasInteractToy(ldsCreatorToyList) && hasInteractToy(ldsJoinerToyList)) { // Me: 无感应玩具 && Joiner: 有感应玩具
        setShowGesture(1) // Me 显示手势球
      } else if (hasInteractToy(ldsCreatorToyList) && !hasInteractToy(ldsJoinerToyList)) { // Me: 有感应玩具 && Joiner: 无感应玩具
        setShowGesture(2) // Joiner 显示手势球
      } else {
        setShowGesture(0)
      }
    } else {
      setShowGesture(0)
    }
  }, [isSupportTouchPanel, ldsControlType, ldsCreatorToyList, ldsJoinerToyList])

  const getToyAreaClass = (role) => {
    let classStr = "toy-area";
    if(role === "joiner") {
      if(ldsJoinerToyList.length > 1 && showGesture === 2){
        classStr += " small-toy";
      }
    } else if(role === "creator"){
      if(ldsCreatorToyList.length > 1 && showGesture === 1){
        classStr += " small-toy";
      }
    }
    return classStr;
  }
  const getDirImg = () => {
    if(ldsControlType === 0){
      return control_dir_0;
    }else if(ldsControlType === 1){
      return control_dir_1;
    }else if(ldsControlType === 2){
      return control_dir_2;
    }
    return control_dir_0;
  }
  const getToyLevelImg = (position, toyId) => {
    let ldsLevel = null
    if(position === 'left'){
      ldsLevel = ldsJoinerToyLevel;
    }else if(position === 'right'){
      ldsLevel = ldsCreatorToyLevel;
    }
    const ldsLevelObj = ldsLevel.all || ldsLevel[toyId]
    if(ldsLevelObj){
      let levelArr = []
      for (let order in ldsLevelObj) {
        let level = +ldsLevelObj[order]
        if (order === 'p' && level >= 0) { // p指令的值为0-3
          level = [0, 6, 14, 20][level] || 0
        }
        levelArr.push(level)
      }
      const maxLevel = Math.max(...levelArr)
      if(maxLevel <= 0){
        return levelImg0;
      }else if(maxLevel <= 5){
        return levelImg1;
      }else if(maxLevel <= 10){
        return levelImg2;
      }else if(maxLevel <= 15){
        return levelImg3;
      }else if(maxLevel <= 20){
        return levelImg4;
      }
    }
    return levelImg0;
  }
  return (
    <div className="lds-control">
      <div className={showGesture === 1 ? 'lds-control-induced small-control' : 'lds-control-induced'}>
        <div className={getToyAreaClass('joiner')}>
          <div className="toy-info">
            {
              ldsJoinerToyList && ldsJoinerToyList.length > 0 && ldsJoinerToyList.map((item, index) => {
                let path
                try {
                  path = require("@/assets/images/business/toys_icon/" + item.toyName + ".png").default;
                  if(!item.selected){
                    path = require("@/assets/images/business/toys_icon/" + item.toyName + "_gray.png").default;
                  }
                } catch (e) {
                  path = require("@/assets/images/business/toys_icon/noType.png").default;
                  if(!item.selected){
                    path = require("@/assets/images/business/toys_icon/noType_gray.png").default;
                  }
                }
                return (
                  <div key={item.toyId} className="toy-item">
                    <img className="toy-img" src={path} alt=""/>
                    <img className="level" src={getToyLevelImg('left', item.toyId)} alt=""/>
                  </div>
                );
              })
            }
            {
              showGesture === 2 && (
                <div className="toy-item">
                  <img className="toy-img" src={require('@/assets/images/business/toys_icon/lds_control.png').default} alt=""/>
                </div>
              )
            }
          </div>
          { showGesture !== 1 && <div className="role">{txt}</div> }
        </div>
        <div className={showGesture === 1 ? 'con-dir' : 'con-dir m-t'}>
          <img src={getDirImg()} alt=""/>
        </div>
        <div className={getToyAreaClass('creator')}>
          <div className="toy-info">
            {
              showGesture === 1 && (
                <div className="toy-item">
                  <img className="toy-img" src={require('@/assets/images/business/toys_icon/lds_control.png').default} alt=""/>
                </div>
              )
            }
            {
              ldsCreatorToyList && ldsCreatorToyList.length > 0 && ldsCreatorToyList.map((item, index) => {
                let path
                try {
                  path = require("@/assets/images/business/toys_icon/" + item.toyName + ".png").default;
                  if(!item.selected){
                    path = require("@/assets/images/business/toys_icon/" + item.toyName + "_gray.png").default;
                  }
                } catch (e) {
                  path = require("@/assets/images/business/toys_icon/noType.png").default;
                  if(!item.selected){
                    path = require("@/assets/images/business/toys_icon/noType_gray.png").default;
                  }
                }
                return (
                  <div key={item.toyId} className="toy-item">
                    <img className="toy-img" src={path} alt=""/>
                    <img className="level" src={getToyLevelImg('right', item.toyId)} alt=""/>
                  </div>
                );
              })
            }
          </div>
          { showGesture !== 1 && <div className="role">{t('common_me')}</div> }
        </div>
      </div>
      {
        showGesture === 1 && (
          <LdsBalls 
            linkId={linkId}
            toysData={toysData}
            joinerToy={joinerToy}
            isEnd={isEnd}
            setLdsJoinerToyLevel={setLdsJoinerToyLevel}
            socketServiceInstanct={socketServiceInstanct}>
          </LdsBalls>
        )
      }
    </div>
  )
}

export default connect(state => ({}), {})(LdsControl);
