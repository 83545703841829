// api接口
import request from "../utils/request"
import config from "@/config/index"
import { isPc, isAndroid, isMac, getDevice } from "@/utils/common.js";
import { v4 as uuidv4 } from 'uuid';
const pako = require('pako');

const api = {
    //判断controllink状态是否有效
    anonControllinkStatus(params) {
        return request.post(config.baseUrl + "/anon/controllink/status", params)
    },
    //激活一个control link (多次使用的长连接)
    longtimecontrollink(params) {
        return request.post(config.baseUrl + "/anon/longtimecontrollink/init", params)
    },
    //主控方点击controlLink加入控制的接口
    anonControllinkJoin(params) {
        return request.post(config.baseUrl + "/anon/controllink/join", params)
    },
    //被控方进入控制页面请求的接口 (用于移动端connect app嵌套网页controlLink)
    anonCreatorOpenRoom(params) {
        return request.post(config.baseUrl + "/anon/connect_creator_open_room", params)
    },

    //获取结束页信息
    nhOrderControlLink(params) {
        return request.post(
            config.expiredHost + "/nh-order/control/link",
            JSON.stringify(params),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
    },

    //获取结束页信息(新版本)
    getRemoteControlLinkEventResource(params) {
        let formData = new FormData()
        formData.append("lang", params.lang)
        formData.append("creator", params.creator)
        return request.post(
            config.reportUrl + "/get_remote_control_link_event_resource",
            formData,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
    },

    // 获取后台管理系统配置的 Control Link 广告
    getAdContent(params) {
        return request.post(config.reportUrl + "/control_link_advertisement_content", params)
    },

    // 获取 Remote 4个最新版的下载链接
    getRemoteDownloadUrl() {
        return request.get(config.reportUrl + "/api/remote_last_update_version")
    },


    //获取问卷
    getSurvey() {
        return request.get(config.reportUrl + "/app/survey/get?module=CONTROL_LINK")
    },
    //上传聊天音频文件
    uploadAudio(params) {
        return request.post(
            config.reportUrl + "/link/webchat/audio/upload",
            params,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
    },
    //获取翻译信息
    getLangControlLink(params) {
        return request.post(
            config.getLangUrl + "/date-web-api/appTranslation/controlLink/getLang",
            JSON.stringify(params),
            {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
    },
    // 网页端获取基础数据
    getCommonBaseData(params) {
        return request.get(config.reportUrl + "/api/h5_common_base_data")
    },
    // 多文件上传
    uploadMultipicture(params) {
        return request.post(
            config.reportUrl + "/remote/report-user/uploadMultipicture",
            params,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
    },
    // 举报controllink到freshdesk
    controlLinkReport(params) {
        return request.post(
            config.reportUrl + "/remote/controllink/report",
            params,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
    },
    logsNewV2(params) {
      let text = [JSON.stringify(params)]
      if(!window.localStorage.deviceId) window.localStorage.deviceId = uuidv4()
      let deviceId = window.localStorage.deviceId
      let data = `platform=${isPc()?`desktop-${isMac()?'mac':'win'}`:(isAndroid()?'android':'ios')}&appType=ControlLinkJS&text=${JSON.stringify(text)}&version=2.0.0&openId=${uuidv4()}&deviceId=${deviceId}&sessionId=${deviceId}&model=${getDevice()}`
      console.log('data-----',data)
      let binaryArray = pako.gzip(data);
      let url = config.logUrl + "/wear/logsNewV2";
      try{
        var oReq = new XMLHttpRequest();
        oReq.open("POST", url, true);
        oReq.setRequestHeader('Content-Encoding','gzip');
        oReq.onload = function (oEvent) { };
        oReq.send(binaryArray);
      }catch(e){
        console.error('error-->' + JSON.stringify(e));
      }
    },
}

export default api
