import './index.scss'
import { connect } from "react-redux"
import emojis from '@/assets/images/business/emojis.png'
import expressionList from '@/utils/expressin'
import SocketEvent from '@/serve/socket-event'
import aesUtils from '@/utils/aes/aes-utils'
import { updateChatList } from "@/redux/action/chat-list"
import $validate from '@/utils/validate'
import { updateCloseOperate } from "@/redux/action/close-operate"
import React, { useEffect, useRef, useState, useCallback } from "react"
import ChatList from "@/components/chat-list"
import { getMsgBody, generateMsgId } from "@/utils/common.js"
import { useTranslation } from 'react-i18next'
import config from "@/config/index"

const ChatArea = (props) => {
	const { t } = useTranslation()
	const {
		socketServiceInstanct,
		userId,
		linkId,
		joinerFirstTime,
		chatInfo,
		chatList,
		// userOnline,
		endEvent,
		updateUserId,
		updateChatList,
		joinerFirstTimeChange,
		closeOperateStatus,
		// redux关闭操作面板
		updateCloseOperate,
		time,
		stop,
		reachMax,
		closeTime
	} = props
	//存储发送信息
	const [storeSendInfo, setStoreSendInfo] = useState("")
	//是否是第一次进入
	const [isFirst, setIsFirst] = useState(false)
	useEffect(() => {
		let str = joinerFirstTime + ""
		if (str === "false" || str === "true") {
			setIsFirst(joinerFirstTime)
		}
	}, [joinerFirstTime])
	//第一次进入页面初始数据需要做socket发送成功，等待服务器提示成功后，将该数据存储进聊天列表
	const [chatInfoG, setChatInfoG] = useState("")
	useEffect(() => {
		if (chatInfo) {
			setChatInfoG(chatInfo)
		}
	}, [chatInfo])


	const [tipsOnline, setTipsOnline] = useState(-1); // 提示在线/不在线 -1默认 0不在线 1在线
	const [disconnectedTimes, setDisconnectedTimes] = useState(0); // 断连次数
	const DISCONNECTED_TIPS_TIMES = 3
	const userOnlineChangeCallback = useCallback((userOnline)=>{
		console.log(`--userOnline: `, userOnline);
		let onlineText = userOnline ? 'reconnected' : 'disconnected'
		const tipsStr = `The other user is ${onlineText}.`
		// console.log(`userOnline tipsStr: `, tipsStr);
		const msgBody = [{...getMsgBody(linkId, { tips: tipsStr }, 'tips', `${onlineText}-${userId}`), createTime: Date.now(), msgId: generateMsgId()}]
		console.log(`--userOnline disconnectedTimes: `, disconnectedTimes);
		if (userOnline) { // 在线
			if(tipsOnline === 0) { // 上次提示了断线
				setTipsOnline(1)
				updateChatList(msgBody)
			}
			if(disconnectedTimes > 0) setDisconnectedTimes(0)
		} else { // 断线
			if(tipsOnline) { // 上次提示了在线
				if(disconnectedTimes >= DISCONNECTED_TIPS_TIMES){ // 推送了两次断连了
					setTipsOnline(0)
					updateChatList(msgBody)
				}
			}
			if(disconnectedTimes < DISCONNECTED_TIPS_TIMES) setDisconnectedTimes((pre) => pre+1)
		}
	},[userId, linkId, updateChatList, disconnectedTimes, tipsOnline])

	useEffect(() => {
		//返回对方用户是否在线的查询结果
		const ackQueryUserOnlineInfoTcEvent = (res) => {
			console.log(`--userOnline watch: `, res)
			userOnlineChangeCallback(res.onLine)
		}
		socketServiceInstanct.on(SocketEvent.ACK_QUERY_USER_ON_LINE_INFO_TC, ackQueryUserOnlineInfoTcEvent);
		return function () {
			socketServiceInstanct.off(SocketEvent.ACK_QUERY_USER_ON_LINE_INFO_TC, ackQueryUserOnlineInfoTcEvent);
		}
	}, [socketServiceInstanct, userOnlineChangeCallback]);


	const [chatListData, setChatListData] = useState([])
	useEffect(() => {
		if (!$validate.isNull(chatList)) {
			setChatListData(chatList)
			localStorage.setItem('chatList', JSON.stringify(deWeight(chatListData)))
		}
	}, [chatList, chatListData])
	const textareaValueRef = useRef()
    const errorListTime = useRef({});
	const [textareaValue, setTextareaValue] = useState(null) //输入框
	const [isExpression, setIsExpression] = useState(false)  //表情面板
	const [caretPos, setCaretPos] = useState({ start: 0, end: 0 })  //存储光标位置
	const [isPositionState, setIsPositionState] = useState(false) //是否获取光标状态（true/false）
	const [msgId, setMsgId] = useState(false)                //聊天消息对应id，拉去数据处理
	const [isShowQrcode, setIsShowQrcode] = useState(false)
	// 打开手机键盘
	const openKeyboard = () => {
		//表情面板
		setIsExpression(!isExpression)
		//是否获取光标状态（true/false）
		setIsPositionState(true)
		// 二维码
		setIsShowQrcode(!isShowQrcode)
		// 关闭操作面板
		updateCloseOperate(true)
		//先失焦再聚焦
		textareaValueRef.current.blur()
		textareaValueRef.current.focus()
	}
	// 获取光标位置
	const getPositionForTextArea = (e) => {
		let CaretPos = {
			start: 0,
			end: 0
		}
		const getSelect = getSelection()
		const ctrl = getSelect.getRangeAt(0)
		if (ctrl.startOffset || ctrl.endOffset) {
			if (ctrl.startOffset) {  //Firefox support
				CaretPos.start = ctrl.startOffset
			}
			if (ctrl.endOffset) {
				CaretPos.end = ctrl.endOffset
			}
		}
		if (textareaValue && e) {
			const eq = [].indexOf.call(e.target.childNodes, getSelect.anchorNode)
			let arr1 = textareaValue.split('[').filter(e => e !== '').map(e => `[${e}`)
			let arr = arr1.map(row => row.split(']').filter(e => e !== '').map((e) => {
				return e.includes('[') ? `${e}]` : e
			}))
			let newArr = []
			arr.forEach(row => newArr.push(...row))
			newArr = newArr.slice(0, eq)
			const num = newArr.join().replace(/,/g, '').length
			if (ctrl.startOffset) {  //Firefox support
				CaretPos.start = ctrl.startOffset + num
			}
			if (ctrl.endOffset) {
				CaretPos.end = ctrl.endOffset + num
				if (ctrl.commonAncestorContainer.nodeValue) {
					const trimStr = ctrl.commonAncestorContainer.nodeValue.substring(0, ctrl.endOffset)
					let a = trimStr.split('').map(row => row.trim() ? row : '123456')
					const trimNum = a.join().replace(/,/g, '').length
					if (trimNum) CaretPos.end = trimNum + num
				}
			}
		}
		return (CaretPos)
	}
	// 打开表情面板
	const chooseExpressin = () => {
		setIsPositionState(false)
		setIsExpression(!isExpression)
		updateCloseOperate(true)
	}
	// 去重
	const deWeight = (list = []) => {
		let arr = JSON.parse(JSON.stringify(list))
		if (arr.length === 0) return arr
		for (let i = 0; i < arr.length - 1; i++) {
			for (let j = i + 1; j < arr.length; j++) {
				if (arr[i].msgId === arr[j].msgId) {
					if (arr[i].msgWaitState === '' || arr[j].msgWaitState === '') {
						arr[i].msgWaitState = ''
					} else {
						if (arr[i].msgWaitState === 'reload' || arr[j].msgWaitState === 'reload') {
							arr[i].msgWaitState = 'reload'
						} else if (arr[i].msgWaitState === 'error' || arr[j].msgWaitState === 'error') {
							arr[i].msgWaitState = 'error'
						}
					}
					arr.splice(j, 1)
					//因为数组长度减小1，所以直接 j++ 会漏掉一个元素，所以要 j--
					j--
				}
			}
		}
		return arr
	}
	// 获取输入框处理
	const handleValueChange = (e) => {
		setIsPositionState(true)
		setTextareaValue(imgToEmoji(e.target.innerHTML))
		let position = getPositionForTextArea(e) // 光标的位置
		setCaretPos(position)
		inputScrollBottom()
		updateCloseOperate(true)
	}
	// 获取输入框焦点
	const handleValueFocus = (e) => {
		setIsExpression(false)
		setIsPositionState(true)
		inputScrollBottom()
	}
	// 把输入框的表情包文本换成图片显示
	const innerTextareaHtml = (str) => {
		const reg = /\[(.+?)\]/g
		let list = []
		let result = null
		let innerHTML = str
		do {
			result = reg.exec(str)
			result && list.push(result[1])
		} while (result)
		list.forEach(row => {
			try {
				const data = expressionList[expressionList.map(item => item.key).indexOf(row)]
				const emoji = `<img src=${require('@/assets/images/business/emoji/' + data.emoji).default} alt=${data.key} />`
				innerHTML = innerHTML.replace(`[${row}]`, emoji)
			} catch (error) {
				console.log(error)
			}
		})
		textareaValueRef.current.innerHTML = innerHTML
	}
	// 把输入框的表情包图片换成文本保存
	const imgToEmoji = (str) => {
		const reg = /alt="(.+?)"/g
		let list = []
		let result = null
		let innerHTML = str
		do {
			result = reg.exec(str)
			result && list.push(result[1])
		} while (result)
		list.forEach(row => {
			const emoji = new RegExp("\\<img(.+?)" + row + "(.+?)>")
			innerHTML = innerHTML.replace(emoji, `[${row}]`)
		})
		return innerHTML
	}
	// 添加表情
	const expressionHandler = (data) => {
		setIsPositionState(false)
		const keyText = `[${data.key}]`
		// let imgTag = `<img class='emoji' src=${require('@/assets/images/business/emoji/' + data.emoji).default} alt=''/>`;
		if (textareaValue) {
			// let leftTxt = textareaValue.slice(0, caretPos.end);
			// let rightTxt = textareaValue.slice(caretPos.end);
			// let str = leftTxt + imgTag + rightTxt;
			// textareaValueRef.current.innerHTML = str;
			// setTextareaValue(str);
			const text = textareaValue.replace(/ /g, '&nbsp;')
			let leftTxt = text.slice(0, caretPos.end)
			let rightTxt = text.slice(caretPos.end)
			if (leftTxt.includes('[')) {
				let arr = leftTxt.split('[')
				let str = arr.pop()
				if (!str.includes(']')) {
					let right = rightTxt.split(']')
					leftTxt = leftTxt + right[0] + ']'
					rightTxt = rightTxt.substring(right[0].length + 1, rightTxt.length)
				}
			}
			const str = `${leftTxt}${keyText}${rightTxt}`
			// textareaValueRef.current.innerHTML = innerHTML;
			innerTextareaHtml(str)
			setTextareaValue(str)
		} else {
			// textareaValueRef.current.innerHTML = imgTag;
			// setTextareaValue(imgTag);
			innerTextareaHtml(keyText)
			setTextareaValue(keyText)
		}
		// let caretPosObj = caretPos;
		// caretPosObj.start = caretPosObj.start + imgTag.length;
		// caretPosObj.end = caretPosObj.end + imgTag.length;
		let caretPosObj = caretPos
		caretPosObj.start = caretPosObj.start + keyText.length
		caretPosObj.end = caretPosObj.end + keyText.length
		// 原来光标位置加上表情长度，重新更新存储光标位置
		setCaretPos(caretPosObj)
		inputScrollBottom()
		updateCloseOperate(true)
	}
	useEffect(() => {
		textareaValueRef.current.focus()
	}, [textareaValueRef, storeSendInfo])

	// 删除聊天
	const delChatText = () => {
		if (!textareaValue) {
			return
		}
		let str = textareaValue.trim().slice(0, textareaValue.length - 1)
		// textareaValueRef.current.innerHTML = str;
		innerTextareaHtml(str)
		setTextareaValue(str)
	}
	// 格式化输入信息
	const formatString = (str) => {
		if (!str) return ''
		let val = str
		val = val
			.replace(/<div>/g, '')
			.replace(/&nbsp;/g, ' ')
			.replace(/<\/div>/g, '\n')
			.replace(/<br>/g, '\n')
			.replace(/&lt;/g, '<')
			.replace(/&lt;/g, '<')
			.replace(/&gt;/g, '>')
			.replace(/&amp;/g, '&')
			.replace(/<\/?.+?>/g, "")
			.replace(/ /g, " ")
		return val
	}
	//提交聊天信息
	const submitChatInfo = (o) => {
		if (!(o.key === "Enter" || o.type === 'click')) {
			return
		}
		if (o.key === 'Enter') o.preventDefault()
		if (!textareaValue || !formatString(textareaValue).trim()) {
			setTextareaValue('')
			return
		}
		if(!sessionStorage.getItem('joiner_cancel_occupy_countdown_'+linkId)) { // 加入者取消占用倒计时
			sessionStorage.setItem('joiner_cancel_occupy_countdown_'+linkId, 1)
			socketServiceInstanct.socketEmitMsg(SocketEvent.Q_JOINER_CANCEL_OCCUPY_COUNTDOWN_TS, {linkId: linkId});
		}
		const sendData = {
			msgId: generateMsgId(),
			text: formatString(textareaValue)
		}
		let params = getMsgBody(linkId, sendData, 'chat', userId)
		socketServiceInstanct.socketEmitMsg(SocketEvent.Q_SEND_IM_MSG_TS, params)
		params.fix = 'right'
		params.msgDataText = textareaValue
		params.msgDataInfo = sendData
		params.msgId = sendData.msgId
		setStoreSendInfo(params)
		updateCloseOperate(true)
		setTimeout(() => {
			textareaValueRef.current.focus()
		}, 500)
		let createTime = Date.now()
		params.msgWaitState = 'loading'
		updateChatList([{ ...params, createTime }])
		setTextareaValue(null)
		innerTextareaHtml('')
		errorListTime.current[params.ackId] = setTimeout(() => {
			if (params.msgWaitState === 'loading') {
				let arr = [...chatList]
				params.msgWaitState = 'error'
				updateChatList([...arr, { ...params }])
			}
		}, 60000)
		if (textareaValueRef && textareaValueRef.current) {
			textareaValueRef.current.blur()
		}
	}
	// 输入框内容滚动到底部，超过输入框自动往上顶
	const inputScrollBottom = () => {
		// if (textareaValueRef && textareaValueRef.current.scrollHeight > textareaValueRef.current.clientHeight) {
		textareaValueRef.current.scrollTop = textareaValueRef.current.scrollHeight
		// }
	}
	useEffect(() => {
		if (!closeOperateStatus) {
			setIsPositionState(false)
			setIsExpression(false)
		}
	}, [closeOperateStatus])

	useEffect(() => {
		//服务器回应是否发送成功（ackCode值为12为正常运行）
		const sendImMsgEvent = (res) => {
			const { createTime, ackCode, ackId } = JSON.parse(res)
			let arr = deWeight([...chatList])
			arr.forEach(row => {
				if (row.ackId === ackId) { // 聊天系统前端未做消息投递确认Task #33951
					row.msgWaitState = ''
					if (ackCode !== 12) {
                        row.msgWaitState = 'error'
                    } else {
                        clearTimeout(errorListTime.current[ackId])
                        delete errorListTime.current[ackId]
                    }
				}
			})
			if (isFirst) {
				let info = JSON.parse(JSON.stringify(chatInfoG))
				if (info) {
					info.isFirst = true
					info.createTime = createTime
					updateChatList([info])
					joinerFirstTimeChange()
				}
				return
			}
			updateChatList([...arr])
		}
		//服务器回应B拉取新的消息 (checked)
		const userNewMsgListEvent = (res) => {
			let { list } = res
			const chatList = list.filter(item => {
				return item.msgType === 'chat' || item.msgType === 'audio' || item.msgType === 'couponcardv1'
			})
			if (!$validate.isNull(chatList)) {
				for (const item of chatList) {
					item.fix = 'left'
					let data = null
					if (typeof item.msgData === "string") {
						try {
							let tmpStr = aesUtils.aesDecryptXy(item.msgData)
							data = JSON.parse(tmpStr)
						} catch (e) {
							console.error("258 e=", e.message, item)
							let { x: dynamicKey, y: dynamicValue } = JSON.parse(localStorage.getItem('aesEncryp')) || {}
							console.log("无法aesDecryptXy解密的数据 item=", item, "x=" + dynamicKey, "y=" + dynamicValue)
							item.msgType = "error" //无法解密的数据，把msgType标记为error，不做任何dom渲染处理
						}
					} else {
						data = item.msgData
					}
					if (item.msgType === 'chat') {
						item.msgDataText = data.text
					} else if (item.msgType === 'audio') {
						// 处理语音
						item.audioUrl = config.audioFileUrl + data.url
						item.audioTime = data.time
						item.msgDataText = `<span>${data.time}"<span/>`
					} else if (item.msgType === 'couponcardv1') {
						item.countryCodeToAmounts = data.countryCodeToAmounts
						item.couponUrl = data.couponUrl
					}
				}
			}
			if (!$validate.isNull(list)) {
				// 通知服务器已拉取最新消息
				let type = SocketEvent.Q_DELIVER_IM_MSG_TS
				socketServiceInstanct.socketEmitMsg(type, { msgId: list[list.length - 1].msgId })
				setMsgId(list[list.length - 1].msgId)
			}
			// 永久缓存聊天记录
			const chatInfoList = JSON.parse(localStorage.getItem('chatList'))
			if ($validate.isNull(chatListData) && !$validate.isNull(chatInfoList)) {

				let ctArr = []
				for (let i = 0; i < chatInfoList.length; i++) {
					if (chatInfoList[i].dateImTypeData === linkId) {
						ctArr.push(chatInfoList[i])
					}
				}
				let arr = [...chatList, ...chatListData, ...ctArr]
				arr = deWeight(arr)
				updateChatList(arr)
				localStorage.setItem('chatList', JSON.stringify(arr))
			} else {
				updateChatList([...chatList])
				localStorage.setItem('chatList', JSON.stringify(deWeight(chatListData)))
			}
		}
		//收到这个事件,说明有人给B发送消息了,B自己去拉一下消息吧。1.如果是一个全新的聊天,也就是APP本地的聊天列表没有B;那就去 拉一下B的信息,把列表构造出来
		const haveNewImMsgEvent = (res) => {
			// 向服务器拉去新消息
			let type = SocketEvent.Q_GET_USER_NEW_MSG_LIST_TS
			const params = {
				dateImTypeData: linkId,
				msgId: msgId || "",
				ackId: ""
			}
			socketServiceInstanct.socketEmitMsg(type, params)
		}
		// 服务器通知客户端刷新自动失效倒计时提醒
		const autoDisable = (res) => {
			// console.warn(`--服务器通知客户端刷新自动失效倒计时提醒  autoDisable: `, res);
			try {
				const data = JSON.parse(res)
				if (`${data.reachMaxAbnormalCount}` === 'true') endEvent('reachMax')
				if (`${data.remainTime}` === '-1') return endEvent('over')
				endEvent('start', data.remainTime)
			} catch (error) {
				endEvent('over')
			}
		}
		// 通知浏览器结束
		const linkIsEndEvent = (res) => {
			// console.warn(`--通知浏览器结束  linkIsEndEvent: `, res);
			try {
				socketServiceInstanct.disconnect()
				const data = JSON.parse(res)
				if (`${data.controllerBanned}` === 'true') {
					endEvent(`ban-${data.timeUnit}-${data.banTime}`)
					return
				}
				endEvent(data.endType);
			} catch (error) {
				endEvent()
			}
		}
		// 扫码服务器通知网页失效
		const anonNotifyBreakControlTcEvent = (res) => {
			const { linkStatus } = JSON.parse(res)
			if (linkStatus) {
				endEvent()
			}
		}
		//成功唤醒app  //倒计时开始
		if (socketServiceInstanct) {
			const e = socketServiceInstanct._events
			if (!e.Q_ACK_SEND_IM_MSG_TC) {
				socketServiceInstanct.on(SocketEvent.Q_ACK_SEND_IM_MSG_TC, sendImMsgEvent)
			}
			if (!e.Q_ACK_USER_NEW_MSG_LIST_TC) {
				socketServiceInstanct.on(SocketEvent.Q_ACK_USER_NEW_MSG_LIST_TC, userNewMsgListEvent)
			}
			if (!e.Q_YOU_HAVE_SOME_NEW_IM_MSG_TC) {
				socketServiceInstanct.on(SocketEvent.Q_YOU_HAVE_SOME_NEW_IM_MSG_TC, haveNewImMsgEvent)
			}
			if (!e.ANON_LINK_IS_END_TC) {
				socketServiceInstanct.on(SocketEvent.ANON_LINK_IS_END_TC, linkIsEndEvent)
			}
			if (!e.ANON_NOTIFY_BREAK_CONTROL_TC) {
				socketServiceInstanct.on(SocketEvent.ANON_NOTIFY_BREAK_CONTROL_TC, anonNotifyBreakControlTcEvent)
			}
			if (!e.Q_REFRESH_OCCUPY_COUNTDOWN_TC) {
				socketServiceInstanct.on(SocketEvent.Q_REFRESH_OCCUPY_COUNTDOWN_TC, autoDisable)
			}
		}
		return function () {
			if (socketServiceInstanct) {
				socketServiceInstanct.off(SocketEvent.Q_ACK_SEND_IM_MSG_TC, sendImMsgEvent)
				socketServiceInstanct.off(SocketEvent.Q_ACK_USER_NEW_MSG_LIST_TC, userNewMsgListEvent)
				socketServiceInstanct.off(SocketEvent.Q_YOU_HAVE_SOME_NEW_IM_MSG_TC, haveNewImMsgEvent)
				socketServiceInstanct.off(SocketEvent.ANON_LINK_IS_END_TC, linkIsEndEvent)
				socketServiceInstanct.off(SocketEvent.ANON_NOTIFY_BREAK_CONTROL_TC, anonNotifyBreakControlTcEvent)
				socketServiceInstanct.off(SocketEvent.Q_REFRESH_OCCUPY_COUNTDOWN_TC, autoDisable)
			}
		}
	}, [chatList, linkId, endEvent, msgId, updateChatList, textareaValueRef, storeSendInfo, chatInfoG,
		chatListData, isFirst, joinerFirstTimeChange, socketServiceInstanct, updateUserId])

	return (
		<>
			<div className="chat-operate-contain">
				<div className="chat-list-content">
					<ChatList time={time} stop={stop} socketServiceInstanct={socketServiceInstanct} reachMax={reachMax} closeTime={closeTime} />
				</div>
				<div className="chat-operate">
					<div className="control-panel">
						{/*表情icon*/}
						{(
							<div className="expression" onClick={chooseExpressin}>
								<img src={emojis} alt="" />
							</div>
						)}
						{/*输入框*/}
						<div className="input input-group">
							<div
								onClick={openKeyboard}
								className="inp"
								id="chat-input"
								ref={textareaValueRef}
								contentEditable="true"
								onInputCapture={handleValueChange}
								onFocus={handleValueFocus}
								onKeyPress={submitChatInfo}
							/>
						</div>
						{
							// 发送按鈕
							<div className="send" onClick={submitChatInfo}>
								{t('button_send')}
							</div>
						}
					</div>
					{
						// 表情面板
						<div className="expression-list" style={{ display: (isExpression && !isPositionState) ? "block" : "none" }}>
							<div className="expression-info">
								<ul>
									{
										expressionList.map(item => {
											return (
												<li onClick={e => expressionHandler(item)} key={item.key}>
													<img src={require('@/assets/images/business/emoji/' + item.emoji).default} alt={item.key} />
												</li>
											)
										})
									}
								</ul>
								<i className="del-chat-text" onClick={delChatText} />
							</div>
						</div>
					}
					<div className="triangle" style={{ display: (isExpression && !isPositionState) ? "block" : "none" }} />
				</div>
			</div>
		</>
	)
}

export default connect(state => ({
	chatList: state.chatList,
	closeOperateStatus: state.closeOperateStatus
}), { updateChatList, updateCloseOperate })(ChatArea)

