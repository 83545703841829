
import './index.scss';
import logo from "../../../../assets/images/business/logo.png";

const HeaderLogo = ()=> {

  // 跳转到官网
  const jumpWebsite = () => {
    window.open("https://www.lovense.com/", "_blank");
  };

  return (
    <>
      <div className="pc-header">
        <img src={logo} onClick={jumpWebsite} alt=""/>
      </div>
    </>
  )
}

export default HeaderLogo;