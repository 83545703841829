import React from 'react';
import './index.scss'

const Loading = (props) => {

    return (
        <div className="loading"/>
    );

}

export default Loading;
