import "./index.scss"
import { connect } from "react-redux"
import React, { useEffect, useState, useRef } from "react"
import logo from "../../../../../assets/images/business/remote_logo.png"
import endImg from "../../../../../assets/images/business/nav_end.png"
import reportImg from "../../../../../assets/images/business/light_report.svg"
import LayerMask from "../../layer-mask"
import EndDialog from "./end-dialog"
import QrCode from "./qrCode"
import SocketEvent from "../../../../../serve/socket-event"
import { formatSeconds } from "../../../../../utils/common"
import { updateControlState } from "@/redux/action/control-state.js"
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
const pathData = {
  development: '',
  test: '/c2',
  production: '/v2',
}

const CountDown = (props) => {
  const { t } = useTranslation()
  const {
    linkId,
    isEnd,
    toysData,
    linkInfo,
    qrCode,
    fromCam,
    updateControlState,
    socketServiceInstanct,
    endSocket,
    setIsEnd,
    hadAuthority,
  } = props
  const [countdownTxt, setCountdownTxt] = useState("")
  const timeOut = useRef(null);

  useEffect(()=>{
    if(isEnd && isEnd.end) clearTimeout(timeOut.current)
  }, [isEnd])

  useEffect(() => {
    let timer = null
    const setTimeTxt = (t) => {
      let tmpTime = formatSeconds(t)
      setCountdownTxt(tmpTime)
    }
    if (linkInfo) {
      if (linkInfo.isStart) {
        if (isEnd && isEnd.end) {
          clearInterval(timer)
        } else {
          if (linkInfo.isStart) {
            let lctTime = linkInfo.leftControlTime
            if (linkInfo.isStart && !timer) {
              //controlLink已经处于控制中状态，重新刷新页面进来
              if (linkInfo.expires < 0) {
                let sec = lctTime - 1
                if (sec < 0) {
                  sec = 0
                }
                setTimeTxt(sec)
              } else {
                setTimeTxt(lctTime + 1)
              }
            } else {
              //controlLink处于未开始控制 重新刷新页面进来
              if (linkInfo.expires < 0) {
                setCountdownTxt("00:00")
              } else {
                setTimeTxt(linkInfo.expires)
              }
            }
            clearInterval(timer)
            if (linkInfo.expires < 0) {
              //控制不受时间限制
              let t0 = lctTime++
              setTimeTxt(t0)
              timer = setInterval(() => {
                let t2 = lctTime++
                setTimeTxt(t2)
              }, 1000)
            } else {
              let t0 = lctTime--
              setTimeTxt(t0)
              timer = setInterval(() => {
                let t2 = lctTime--
                if (t2 <= 0) {
                  //显示过期页面
                  //为确保玩具停止震动，加推停止指令
                  if (toysData) {
                    let orderBody = {}
                    for (let i = 0; i < toysData.length; i++) {
                      let toyId = toysData[i].id
                      orderBody[toyId] = { v: 0, v1: 0, v2: 0, v3: 0, s: 0, p: 0, r: 0, f: 0, t: 0, d: 0 }
                    }
                    let cate = { "version": 5, cate: "id", id: orderBody }
                    let order = {
                      ackId: uuidv4(),
                      toyCommandJson: JSON.stringify(cate),
                      linkId: linkId,
                      userTouch: true,
                    }
                    if (socketServiceInstanct) {
                      socketServiceInstanct.socketEmitMsg(SocketEvent.ANON_COMMAND_LINK_TS, order)
                    }
                    // updateControlState({"state":2});
                    clearInterval(timer)
                    // 三秒后没收到服务器响应则跳转默认结束页面
                    timeOut.current = setTimeout(() => {
                      updateControlState({ state: 2 })
                      setIsEnd({end: true})
                    }, 3000)
                  }
                //   endSocket()
                }
                setTimeTxt(t2)
              }, 1000)
            }
          }
        }
      } else {
        if (linkInfo.expires < 0) {
          setCountdownTxt("00:00")
        } else {
          setTimeTxt(linkInfo.expires)
        }
      }
    }
    return function () {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [linkId, isEnd, toysData, linkInfo, socketServiceInstanct, updateControlState, endSocket, setIsEnd])

  // 结束聊天窗口
  const endControlLink = () => {
    if (toysData) {
      let orderBody = {}
      for (let i = 0; i < toysData.length; i++) {
        let toyId = toysData[i].id
        orderBody[toyId] = { v: 0, v1: 0, v2: 0, v3: 0, s: 0, p: 0, r: 0, f: 0, t: 0, d: 0, o: 0, pos: 0 }
      }
      let cate = { "version": 5, cate: "id", id: orderBody }
      let order = {
        ackId: uuidv4(),
        toyCommandJson: JSON.stringify(cate),
        linkId: linkId,
        userTouch: true,
      }
      if (socketServiceInstanct) {
        socketServiceInstanct.socketEmitMsg(SocketEvent.ANON_COMMAND_LINK_TS, order)
      }
      updateControlState({ state: 2 })
    }
    endSocket()
  }
  //结束控制
  const [showEndDia, setShowEndDia] = useState(false)
  const confirmEvent = () => {
    setShowEndDia(false)
    endControlLink()
  }
  //显示二维码
  const [showQrCode, setShowQrCode] = useState(false)
  const connectRemote = () => {
    setShowQrCode(true)
  }

  return (
    <>
      <div className="pc-count-down flex acenter">
        <div className="left flex acenter">
          {!fromCam && <div className="connect-remote flex acenter" onClick={connectRemote}>
            <img className="img-logo" src={logo} alt="" />
            <div className="txt ellipsis">{t('button_open_remote')}</div>
          </div>}
        </div>
        <div className="right flex acenter">
          <div className="report-btn flex center" onClick={() => { window.open(`${window.location.origin}${pathData[process.env.REACT_APP_ENV]}/report?linkId=${linkId}`, "_blank") }}>
            <img className="report-img" src={reportImg} alt="" />
          </div>
          {
            hadAuthority && (
              <div className="end-btn flex column center" onClick={() => { setShowEndDia(true) }}>
                <img className="end-img" src={endImg} alt="" />
                <div className="count-txt">
                  {countdownTxt ? <p>{countdownTxt}</p> : ""}
                </div>
              </div>
            )
          }
        </div>
      </div>
      {
        showEndDia ?
          <LayerMask
            ContentComponent={EndDialog}
            cancelEvent={() => { setShowEndDia(false) }}
            confirmEvent={confirmEvent}
          /> : ""
      }
      {
        showQrCode ?
          <LayerMask
            ContentComponent={QrCode}
            cancelEvent={() => { setShowQrCode(false) }}
            data={qrCode}
          /> : ""
      }
    </>
  )
}

export default connect(state => ({
  // controlState: state.controlState,
}), {
  updateControlState
})(CountDown)
