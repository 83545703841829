import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import $api from '@/serve/api'

// import LanguageDetector from 'i18next-browser-languagedetector';
let default_en =  {
  "lush3_des": "Bluetooth Egg Vibrator",
  "sync_control_text": "Sync control",
  "live_control_text": "Live control",
  "sync_control_controllee": "Controllee",
  "message_type_not_support": "Control link web page does not support this message type.",
  "end_page_recommend_toys": "Check out these toys that can interact with your partners toy!",
  "button_know_more": "Know More",
  "common_loading": "Loading...",
  "start_message_duration": "Duration:",
  "live_control_panel_partner_no_toy": "You can control them once they connect a toy.",
  "button_send": "Send",
  "cant_open_multiple_links": "Another control link session is currently active. You can't open this link until the active one ends.",
  "end_page_timesup": "Time's up!",
  "end_page_recommended": "Recommended for you",
  "download_remote_des": "Check out Lovense Remote for even more features",
  "common_me": "Me",
  "start_message_which_toy": "Toy(s):",
  "open_remote_des2": "Supports more Lovense Remote features!",
  "end_page_timesup_des": "Check with your partner for another link.",
  "sync_control_type_interactive": "Interactive",
  "open_remote_des3": "Make sure you are using the newest app update.",
  "end_page_wishlist_link": "Controllee's Wish List Link",
  "button_open_remote": "Open with Lovense Remote",
  "notification_end_control_link": "Are you sure that you want to end this session?",
  "toy_reconnecting": "Reconnecting...",
  "failed_open_control_link": "Oops, failed to open the Lovense control link, please check if you enter the valid link or try again later.",
  "open_remote_des1": "Sync your Max/Nora to control their toy(s).",
  "sync_control_type_controlled": "Controlled",
  "button_end": "End",
  "diamo_des": "Vibrating Cock Ring",
  "start_message_des": "I've started to control your toy(s) with this control link:",
  "open_remote_scan_qr": "Scan the QR code with Lovense Remote",
  "max2_des": "High Tech Male Masturbator",
  "sync_control_controller": "Controller",
  "sync_control_type_controlling": "Controlling",
  "start_message_duration_unlimited": "Unlimited",
  "edge2_des": "Adjustable Prostate Massager",
  "open_remote_page_expire": "This page will expire after scanning the QR code with Lovense Remote",
  "open_remote_title": "Control with Lovense Remote app",
  "button_cancel": "Cancel",
  "report_page_title": "Please provide more information for your report",
  "report_info_name": "Your Name",
  "report_info_email1": "Your Email",
  "report_info_email2": "Re-enter your Email",
  "report_type": "Report Type",
  "report_info_description": "Please describe your issue",
  "report_invalid_email": "Invalid email",
  "report_email_not_match": "Emails entered do not match!",
  "user_report_reason2": "Harassment",
  "user_report_reason3": "User is under 18 or talking about minors",
  "user_report_reason4": "Other",
  "button_add_photo": "Add photo",
  "add_photo_maxsize": "The file size can’t be more than 3MB.",
  "patterns_menu_report": "Report",
  "auto_disable_time": "Auto-disable time:",
  "auto_disable_controller_des": "Start controlling within 30 seconds or you may be temporarily banned from opening control links.",
  "auto_disable_controller_des2": "Please start controlling within 30 seconds or you will be temporarily banned from opening control links.",
  "auto_disable_controller_des3": "Start controlling/chatting within 45 seconds or you may be temporarily banned from opening control links.",
  "auto_disable_controller_des4": "Please start controlling/chatting within 45 seconds or you will be temporarily banned from opening control links.",
  "auto_disable_time_stopped": "Auto-disable time stopped",
  "link_auto_end_title": "Session ended!",
  "link_auto_end_controller_des": "You are banned from opening control links for <#1#>.",
  "tenporarily_ban_hour": "hour(s)",
  "tenporarily_ban_day": "day(s)",
  "tenporarily_ban_week": "week(s)",
  "tenporarily_ban_month": "month(s)",
  "controller_temporarily_banned": "You are banned from opening control links.",
  "common_stop": "Stop",
  "common_stopped": "Stopped",
  "auto_disable_controllee_des": "The controller must start controlling within 30 seconds. Tap “Stop” to give the controller more time to begin this session.",
  "auto_disable_controllee_des2": "The controller must start controlling/chatting within 45 seconds. Tap “Stop” to give the controller more time to begin this session.",
  "link_auto_end_controllee_des": "The control session timed out. The controller has been temporarily banned from opening control links.",
  "control_link_survey_title": "Got a minute?",
  "control_link_survey_des": "Take our survey and share your thoughts.",
  "buttonn_survey": "Go to survey",
  "fail_to_open_link1": 'Unable to open this link',
  "fail_to_open_link2": 'The link is taken',
  "fail_to_open_link3": 'The link has expired',
  "fail_to_open_link_des1": 'Someone else has already opened the control link',
  "fail_to_open_link_des2": 'Ask your partner for an active link',
  "fail_to_open_link_des3": 'Please check that your link is valid or try again later',
  "new_control_link_start_message": `I've started to control your toy(s) with this control link.`,
  "coupon_message": 'Enjoy this <#1#> coupon from me <3',
  "des_remote_control_link_h5": 'Control app for Lovense toys',
  "default_text_control_link_h5": 'Check it out and have fun!',
  "learn_more": 'Learn More',
};

let default_ja = {
  "lush3_des": "遠隔操作可能なおたまじゃくし型バイブレーター",
  "sync_control_text": "シンクロコントロール",
  "live_control_text": "ライブコントロール",
  "sync_control_controllee": "コントロール",
  "end_page_recommend_toys": "パートナーと一緒に遊べるおもちゃを確認しよう！",
  "button_know_more": "もっと知る",
  "common_loading": "ロード中です．．．",
  "start_message_duration": "期間:",
  "live_control_panel_partner_no_toy": "おもちゃが接続されるとコントロールできるようになります。",
  "button_send": "送る",
  "end_page_timesup": "時間切れ",
  "end_page_recommended": "あなたへのおススメ",
  "download_remote_des": "Lovense Remoteをチェックして多くの機能を楽しもう！",
  "common_me": "私",
  "start_message_which_toy": "おもちゃ：",
  "open_remote_des2": "多くの機能を楽しもう！",
  "end_page_timesup_des": "他のリンクに関してパートナーと確認してください。",
  "sync_control_type_interactive": "相互",
  "open_remote_des3": "最新バージョンのLovense Remoteを使用していることを確認してください。",
  "end_page_wishlist_link": "欲しいものリストリンク",
  "button_open_remote": "Lovense Remoteで開く",
  "notification_end_control_link": "このセッションを本当に終了しますか？",
  "toy_reconnecting": "再接続中…",
  "failed_open_control_link": "Lovense Remoteのコントロールを開くのに失敗しました。有効なリンクを入力して再度試してください。",
  "open_remote_des1": "Max/Noraをおもちゃでおもちゃをコントロール",
  "sync_control_type_controlled": "受け中",
  "button_end": "終了",
  "diamo_des": "遠隔操作可能なコックリング",
  "start_message_des": "コントロールリンクでのおもちゃの操作を開始しました。",
  "open_remote_scan_qr": "QRコードをLovense Remoteでスキャンしてください。",
  "max2_des": "遠隔操作可能なオナホール",
  "sync_control_controller": "支配者",
  "sync_control_type_controlling": "攻め中",
  "start_message_duration_unlimited": "無制限",
  "edge2_des": "遠隔操作可能なエネマグラ",
  "open_remote_page_expire": "このページはLovense Remoteでスキャンすると消滅します。",
  "open_remote_title": "Lovense Remoteでコントロール",
  "button_cancel": "取り消す"
};
let SetLng = navigator.language;//获取系统语言
let  resources = {
    en: {
      translation:default_en
    },
    ja: {
      translation:default_ja
    }
  };
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
//   .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
//   .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    lng: SetLng,
    debug: false,
    resources: resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });
 // 获取翻译信息
 $api.getLangControlLink().then(res => {
  if (res.result) {
    resources = {
      en: {
        translation: Object.assign(default_en, res.data.lang.en)
      },
      ja: {
        translation: res.data.lang.ja
      }
    };
    console.log(resources.en);
    i18n.use(initReactI18next).init({
      fallbackLng: 'en',
      lng: SetLng,
      debug: true,
      resources: resources,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      }
    });
  }
}, err => {

})

export default i18n;